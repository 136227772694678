import { createSlice, current } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token_data: "",
        token_id: "",
        user_id: "",
        login :false,
        user_name:"",
        user:null,
        emailVerified:false,
        panVerified:false,
        issue_dt: null
    },

    reducers: {
       
        setToken: (state, action) => {
            state.token_data = action.payload.token_data;
            state.token_id = action.payload.token_id;
            state.user_id = action.payload.user_id;
            state.login = true;
            state.user_name = action.payload.user_name;
            state.issue_dt = new Date();
            state.user = action.payload.user;
            state.emailVerified = action.payload.user.user_email_verified_yn === "yes" ? true : false;
            state.panVerified = action.payload.user.user_pan_verified_yn === "yes" ? true : false;  
        },

        resetToken : (state, action) => {
            state.token_data = ""
            state.token_id = ""
            state.user_id = "";
            state.login =false;
            state.user_name = ""
            state.issue_dt = null

        },

    },
});

export const { setToken, resetToken } = authSlice.actions;

export default authSlice.reducer;
