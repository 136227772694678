


import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { apiService } from "../../constants/ApiService";
import { resetToken } from "../../reducetool/features/authSlice";
import { useDispatch } from "react-redux";

const OrderOtp = () => {

    const dispatch = useDispatch();
    const [orderId, setOrderId] = useState("");
    const [loader, setLoader] = useState(false)

    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("")
    const inputRefs = Array.from({ length: 5 }).map(() => React.createRef());
    const location = useLocation();
    const navigate = useNavigate();
    
    const [errorApiError, setErrorApiError] = useState('');



    const handleOnChange = (e, index) => {
        const { value } = e.target;

        if (value.length === 0 && index > 0 && inputRefs[index - 1]) {
            inputRefs[index - 1].current.focus();
        }
        else if (value.length === 1 && index < 5 && inputRefs[index + 1]) {
            inputRefs[index + 1].current.focus();
        }

        setOtp((prevOtp) => {
            let newOtp = prevOtp.slice(0, index) + value + prevOtp.slice(index + 1);
            newOtp = newOtp.substring(0, 6);
            return newOtp;
        });
    };
   

    const verifyOtp = async () => {

        if (otp.length < 5) {
            alert("Please enter the complete OTP.");
            return;
        }

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/admin/User_Detail/ValidateEmailOTP?email=${email}&otp=${otp}`, config)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                // alert("Register Succefully");
                navigate("/ordercancel", { state: { orderId: orderId } });
                return
            }
            else {
                setErrorApiError("Wrong OTP!")
                // alert(response.errorMessage);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };


    const resendOtp = async (currentEmail) => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            let userEmail = currentEmail?currentEmail : email
        
            const response = await apiService.get(`/api/admin/User_Detail/GenerateOTP?email=${userEmail}&otp_purpose=${"verify cancellation order"}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // alert("otp is send")
                // alert("Register Succefully");
                // navigate("/")
                return

            }
            else {
                alert(response.errorMessage);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    useEffect(() => {
        if (location.state) {
            setOrderId(location.state.orderId)
            setEmail(location.state.user_email)
            const timer = setTimeout(() => {
                resendOtp(location.state.user_email);
            }, 10000);
            return () => clearTimeout(timer); // Cleanup the timer when the component unmounts

        }
    }, [location.state]);

    return (
        <>
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>OTP</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index-2.html">
                                                <i className="fa-solid fa-house" />
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">OTP</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="log-in-section otp-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div className="image-contain">
                                <img
                                    src="../assets/images/inner-page/otp.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className="log-in-box">
                                    <div className="log-in-title">
                                        <h3 className="text-title">
                                            Please enter the one time password
                                        </h3>
                                        <h5 className="text-content">
                                        {errorApiError && <div className="text-danger mt-3" style={{ fontSize: 18, marginBottom: 20 }}>{errorApiError}</div>}

                                            {/* A code has been sent to <span>*******9897</span> */}
                                        </h5>
                                    </div>
                                    {/* <div
                                        id="otp"
                                        className="inputs d-flex flex-row justify-content-center"
                                    >
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="first"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="second"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="third"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="fourth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="fifth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="sixth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                    </div> */}

                                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-3">
                                        {inputRefs.map((inputRef, index) => (
                                            <input
                                                key={index}
                                                ref={inputRef}
                                                className="text-center form-control rounded"
                                                type="number"
                                                maxLength={1}
                                                // onKeyDown={(e) => handleKeyPress(e, index)}
                                                onChange={(e) => handleOnChange(e, index)}
                                            />
                                        ))}
                                    </div>
                                    {/* <div className="send-box pt-4">
                                        <h5>
                                            Didn't get the code?{" "}
                                            <a href="javascript:void(0)" className="theme-color fw-bold"
                                                onClick={() => { resendOtp() }}
                                            >
                                                Resend It
                                            </a>
                                        </h5>
                                    </div> */}
                                    <button
                                        onclick="location.href = 'index-2.html';"
                                        className="btn btn-animation w-100 mt-3"
                                        type="button"
                                        onClick={() => { verifyOtp() }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default OrderOtp;