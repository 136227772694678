import { useState } from "react";
import { useNavigate } from "react-router-dom";


const Search = () => {

    const navigate = useNavigate();
    
    const [ searchKeyData , setSearchKeyData] = useState("");

    const onHandleSearchKeyItem = () => {
        navigate("/searchProduct", { state: { searchKey: searchKeyData } })
    }

    return (
        <>

            <section className="breadcrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-contain">
                                <h2>Search</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index-2.html">
                                                <i className="fa-solid fa-house" />
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">Search</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="search-section">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-8 mx-auto">
                            <div className="title d-block text-center">
                                <h2>Search for products</h2>
                                <span className="title-leaf">
                                    <svg className="icon-width">
                                        <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                                    </svg>
                                </span>
                            </div>
                            <div className="search-box">
                                <div className="input-group">
                                    <input type="text" className="form-control" 
                                    placeholder="" 
                                    onChange={(e) => { setSearchKeyData(e.target.value) }}/>
                                    <button
                                        className="btn theme-bg-color text-white m-0"
                                        type="button"
                                        id="button-addon1"
                                        onClick={()=>{onHandleSearchKeyItem()}}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Search;