import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';


const Return_policy = (props) => {


    const navigate = useNavigate();
    const location = useLocation();
    const [returnPolicy , setReturnPolicy ] = useState("")

    useEffect(() => {
        if (location.state) {
            setReturnPolicy(location.state.returnPolicy)
        }
      }, [location.state]);
    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Return policy </h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index-2.html">
                                                <i className="fa-solid fa-house" />
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Return policy
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* 404 Section Start */}
            <section className="section-404 section-lg-space">
                <div className="container-fluid-lg">
                    <div className="row">

                        <div className="col-12">
                            <div>
                                <p>
                                    {returnPolicy}
                                </p>
                                    <button
                                    onClick={()=>navigate("/")}
                                    className="btn btn-md text-white theme-bg-color mt-4 mx-auto"
                                >
                                    Back To Home Screen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 404 Section End */}
        </>
    )
}

export default Return_policy;