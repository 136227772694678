const Client = ({ testimonialToRender }) => {
    return(
    <>
    
<section className="review-section section-lg-space">
  <div className="container-fluid">
    <div className="about-us-title text-center">
      <h4 className="text-content">Latest Testimonals</h4>
      <h2 className="center">What people say</h2>
    </div>
    <div className="row">
      <div className="col-12">
        <div className="slider-4-half product-wrapper">
        {testimonialToRender.map((test, index) => (
          <div>
            <div className="reviewer-box">
              <i className="fa-solid fa-quote-right" />
              <div className="product-rating">
                <ul className="rating">
                  <li>
                    <i data-feather="star" className="fill" />
                  </li>
                  <li>
                    <i data-feather="star" className="fill" />
                  </li>
                  <li>
                    <i data-feather="star" className="fill" />
                  </li>
                  <li>
                    <i data-feather="star" className="fill" />
                  </li>
                  <li>
                    <i data-feather="star" />
                  </li>
                </ul>
              </div>
              {/* <h3>Top Quality, Beautiful Location</h3> */}
              <p>
                "{test.client_feedback}"
              </p>
              <div className="reviewer-profile">
                <div className="reviewer-image">
                  <img
                    src="../assets/images/inner-page/user/user.jpg"
                    className="blur-up lazyload"
                    alt=""
                  />
                </div>
                <div className="reviewer-name">
                  <h4>{test.user_name}</h4>
                  <h6>Happy Customer</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
          
         
        </div>
      </div>
    </div>
  </div>
</section>
    </>)
}

export default Client;