import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { apiService } from '../../constants/ApiService';


const OrderReturn = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [productToBeReturnedBy, setProductToBeReturnedBy] = useState("")
    const [previewImage, setPreviewImage] = useState(null);
    const [returncondition, setReturncondition] = useState(false);
    const [helperTable, setHelperTable] = useState([]);
    let initial = {
        order_item_id: "",
        product_to_be_returned_by: "",
        order_courier_pod_no: "",
        order_courier_name: "",
        order_courier_receipt_image: "",
        order_cancel_reason: "",
        order_cancel_option: "",
    }
    const [orderReturnData, setOrderReturnData] = useState(initial);


    const onChangeHandle = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {

            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewImage(reader.result);
                };
                reader.readAsDataURL(file);
            }
            setOrderReturnData((prevData) => ({
                ...prevData,
                order_courier_receipt_image: file,
            }));


        } else {
            setOrderReturnData((prevData) => ({
                ...prevData,
                [name]: value,
            }));


        }
    };

    async function uploadImagesFromArrayWithIndex(obj) {

        if (obj.order_courier_receipt_image && typeof obj.order_courier_receipt_image === 'object' && obj.order_courier_receipt_image !== null && 'name' in obj.order_courier_receipt_image) {
            try {
                const imageUrl = await uploadImage(obj.order_courier_receipt_image);
                obj.order_courier_receipt_image = imageUrl;
            } catch (error) {
                console.error(`Error uploading image`, error);
            }

        }

    }

    async function uploadImage(image) {
        const uploadData = new FormData();
        uploadData.append('signature', image);

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json'
            }
        };

        try {
            const response = await apiService.post("/api/ReturnMaster/uploadfile", uploadData, config);
            if (response.status === true) {
                return response.data;
            } else {
                throw new Error("Invalid response from server");
            }
        } catch (error) {
            throw new Error("Failed to upload image: " + error.message);
        }
    }


    const addToOrderReturn = async () => {

        if (orderReturnData.product_to_be_returned_by != "company") {
            await uploadImagesFromArrayWithIndex(orderReturnData)
        }
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            setLoading(true)
            const response = await apiService.post(`/api/ReturnMaster/create`, orderReturnData, config)
            setLoading(false)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                console.log("Request accepted successfully")
                if (orderReturnData.product_to_be_returned_by != "company") {
                    navigate("/orderTracking")
                }
                // setOrderReturnData(response.systemFilterMasterData)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };


    const getReturnOrderDetail = async (orderItemId) => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/ReturnMaster/returnOrderByOrderItemId?orderItemId=${orderItemId}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response.message);
                if (response && response.returnMasterByID !== undefined && response.returnMasterByID !== null && Object.keys(response.returnMasterByID).length !== 0) {
                    setReturncondition(true);
                    setOrderReturnData(response.returnMasterByID)
                }else{
                    if (orderReturnData.product_to_be_returned_by == "company") {
                        addToOrderReturn()
                    }
                }
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const getHelperData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/HelperMaster/allOption?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response)
                setHelperTable(response.helperMasterData)
                // console.log(response.message);
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    useEffect(() => {
        if (location.state) {


            if (location.state.product_to_be_returned_by == "customer") {
                setOrderReturnData((prevData) => ({
                    ...prevData,
                    product_to_be_returned_by: location.state.product_to_be_returned_by,
                    order_item_id: location.state.orderItemId
                }));

                getReturnOrderDetail(location.state.orderItemId)
                getHelperData()

            } else {
                setOrderReturnData((prevData) => ({
                    ...prevData,
                    product_to_be_returned_by: location.state.product_to_be_returned_by,
                    order_item_id: location.state.orderItemId
                }));

                getReturnOrderDetail(location.state.orderItemId)
            }

        }
    }, [location.state]);

    return (
        <>
            <section className="order-detail pt-0 mb-3">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="col-12 overflow-hidden mt-3" style={{ marginBottom: 50 }}>
                                <h2>See your product return status below:</h2>
                                <ol className="progtrckr">

                                    <li className={orderReturnData.order_returnable ? "progtrckr-done" : "progtrckr-todo"} >
                                        <h5>Returning details filled by you</h5>
                                        <h6>{orderReturnData.order_returnable_time ? new Date(orderReturnData.order_returnable_time).toLocaleString() : ""}</h6>
                                    </li>
                                    <li className={orderReturnData.order_returnable_received ? "progtrckr-done" : "progtrckr-todo"} >
                                        <h5>Returned product received by us</h5>
                                        <h6>{orderReturnData.order_returnable_received_time}</h6>
                                        <h6>{orderReturnData.order_returnable_received_time ? new Date(orderReturnData.order_returnable_received_time).toLocaleString() : ""}</h6>

                                    </li>
                                    <li className={orderReturnData.order_refund ? "progtrckr-done" : "progtrckr-todo"} >
                                        <h5>Refund given</h5>
                                        <h6>{orderReturnData.order_refund_time}</h6>
                                        <h6>{orderReturnData.order_refund_time ? new Date(orderReturnData.order_refund_time).toLocaleString() : ""}</h6>

                                    </li>

                                </ol>
                            </div>


                            {
                                orderReturnData.product_to_be_returned_by == "customer" 
                                    ?


                                    <div className="breadscrumb-contain" style={{ textAlign: "left" }}>
                                        <h2 className="text-left mb-3" >Please fill Return details below:</h2>
                                        <label>Courier service</label>

                                        <div className="input-group">

                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                placeholder=" Type the name of Courier service here...."
                                                id="order_courier_name"
                                                name="order_courier_name"
                                                value={orderReturnData.order_courier_name}
                                                onChange={(e) => { onChangeHandle(e) }}
                                                disabled={productToBeReturnedBy == "company" || returncondition}
                                            />


                                        </div>

                                        <label className='mt-3' >POD no.</label>

                                        <div className="input-group">

                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                id="order_courier_pod_no"
                                                name="order_courier_pod_no"
                                                value={orderReturnData.order_courier_pod_no}
                                                onChange={(e) => { onChangeHandle(e) }}
                                                placeholder=" Type the POD no. of courier here...."
                                                disabled={productToBeReturnedBy == "company" || returncondition}

                                            />

                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>


                                            <label className='mt-3'>Courier service Recipted</label>


                                            {previewImage && <img src={previewImage} alt="Preview" style={{ width: 150, height: 150, borderRadius: 10 }}></img>}
                                            <div className="input-group">

                                                <input
                                                    type="file"
                                                    className="form-control mb-3"
                                                    id="order_courier_receipt_image"
                                                    name="order_courier_receipt_image"
                                                    // value={orderReturnData.order_courier_receipt_image}
                                                    onChange={(e) => { onChangeHandle(e) }}
                                                    disabled={productToBeReturnedBy == "company" || returncondition}

                                                />

                                            </div>
                                        </div>



                                        <div className="breadscrumb-contain" style={{ textAlign: "left", marginTop: 20 }}>
                                            {/* <h2 className="text-left mb-3" >Why do you want to cancel your order?</h2> */}
                                            <h6 className="text-left mb-3">
                                                Please write a reason for your return below:
                                            </h6>


                                            <div className="input-group">
                                                <select
                                                    className="form-control mb-3"
                                                    id="order_cancel_option"
                                                    name="order_cancel_option"
                                                    value={orderReturnData.order_cancel_option}
                                                    onChange={(e) => { onChangeHandle(e) }}
                                                    disabled={productToBeReturnedBy == "company" || returncondition}

                                                >
                                                    <option value="select">Select</option>
                                                    {helperTable.map((data, index) => (
                                                        <option key={index} value={data.field_data}>{data.field_data}</option>
                                                    ))}
                                                </select>

                                            </div>

                                            <div className="input-group">
                                                <textarea
                                                    className="form-control mb-3"
                                                    placeholder="Type here...."
                                                    id="order_cancel_reason"
                                                    name="order_cancel_reason"
                                                    value={orderReturnData.order_cancel_reason}
                                                    onChange={(e) => { onChangeHandle(e) }}
                                                    disabled={productToBeReturnedBy == "company" || returncondition}
                                                ></textarea>
                                            </div>



                                        </div>

                                        {productToBeReturnedBy != "company" && <button
                                            onClick={() => { addToOrderReturn() }}
                                            className="btn btn-animation proceed-btn fw-bold mt-3"
                                            disabled={productToBeReturnedBy == "company" || returncondition}

                                        >
                                            {loading ? "Loading ..." : "Submit"}
                                        </button>}

                                    </div>
                                    :
                                    <>
                                        <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f5f5f5', padding: '20px', textAlign: 'center' }}>
                                        {   loading ?<h2>Loading ... </h2> : <h2 style={{ color: '#333', fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Your return request has been accepted. As soon as the company picks up your item,</h2> }
                                        </div>
                                    </>

                            }


                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OrderReturn;