import React, { useState, useEffect } from 'react';
import { apiService } from "../constants/ApiService";
import Banner from "./About/Banner";
import Product from "./Product/Product";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../constants/layout/Header';
import './About/Carousel.css';



const Home = () => {

    const navigate = useNavigate();
    const [sliderTable, setSliderTable] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const getSliderData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/SliderMaster/allslider?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                console.log(response)
                setSliderTable(response.slider_MasterData)
                // setTotalPage(response.totalPages)
                // console.log(response.message);
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + sliderTable.length) % sliderTable.length);
    };

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderTable.length);
    };
    
    // Automatically change the image every 5 seconds
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderTable.length);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [currentIndex, sliderTable.length]);



    useEffect(() => {
        getSliderData();
    }, [!sliderTable]);

    return (
        <>
            <section className="home-section pt-2">
                <div className="container-fluid-lg">

                    <div className="row g-4">



                        {/* <div className="col-xl-8 ratio_65">
                            <div className="home-contain h-100">
                                <div className="h-100">
                                    <img
                                        src="../assets/images/vegetable/banner/1.jpg"
                                        className="bg-img lazyload"
                                        alt=""
                                    />
                                </div>
                                <div className="home-detail p-center-left w-75">
                                    <div>
                                        <h6>
                                            Exclusive offer <span>30% Off</span>
                                        </h6>
                                        <h1 className="text-uppercase">
                                            Stay home &amp; delivered your{" "}
                                            <span className="daily">Daily Needs</span>
                                        </h1>
                                        <p className="w-75 d-none d-sm-block">
                                            Vegetables contain many vitamins and minerals that are good
                                            for your health.
                                        </p>
                                        <button
                                            onclick="location.href = 'shop-left-sidebar.html';"
                                            className="btn btn-animation mt-xxl-4 mt-2 home-button mend-auto"
                                        >
                                            Shop Now <i className="fa-solid fa-right-long icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 ratio_65">
                            <div className="row g-4">
                                <div className="col-xl-12 col-md-6">
                                    <div className="home-contain">
                                        <img
                                            src="../assets/images/vegetable/banner/2.jpg"
                                            className="bg-img  lazyload"
                                            alt=""
                                        />
                                        <div className="home-detail p-center-left home-p-sm w-75">
                                            <div>
                                                <h2 className="mt-0 text-danger">
                                                    45% <span className="discount text-title">OFF</span>
                                                </h2>
                                                <h3 className="theme-color">Nut Collection</h3>
                                                <p className="w-75">
                                                    We deliver organic vegetables &amp; fruits
                                                </p>
                                                <a href="shop-left-sidebar.html" className="shop-button">
                                                    Shop Now <i className="fa-solid fa-right-long" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-md-6">
                                    <div className="home-contain">
                                        <img
                                            src="../assets/images/vegetable/banner/3.jpg"
                                            className="bg-img  lazyload"
                                            alt=""
                                        />
                                        <div className="home-detail p-center-left home-p-sm w-75">
                                            <div>
                                                <h3 className="mt-0 theme-color fw-bold">Healthy Food</h3>
                                                <h4 className="text-danger">Organic Market</h4>
                                                <p className="organic">
                                                    Start your daily shopping with some Organic food
                                                </p>
                                                <a href="shop-left-sidebar.html" className="shop-button">
                                                    Shop Now <i className="fa-solid fa-right-long" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                        {/* <div className="col-xl-12 col-lg-12 mb-3">
                            <div className="home-contain rounded-0 p-0 bg-size blur-up lazyloaded"
                                style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                    display: "block",
                                }}>

                                <div className="bg-img blur-up lazyloaded"  >
                                    <div className="left"
                                    // onClick={handlePrevious}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="50"
                                            viewBox="0 96 960 960"
                                            width="50"
                                        >
                                            <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
                                        </svg>
                                    </div>
                                    <div className="right"
                                        style={{ right: 40 }}
                                    // onClick={handleNext}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="50"
                                            viewBox="0 96 960 960"
                                            width="50"
                                        >
                                            <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
                                        </svg>
                                    </div>

                                    <div >
                                        <img
                                            style={{ height: "40vh" }}
                                            className="img-fluid bg-img blur-up lazyloaded"
                                            width="100%"
                                            src="../assets/images/vegetable/banner/1.jpg"
                                            alt=""
                                        />
                                        <h1 style={{ position: "absolute", top: "30px", left: "10px" }} className="heding-2">this is a code thisng</h1>
                                        <h2 style={{ position: "absolute", top: "150px", left: "15px" }} className="content-2">this is a code thisng</h2>
                                        <h5 style={{ position: "absolute", top: "200px", left: "15px" }} className="text-content">
                                            Fresh &amp; Top Quality Dry Fruits are available here!
                                        </h5>
                                        <button
                                            style={{ position: "absolute", top: "210px", left: "15px" }}
                                            className="btn theme-bg-color btn-md text-white fw-bold mt-md-4 mt-2 mend-auto"
                                            onclick=""
                                            tabIndex={0}
                                        >
                                            Shop Now <i className="fa-solid fa-arrow-right icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-xl-12 col-lg-12 mb-3">
                            <div className="home-contain rounded-0 p-0 bg-size blur-up lazyloaded"
                                style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                    display: "block",
                                }}>

                                <div className="bg-img blur-up lazyloaded"  >
                                <div className="left" style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)" }} onClick={prevImage}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="50"
                            viewBox="0 96 960 960"
                            width="50"
                        >
                            <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
                        </svg>
                    </div>
                    <div className="right" style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }} onClick={nextImage}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="50"
                            viewBox="0 96 960 960"
                            width="50"
                        >
                            <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
                        </svg>
                    </div>

                                    <div >
                                        <img
                                            style={{ height: "40vh" }}
                                            className="img-fluid bg-img blur-up lazyloaded"
                                            width="100%"
                                            src={process.env.REACT_APP_API_BASE_URL + sliderTable[currentIndex]?.img_url}
                                            alt=""
                                        />
                                        <h1 style={{ position: "absolute", top: "30px", left: "10px" }} className="heding-2">{sliderTable[currentIndex]?.slider_title}</h1>
                                        <h2 style={{ position: "absolute", top: "150px", left: "15px" }} className="content-2">{sliderTable[currentIndex]?.slider_description}</h2>
                                        <h5 style={{ position: "absolute", top: "200px", left: "15px" }} className="text-content">
                                            {sliderTable[currentIndex]?.slider_status === 0 ? "" : ""}
                                        </h5>
                                        <button
                                            style={{ position: "absolute", top: "210px", left: "15px" }}
                                            className="btn theme-bg-color btn-md text-white fw-bold mt-md-4 mt-2 mend-auto"
                                            onClick={() => console.log("Shop Now")}
                                            tabIndex={0}
                                        >
                                            Shop Now <i className="fa-solid fa-arrow-right icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* Home Section End */}
            {/* <Banner/> */}
            <Product />
        </>
    )
}

export default Home;