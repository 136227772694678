
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem, allRemoveItem, removeOneCompleteItem } from '../../reducetool/features/cardSlice';
import { apiService } from '../../constants/ApiService';


const Cart = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shoppingCart = useSelector(state => state.cardState);
    const authData = useSelector(state => state.authState);


    const handleAddToCart = (itemId) => {
        let filterArray = shoppingCart.items.find(data => {
            return data.id === itemId;
        });
        dispatch(addItem(filterArray));
    };

    const handleRemoveFromCart = (itemId) => {
        let filterArray = shoppingCart.items.find(data => {
            return data.id === itemId;
        });
        dispatch(removeItem(filterArray));
    };


    const createAddToCardData = async()=>{
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.post(`/api/AddToCartMaster/create?userId=${authData.user_id}`, shoppingCart.items, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response.message)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const gotoCheckout =async()=>{
        if(!authData.login){
            navigate("/login")
            return
        }else{
            await createAddToCardData()
            navigate("/checkout")
            return
        }
    }   

    const hanldeProductDetail = (productId) => {

        console.log(shoppingCart)
        let selectedItem = shoppingCart.items.find(item => item.id === productId);
        navigate("/ProductDetail", { state: { productId: selectedItem.id } });
    }

    let SubTotal = shoppingCart.items.reduce((total, item) => total + (item.product_price * item.quantity), 0)

    return (
        <>
            {/* Cart Section Start */}
            <section className="cart-section section-b-space">
                <div className="container-fluid-lg">
                    {shoppingCart.items.length != 0 ?
                        <div className="row g-sm-5 g-3">
                            <div className="col-xxl-9">
                                <div className="cart-table">
                                    <div className="table-responsive-xl">
                                        <table className="table">
                                            <tbody>
                                                {shoppingCart.items.length != 0 && shoppingCart?.items.map((product, index) => (
                                                    <tr key={index} className="product-box-contain">
                                                        <td className="product-detail">
                                                            <div className="product border-0">
                                                                <Link to={product.link} className="product-image" onClick={()=>{hanldeProductDetail(product.id)}}>
                                                                    <img src={process.env.REACT_APP_API_BASE_URL +product.img_url} className="img-fluid lazyload" alt={product.name} />
                                                                </Link>
                                                                <div className="product-detail">
                                                                    <ul>
                                                                        <li className="name">
                                                                            <Link to={product.link}>{product.product_title.substring(0,20)}...</Link>
                                                                        </li>
                                                                        {/* <li className="text-content">
                                                                            <span className="text-title">Sold By:</span> {product.vender_name}
                                                                        </li> */}
                                                                        <li className="text-content">
                                                                            <span className="text-title">Quantity</span> - {product.quantity}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="price">
                                                            <h4 className="table-title text-content">Price</h4>
                                                            <h5>
                                                               Rs {product.product_price} <del className="text-content">Rs {product.product_del_price}</del>
                                                            </h5>
                                                            <h6 className="theme-color">You Save : Rs{product.product_del_price - product.product_price}</h6>
                                                        </td>
                                                        <td className="quantity">
                                                            <h4 className="table-title text-content">Qty</h4>
                                                            <div className="quantity-price">
                                                                <div className="cart_qty">
                                                                    <div className="input-group">
                                                                        <button
                                                                            type="button"
                                                                            className="btn qty-left-minus"
                                                                            data-type="minus"
                                                                            data-field=""
                                                                            onClick={() => handleRemoveFromCart(product.id)}

                                                                        >
                                                                            <i
                                                                                className="fa fa-minus ms-0"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                        <input
                                                                            className="form-control input-number qty-input"
                                                                            type="text"
                                                                            name="quantity"
                                                                            value={product.quantity}
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            className="btn qty-right-plus"
                                                                            data-type="plus"
                                                                            data-field=""
                                                                            onClick={() => handleAddToCart(product.id)}
                                                                        >
                                                                            <i
                                                                                className="fa fa-plus ms-0"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="subtotal">
                                                            <h4 className="table-title text-content">Total</h4>
                                                            <h5>Rs {product.quantity * product.product_price}</h5>
                                                        </td>
                                                        <td className="save-remove">
                                                            <h4 className="table-title text-content">Action</h4>
                                                            {/* <a
                                                                className="remove close_button"
                                                                href="javascript:void(0)"
                                                                onClick={()=>{dispatch(removeOneCompleteItem(product.id))}}
                                                            >
                                                                Remove
                                                            </a> */}
                                                            <button className="btn btn-sm btn-animation proceed-btn fw-bold"
                                                                onClick={() => { dispatch(removeOneCompleteItem(product.id)) }}
                                                            >Remove</button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-3">
                                <div className="summery-box p-sticky">
                                    <div className="summery-header">
                                        <h3>Cart Total</h3>
                                    </div>
                                    <div className="summery-contain">
                                        <div className="coupon-cart">
                                            <h6 className="text-content mb-2">Coupon Apply</h6>
                                            <div className="mb-3 coupon-box input-group">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Enter Coupon Code Here..."
                                                />
                                                <button className="btn-apply">Apply</button>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>
                                                <h4>Subtotal</h4>
                                                <h4 className="price">{shoppingCart.items.reduce((total, item) => total + (item.product_price * item.quantity), 0)}</h4>
                                            </li>
                                            <li>
                                                <h4>Coupon Discount</h4>
                                                <h4 className="price">(-) 0.00</h4>
                                            </li>
                                            <li className="align-items-start">
                                                <h4>Shipping</h4>
                                                <h4 className="price text-end">₹100</h4>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="summery-total">
                                        <li className="list-total border-top-0">
                                            <h4>Total (₹)</h4>
                                            <h4 className="price theme-color">₹{SubTotal + 100}</h4>
                                        </li>
                                    </ul>
                                    <div className="button-group cart-button">
                                        <ul>
                                            <li>
                                                <button
                                                    onClick={() => {gotoCheckout()}}
                                                    className="btn btn-animation proceed-btn fw-bold"
                                                >
                                                    Process To Checkout
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={() => { navigate("/") }}
                                                    className="btn btn-light shopping-button text-dark"
                                                >
                                                    <i className="fa-solid fa-arrow-left-long" />
                                                    Return To Shopping
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :

                        <div className="col-xxl-12">
                            <div className="summery-box p-sticky" style={{ padding: 20 }}>
                                <h3 style={{ textAlign: "center", marginTop: "20px", margin: 20 }}>Your shopping cart is currently empty.</h3>
                                <div className="button-group cart-button ">
                                    <ul>
                                        <li>
                                            <button
                                                onClick={() => { navigate("/") }}
                                                className="btn theme-bg-color shopping-button text-light"
                                            >
                                                <i className="fa-solid fa-arrow-left-long" />
                                                Return To Shopping
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
            {/* Cart Section End */}
        </>

    )
}

export default Cart;