import React, { useState, useEffect } from 'react'
import { useNavigate,Link } from "react-router-dom";
import { apiService } from '../../constants/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderOtpActive } from '../../reducetool/features/companySlice';

import { Helmet } from 'react-helmet';

const useScript = (src) => {
    useEffect(() => {
    const tag = document.createElement('script');
    //tag.async = true;
    tag.src = src;
    document.body.appendChild(tag);
    
    return () => {
      document.body.removeChild(tag);
    }
    }, [src])
    }


const Footer = () => {



    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authData = useSelector(state => state.authState);
    const [companyDetail, setCompanyDetail] = useState({
        company_name: '',
        company_address: '',
        company_pincode: '',
        company_phoneNumber: '',
        company_img_url: '',
        company_email: '',
        company_linkedin_link: '',
        company_fb_link: '',
        company_intragram_link: '',
        company_twitter_link: '',
        company_about_detail: '',
        company_terms: "",
        company_policy: "",
        company_order_otp_active:0
    });


    const getCompanyData = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/SystemMaster/companyDetail`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setCompanyDetail(response.systemMasterDataByID ? response.systemMasterDataByID : companyDetail)
                dispatch(setOrderOtpActive(response.systemMasterDataByID.company_order_otp_active?response.systemMasterDataByID.company_order_otp_active : 0))
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert(error);
        }
    };

 
    
    useEffect(() => {
        getCompanyData()
    }, [])



    const handleNavigation = (destination) => {

        if (destination === '/term') {
            navigate("/term", { state: { term : companyDetail.company_terms  } });
        } else if (destination === '/privacy') {
            navigate("/privacyPolicy", { state: { privatePolicy : companyDetail.company_policy  } });            
        } else if (destination === '/return') {
            navigate("/returnPolicy", { state: { returnPolicy : companyDetail.company_return  } });            
        } else {
            console.log('Navigating to Default Destination');
        }
    };

    
        
    // useScript('../assets/js/jquery-3.6.0.min.js');
    // useScript('../assets/js/jquery-ui.min.js'); 

    // useScript('../assets/js/bootstrap/bootstrap.bundle.min.js');
    // useScript('../assets/js/bootstrap/bootstrap-notify.min.js');
    // useScript('../assets/js/bootstrap/popper.min.js');

    // useScript('../assets/js/feather/feather.min.js');
    // useScript('../assets/js/feather/feather-icon.js');

    // useScript('../assets/js/lazysizes.min.js');
    

    // useScript('../assets/js/slick/slick.js');
    // useScript('../assets/js/slick/slick-animation.min.js');
    // useScript('../assets/js/custom-slick-animated.js');
    // useScript('../assets/js/slick/custom_slick.js');

    
    // useScript('../assets/js/ion.rangeSlider.min.js');
    // useScript('../assets/js/filter-sidebar.js');
    // useScript('../assets/js/quantity-2.js');
    // useScript('../assets/js/jquery.elevatezoom.js');
    // useScript('../assets/js/zoom-filter.js');
    // useScript('../assets/js/timer1.js');
    // useScript('../assets/js/sticky-cart-bottom.js');
    // useScript('../assets/js/wow.min.js');
    // useScript('../assets/js/custom-wow.js');
    // useScript('../assets/js/script.js');

    return(
        <>
        <Helmet>    

         {/* Footer Section Start */}
         <footer className="section-t-space">
                <div className="container-fluid-lg">
                    <div className="service-section">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="service-contain">
                                    <div className="service-box">
                                        <div className="service-image">
                                            <img
                                                src="../assets/svg/product.svg"
                                                className=" lazyload"
                                                alt=""
                                            />
                                        </div>
                                        <div className="service-detail">
                                            <h5>Every Fresh Products</h5>
                                        </div>
                                    </div>
                                    <div className="service-box">
                                        <div className="service-image">
                                            <img
                                                src="../assets/svg/delivery.svg"
                                                className=" lazyload"
                                                alt=""
                                            />
                                        </div>
                                        <div className="service-detail">
                                            <h5>Free Delivery For Order Over ₹50</h5>
                                        </div>
                                    </div>
                                    <div className="service-box">
                                        <div className="service-image">
                                            <img
                                                src="../assets/svg/discount.svg"
                                                className=" lazyload"
                                                alt=""
                                            />
                                        </div>
                                        <div className="service-detail">
                                            <h5>Daily Mega Discounts</h5>
                                        </div>
                                    </div>
                                    <div className="service-box">
                                        <div className="service-image">
                                            <img
                                                src="../assets/svg/market.svg"
                                                className=" lazyload"
                                                alt=""
                                            />
                                        </div>
                                        <div className="service-detail">
                                            <h5>Best Price On The Market</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-footer section-b-space section-t-space">
                        <div className="row g-md-4 g-3">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="footer-logo">
                                    <div className="theme-logo">
                                        <Link to="/">
                                            <img
                                                src="../assets/images/logo/SHIVKMS.png"
                                                className=" lazyload"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div className="footer-logo-contain">
                                        <p>{companyDetail.company_about_detail}
                                        </p>
                                        <ul className="address">
                                            <li>
                                                <i data-feather="home" />
                                                <a to="javascript:void(0)">
                                                    {companyDetail.company_address}{" "}{companyDetail.company_pincode}
                                                    {/* 1418 Riverwood Drive, CA 96052, India */}
                                                </a>
                                            </li>
                                            <li>
                                                <i data-feather="mail" />
                                                <a to="javascript:void(0)">{companyDetail.company_email}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer-title">
                                    <h4>Categories</h4>
                                </div>
                                <div className="footer-contain">
                                    <ul>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Vegetables &amp; Fruit
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Beverages
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Meats &amp; Seafood
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Frozen Foods
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Biscuits &amp; Snacks
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="shop-left-sidebar.html" className="text-content">
                                                Grocery &amp; Staples
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-xl col-lg-2 col-sm-3">
                                <div className="footer-title">
                                    <h4>Indiaeful Links</h4>
                                </div>
                                <div className="footer-contain">
                                    <ul>
                                        <li>
                                            <Link to="/" className="text-content">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ProductList" className="text-content">
                                                Shop
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about" className="text-content">
                                                About India
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog" className="text-content">
                                                Blog
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="text-content">
                                                Contact India
                                            </Link>
                                        </li>


                                        <li>
                                            <Link to="/term" className="text-content" > 
                                                Term and condition
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="text-content" to='/privacy'>
                                                Private Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="text-content" to='/return'>
                                                Return Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-sm-3">
                                <div className="footer-title">
                                    <h4>Help Center</h4>
                                </div>
                                <div className="footer-contain">
                                    <ul>
                                        <li>
                                            {/* <Link to="order-success.html" className="text-content">
                                                Your Order
                                            </Link> */}
                                        </li>
                                        <li>
                                            {/* <Link to="Indiaer-dashboard.html" className="text-content">
                                                Your Account
                                            </Link> */}
                                        </li>
                                        {/* <li>
                                            <Link to="order-tracking.html" className="text-content">
                                                Track Order
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="wishlist.html" className="text-content">
                                                Your Wishlist
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="search.html" className="text-content">
                                                Search
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to="/faq" className="text-content">
                                                FAQ
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="footer-title">
                                    <h4>Contact India</h4>
                                </div>
                                <div className="footer-contact">
                                    <ul>
                                        <li>
                                            <div className="footer-number">
                                                <i data-feather="phone" />
                                                <div className="contact-number">
                                                    <h6 className="text-content">Hotline 24/7 :</h6>
                                                    <h5>{companyDetail.company_phoneNumber}</h5>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer-number">
                                                <i data-feather="mail" />
                                                <div className="contact-number">
                                                    <h6 className="text-content">Email Address :</h6>
                                                    <h5>{companyDetail.company_email}</h5>
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li className="social-app mb-0">
                                            <h5 className="mb-2 text-content">Download App :</h5>
                                            <ul>
                                                <li className="mb-0">
                                                    <a
                                                        href="https://play.google.com/store/apps"
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src="../assets/images/playstore.svg"
                                                            className=" lazyload"
                                                            alt=""
                                                        />
                                                    </Link>
                                                </li>
                                                <li className="mb-0">
                                                    <a
                                                        href="https://www.apple.com/in/app-store/"
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src="../assets/images/appstore.svg"
                                                            className=" lazyload"
                                                            alt=""
                                                        />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sub-footer section-small-space">
                        <div className="reserve">
                            <h6 className="text-content">©{new Date().getFullYear} {companyDetail.company_name} All rights reserved</h6>
                        </div>
                        <div className="payment">
                            <img
                                src="../assets/images/payment/SHIVKMS.png"
                                className=" lazyload"
                                alt=""
                            />
                        </div>
                        <div className="social-link">
                            <h6 className="text-content">Stay connected :</h6>
                            <ul>
                                <li>
                                    <Link to={companyDetail.company_fb_link} target="_blank">
                                        <i className="fa-brands fa-facebook-f" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={companyDetail.company_twitter_link}  target="_blank">
                                        <i className="fa-brands fa-twitter" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={companyDetail.company_intragram_link} target="_blank">
                                        <i className="fa-brands fa-instagram" />
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="https://in.pinterest.com/" target="_blank">
                                        <i className="fa-brands fa-pinterest-p" />
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section End */}
            {/* Quick View Modal Box Start */}
            <div
                className="modal fade theme-modal view-modal"
                id="view"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-sm-4 g-2">
                                <div className="col-lg-6">
                                    <div className="slider-image">
                                        <img
                                            src="../assets/images/product/category/1.jpg"
                                            className="img-fluid  lazyload"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="right-sidebar-modal">
                                        <h4 className="title-name">
                                            Peanut Butter Bite Premium Butter Cookies 600 g
                                        </h4>
                                        <h4 className="price">₹36.99</h4>
                                        <div className="product-rating">
                                            <ul className="rating">
                                                <li>
                                                    <i data-feather="star" className="fill" />
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill" />
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill" />
                                                </li>
                                                <li>
                                                    <i data-feather="star" className="fill" />
                                                </li>
                                                <li>
                                                    <i data-feather="star" />
                                                </li>
                                            </ul>
                                            <span className="ms-2">8 Reviews</span>
                                            <span className="ms-2 text-danger">
                                                6 sold in last 16 hours
                                            </span>
                                        </div>
                                        <div className="product-detail">
                                            <h4>Product Details :</h4>
                                            <p>
                                                Candy canes sugar plum tart cotton candy chupa chups sugar
                                                plum chocolate I love. Caramels marshmallow icing dessert
                                                candy canes I love soufflé I love toffee. Marshmallow pie
                                                sweet sweet roll sesame snaps tiramisu jelly bear claw.
                                                Bonbon muffin I love carrot cake sugar plum dessert bonbon.
                                            </p>
                                        </div>
                                        <ul className="brand-list">
                                            <li>
                                                <div className="brand-box">
                                                    <h5>Brand Name:</h5>
                                                    <h6>Black Forest</h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="brand-box">
                                                    <h5>Product Code:</h5>
                                                    <h6>W0690034</h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="brand-box">
                                                    <h5>Product Type:</h5>
                                                    <h6>White Cream Cake</h6>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="select-size">
                                            <h4>Cake Size :</h4>
                                            <select className="form-select select-form-size">
                                                <option selected="">Select Size</option>
                                                <option value="1.2">1/2 KG</option>
                                                <option value={0}>1 KG</option>
                                                <option value="1.5">1/5 KG</option>
                                                <option value="red">Red Roses</option>
                                                <option value="pink">With Pink Roses</option>
                                            </select>
                                        </div>
                                        <div className="modal-button">
                                            <button
                                                onclick="location.href = 'cart.html';"
                                                className="btn btn-md add-cart-button icon"
                                            >
                                                Add To Cart
                                            </button>
                                            <button
                                                onclick="location.href = 'product-left.html';"
                                                className="btn theme-bg-color view-button icon text-white fw-bold btn-md"
                                            >
                                                View More Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Quick View Modal Box End */}
            {/* Location Modal Start */}
            <div
                className="modal location-modal fade theme-modal"
                id="locationModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Choose your Delivery Location
                            </h5>
                            <p className="mt-1 text-content">
                                Enter your address and we will specify the offer for your area.
                            </p>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="location-list">
                                <div className="search-input">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search Your Area"
                                    />
                                    <i className="fa-solid fa-magnifying-glass" />
                                </div>
                                <div className="disabled-box">
                                    <h6>Select a Location</h6>
                                </div>
                                <ul className="location-select cIndiatom-height">
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Alabama</h6>
                                            <span>Min: ₹130</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Arizona</h6>
                                            <span>Min: ₹150</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>California</h6>
                                            <span>Min: ₹110</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Colorado</h6>
                                            <span>Min: ₹140</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Florida</h6>
                                            <span>Min: ₹160</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Georgia</h6>
                                            <span>Min: ₹120</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Kansas</h6>
                                            <span>Min: ₹170</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Minnesota</h6>
                                            <span>Min: ₹120</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>New York</h6>
                                            <span>Min: ₹110</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="javascript:void(0)">
                                            <h6>Washington</h6>
                                            <span>Min: ₹130</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Location Modal End */}
            
            {/* Cookie Bar Box End */}
            {/* Deal Box Modal Start */}
            <div
                className="modal fade theme-modal deal-modal"
                id="deal-box"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title w-100" id="deal_today">
                                    Deal Today
                                </h5>
                                <p className="mt-1 text-content">Recommended deals for you.</p>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="deal-offer-box">
                                <ul className="deal-offer-list">
                                    <li className="list-1">
                                        <div className="deal-offer-contain">
                                            <Link to="shop-left-sidebar.html" className="deal-image">
                                                <img
                                                    src="../assets/images/vegetable/product/10.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link to="shop-left-sidebar.html" className="deal-contain">
                                                <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                                <h6>
                                                    ₹52.57 <del>57.62</del> <span>500 G</span>
                                                </h6>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="list-2">
                                        <div className="deal-offer-contain">
                                            <Link to="shop-left-sidebar.html" className="deal-image">
                                                <img
                                                    src="../assets/images/vegetable/product/1SHIVKMS.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link to="shop-left-sidebar.html" className="deal-contain">
                                                <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                                <h6>
                                                    ₹52.57 <del>57.62</del> <span>500 G</span>
                                                </h6>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="list-3">
                                        <div className="deal-offer-contain">
                                            <Link to="shop-left-sidebar.html" className="deal-image">
                                                <img
                                                    src="../assets/images/vegetable/product/12.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link to="shop-left-sidebar.html" className="deal-contain">
                                                <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                                <h6>
                                                    ₹52.57 <del>57.62</del> <span>500 G</span>
                                                </h6>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="list-1">
                                        <div className="deal-offer-contain">
                                            <Link to="shop-left-sidebar.html" className="deal-image">
                                                <img
                                                    src="../assets/images/vegetable/product/13.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link to="shop-left-sidebar.html" className="deal-contain">
                                                <h5>Blended Instant Coffee 50 g Buy 1 Get 1 Free</h5>
                                                <h6>
                                                    ₹52.57 <del>57.62</del> <span>500 G</span>
                                                </h6>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Deal Box Modal End */}
           
        </Helmet>

        </>
    )
}

export default Footer;