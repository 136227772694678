import React from "react";

const GiftInfoModal = (props) => {
    return (
        <>
            <div
                className="modal fade theme-modal show"
                id="add-address"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingLeft: 0 }}
            >
                {/* //                                Add a new address */}

                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                How to avail this Gift? 
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => { props.handleGiftClose() }}
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="information-box">
  <ul>
    <li>
      Store cream cakes in a refrigerator. Fondant cakes should be stored in an
      air conditioned environment.
    </li>
    <li>
      Slice and serve the cake at room temperature and make sure it is not
      exposed to heat.
    </li>
    <li>Use a serrated knife to cut a fondant cake.</li>
    <li>
      Sculptural elements and figurines may contain wire supports or toothpicks
      or wooden skewers for support.
    </li>
    <li>
      Please check the placement of these items before serving to small
      children.
    </li>
    <li>The cake should be consumed within 24 hours.</li>
    <li>Enjoy your cake!</li>
  </ul>
</div>

                     
                        </div>
                        <div className="modal-footer">
                           
                            <button
                                type="button"
                                className="btn theme-bg-color btn-md text-white"
                                // data-bs-dismiss="modal"
                                onClick={() => { props.handleGiftClose() }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default GiftInfoModal;