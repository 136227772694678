import React, { useState } from 'react';

function AddColorsToProduct() {
  const [productName, setProductName] = useState('');
  const [availableColors, setAvailableColors] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [manualColorInput, setManualColorInput] = useState('');

  const handleAddColor = (color) => {
    if (!selectedColors.includes(color)) {
      setSelectedColors([...selectedColors, color]);
      setManualColorInput('');
    }
  };

  const handleRemoveColor = (color) => {
    const updatedColors = selectedColors.filter((c) => c !== color);
    setSelectedColors(updatedColors);
  };

  const handleSave = () => {
    console.log('Selected colors:', selectedColors);
  };

  return (
    <div>
      <h2>Add Colors to Product</h2>
      <div>
        <label>Product Name:</label>
        <input
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
      </div>
      <div>
        <h3>Available Colors:</h3>
        {availableColors.map((color, index) => (
          <div key={index}>
            <span>{color}</span>
            <button onClick={() => handleAddColor(color)}>Add</button>
            <button onClick={() => handleRemoveColor(color)}>Remove</button>
          </div>
        ))}
      </div>
      <div>
        <h3>Add Color Manually:</h3>
        <input
          type="text"
          value={manualColorInput}
          onChange={(e) => setManualColorInput(e.target.value)}
        />
        <button onClick={() => handleAddColor(manualColorInput)}>Add</button>
      </div>
      <div>
        <button onClick={handleSave}>Save</button>
      </div>
      <div>
        <h3>Selected Colors:</h3>
        {selectedColors.map((color, index) => (
          <div key={index}>
            <span>{color}</span>
            <button onClick={() => handleRemoveColor(color)}>Remove</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddColorsToProduct;
