import React from "react";

const RewardCardStatsModal = (props) => {
    return (
        <>
            <div
                className="modal fade theme-modal show"
                id="add-address"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingLeft: 0 }}
            >
                {/* //                                Add a new address */}

                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Points Contributors <i className="fa fa-info-circle"></i>
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => { props.handleRewardCardStatsClose() }}
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                        <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Reward Card no.</th>
      <th scope="col">Level reached</th>
      <th scope="col">Coins required for next level</th>
      <th scope="col">Order Id</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>EC-45345-345345-345</td>
      <td>2</td>
      <td>6000 Shiv Coins</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>EC-45345-345345-345</td>
      <td>4</td>
      <td>6000 Shiv Coins</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>EC-45345-345345-345</td>
      <td>5</td>
      <td>6000 Shiv Coins</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
                        </div>
                        <div className="modal-footer">
                           
                            <button
                                type="button"
                                className="btn theme-bg-color btn-md text-white"
                                // data-bs-dismiss="modal"
                                onClick={() => { props.handleRewardCardStatsClose() }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RewardCardStatsModal;