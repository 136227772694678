import React from "react";

const TestimonialModal = (props) => {
    return (
        <>
            <div
                className="modal fade theme-modal show"
                id="add-testimonial"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingLeft: 0 }}
            >
                {/* //                                Add a new Testimonial */}

                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">

                                {props.isAddingTestimonial ? "Add a new testimonial" : "Update testimonial"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => { props.handleEditConfirm() }}
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                    <textarea
                                    style={{height:"50vh"}}
                                        rows="5"
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        id="client_feedback"
                                        name="client_feedback"
                                        value={props.newTestimonialData.client_feedback}
                                        onChange={(e) => { props.handleInputChange(e) }}
                                    ></textarea>
                                    <label htmlFor="fname">Write about us: </label>
                                    { props.errorTestimonialData.client_feedback && <div className="text-danger">{props.errorTestimonialData.client_feedback}</div>}
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary btn-md"
                                onClick={() => { props.handleEditConfirm() }}
                            // data-bs-dismiss="modal"
                            >
                                Close
                            </button>


                            <button
                                type="button"
                                className="btn theme-bg-color btn-md text-white"
                                // data-bs-dismiss="modal"
                                onClick={() => { props.isAddingTestimonial ? props.handleAddTestimonial() : props.handleUpdateAddTestimonial() }}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TestimonialModal;