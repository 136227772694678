import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { apiService } from '../../constants/ApiService';

import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem } from '../../reducetool/features/cardSlice';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';

const Product = () => {

    const navigate = useNavigate();
    const [itemTable, setItemTable] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const dispatch = useDispatch();
    // const colors = ['Red', 'Green', 'Blue', 'Yellow', 'Purple', 'Orange', 'Pink', 'Brown', 'Cyan', 'Magenta', 'Lime', 'Teal', 'Maroon', 'Navy', 'Olive', 'Sky Blue', 'Indigo', 'Turquoise', 'Violet', 'Silver', 'Gold', 'Black', 'White', 'Gray'];
    // const discountOptions = [
    //     { range: 'upto 5%', count: 6 },
    //     { range: '5% - 10%', count: 8 },
    //     { range: '10% - 15%', count: 10 },
    //     { range: '15% - 25%', count: 14 },
    //     { range: 'More than 25%', count: 13 }
    // ];
    // const sizeTable = [
    //     { size_name: 'XS' },
    //     { size_name: 'S' },
    //     { size_name: 'M' },
    //     { size_name: 'L' },
    //     { size_name: 'XL' },
    //     { size_name: 'XXL' },
    //     { size_name: 'XXXL' },
    //     // Add more sizes as needed
    // ];
    const [filterTable, setFilterTable] = useState([]);
    const [selectedFilterKey, setSelectedFilterKey] = useState([]);
    const [categoryTable, setCategoryTable] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categoryTranxFilterData, setCategoryTranxFilterData] = useState([])
    const [subCategory, setSubCategory] = useState("")
    const [filterStyle, setFilterStyle] = useState(false);

    const handleAddToCart = (itemId) => {
        let filterArray = itemTable.find(data => {
            return data.id === itemId;
        });
    };

    const handleNavigration = async (productId) => {
        let selectedItem = itemTable.find(item => item.id === productId);
        await dispatch(addItem(selectedItem));
        navigate("/Cart")
    }

    const hanldeProductDetail = (productId) => {
        let selectedItem = itemTable.find(item => item.id === productId);
        navigate("/ProductDetail", { state: { productId: selectedItem.id, subcategoryName: selectedItem.product_sub_category } });
    }

    const getItemData = async (page = 1, limit = 10, categoryName = "", subcategoryName = "", selectedFilterKey) => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.post(`/api/ProductMaster/allproduct?pageNo=${page}&limit=${limit}&categorySearchKey=${categoryName}&subcategoryName=${subcategoryName}`, selectedFilterKey ? selectedFilterKey : [], config);
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                setItemTable(response.productMasterData ? response.productMasterData : [])
                // setTotalPage(response.totalPages)
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };


    useEffect(() => {
        getItemData();
    }, [!itemTable]);

    const handlePageClick = (data) => {
        getItemData(data.selected + 1)
    };

    const getFilterMaster = async () => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/SystemFilterMaster/allfilter`, config)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                setFilterTable(response.systemFilterMasterData)
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const handleKeySelection = (key) => {

        const colorIndex = selectedFilterKey.indexOf(key);
        if (colorIndex === -1) {
            setSelectedFilterKey([...selectedFilterKey, key]);
            getItemData(1, 10, selectedCategory, subCategory, [...selectedFilterKey, key])

        } else {
            const updatedColors = [...selectedFilterKey];
            updatedColors.splice(colorIndex, 1);
            setSelectedFilterKey(updatedColors);

            getItemData(1, 10, selectedCategory, subCategory, updatedColors)

        }
    };

    const getCategoryData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/CategoryMaster/allcategory?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response.categoryMasterData)
                setCategoryTable(response.categoryMasterData)
                // setTotalPage(response.totalPages)
                // alert(response.message);
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    useEffect(() => {
        getCategoryData();
        getFilterMaster()
    }, [!categoryTable]);

    // const handleCategorySelection = (categoryName) => {

    //     const newCategory = selectedCategory === categoryName ? "" : categoryName;
    //     setSelectedCategory(newCategory);
    //     getItemData(1, 10, newCategory)

    //     let filterData = categoryTable.find((data) => data.category_name == categoryName)
    //     setCategoryTranxFilterData(filterData.categoryTranxMaster)
    // };



    // useEffect(() => {
    //     const queryParamsString = window.location.search; 
    //     const queryParams = new URLSearchParams(queryParamsString);
    //     const categoryName = queryParams.get('categoryName'); 
    //     const subcategoryName = queryParams.get('subcategoryName');


    //     setSelectedCategory(categoryName);
    //     setSubCategory(subcategoryName)
    //     getItemData(1, 10, categoryName,subcategoryName)

    //     console.log(categoryName,subcategoryName)
    // }, []);



    const handleCategorySelection = (categoryName) => {
        setSelectedCategory(prevCategory => {
            const newCategory = prevCategory === categoryName ? "" : categoryName;
            getItemData(1, 10, newCategory);

            const filterData = categoryTable.find(data => data.category_name === newCategory);
            setCategoryTranxFilterData(filterData ? filterData.categoryTranxMaster : []);

            return newCategory;
        });
    };

    const calculateAverageRating = (reviewMaster) => {

        if (!reviewMaster || reviewMaster.length === 0) {
            return 0;
        }

        const ratings = reviewMaster.map(review => review.rating_stars);
        const filteredRatings = ratings.filter(rating => rating != null);

        if (filteredRatings.length === 0) {
            return 0; // Return 0 if there are no valid ratings
        }

        const sum = filteredRatings.reduce((total, rating) => total + rating, 0);
        const averageRating = sum / filteredRatings.length;

        let roundedRating = Math.floor(averageRating); // Round down by default
        if (averageRating - Math.floor(averageRating) >= 0.5) {
            roundedRating++;
        }

        return roundedRating;
    };

    const FilterDisplay = () => {
        setFilterStyle(true);
        if (filterStyle == true) {
            setFilterStyle(false);
        }
    }

    return (
        <>

            <>
                {/* Breadcrumb Section Start */}
                <section className="breadscrumb-section pt-0">
                    <div className="container-fluid-lg">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadscrumb-contain">
                                    <h2>Shop List</h2>
                                    <nav>
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="fa-solid fa-house" />
                                                </Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Shop List
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Breadcrumb Section End */}

                {/* Shop Section Start */}
                <section className="section-b-space shop-section">
                    <div className="container-fluid-lg">
                        <div className="row">



                            {/* this is a complted filter code */}

                            {/* <div className="col-custome-3">
                                <div
                                    className={filterStyle ? "left-box wow fadeInUp show" : "left-box wow fadeInUp"}
                                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                                >


                                    <div className="shop-left-sidebar">
                                        <div className="back-button">
                                            <a onClick={FilterDisplay}>
                                                <h3>
                                                    <i className="fa-solid fa-arrow-left" /> Back
                                                </h3>
                                            </a>
                                        </div>


                                        {/*  filter search key name */}
                            {/* <div className="filter-category">
                                            <div className="filter-title">
                                                <h2>Filters</h2>
                                                <a href="javascript:void(0)">Clear All</a>
                                            </div>
                                            <ul>
                                                {selectedFilterKey.map((filtername, index) => (
                                                    <li key={index}>
                                                        <a href="javascript:void(0)">{filtername}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div> */}


                            {/* // filter  code */}

                            {/* <div className="accordion custome-accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne"
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne"
                                                    >
                                                        <span>Categories</span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseOne"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="headingOne"
                                                >
                                                    <div className="accordion-body">
                                                        <ul className="category-list custom-padding custom-height">
                                                            {categoryTable.map((category, index) => (
                                                                <li key={index}>
                                                                    <div className="form-check ps-0 m-0 category-list-box">
                                                                        <input
                                                                            className="checkbox_animated"
                                                                            type="checkbox"
                                                                            id={`category${index}`}
                                                                            name="category"
                                                                            value={category.category_name}
                                                                            checked={selectedCategory === category.category_name}
                                                                            onChange={() => handleCategorySelection(category.category_name)}
                                                                        // onChange={ selectedCategory ? ()=> handleRemoveCatgorySelection() :() => handleCategorySelection(category.category_name)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`category${index}`}>
                                                                            <span className="name">{category.category_name}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {selectedCategory && filterTable.map((data, index) => {
                                                // console.log(data.filter_name)

                                                // const filteredData = categoryTranxFilterData.filter(item => {
                                                //     console.log(data.filter_name ===  item.filter_name,"jfjfjjffjfjfjfjj")
                                                //     return data.filter_name ===  item.filter_name
                                                // });
                                                // const filteredData = categoryTranxFilterData.filter(item => item.keyName === 'size');
                                                // const hasMatch = categoryTranxFilterData.some(item => item.keyName === data.filter_name);


                                                // console.log(filteredData,"filetr dtaa check     ")

                                                // console.log(hasMatch,"hasMatchhasMatch")

                                                // const filteredCategoryData = categoryTranxFilterData.filter((key) => key.filter_name === data.filter_data);
                                                return (
                                                    <React.Fragment key={index}>
                                                        {categoryTranxFilterData.some(item => item.filter_name.includes(data.filter_name)) && (
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                                    <button
                                                                        className="accordion-button collapsed"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapse${index}`}
                                                                        aria-expanded="false"
                                                                        aria-controls={`collapse${index}`}
                                                                    >
                                                                        <span>{data.filter_name}</span>
                                                                    </button>
                                                                </h2>
                                                                <div
                                                                    id={`collapse${index}`}
                                                                    className="accordion-collapse collapse"
                                                                    aria-labelledby={`heading${index}`}
                                                                >
                                                                    <div className="accordion-body">
                                                                        <ul className="category-list custom-padding">
                                                                            {data?.systemFilterTranxMaster.map((item, idx) => (
                                                                                <li key={idx}>
                                                                                    <div className="form-check ps-0 m-0 category-list-box">
                                                                                        <input
                                                                                            className="checkbox_animated"
                                                                                            type="checkbox"
                                                                                            onClick={() => handleKeySelection(item.filter_data)}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`color${index}-${idx}`}>
                                                                                            <span className="name">{item.filter_data}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div> */} 



                            <div className="col-custome-12">
                                {/* <div className="show-button">
                                    <div className="filter-button-group mt-0">
                                        <div className="filter-button d-inline-block d-lg-none">
                                            <a onClick={FilterDisplay}>
                                                <i className="fa-solid fa-filter" /> Filter Menu
                                            </a>
                                        </div>
                                    </div>

                                    <div className="top-filter-menu">
                                        <div className="category-dropdown">
                                            <h5 className="text-content">Sort By :</h5>
                                            <div className="dropdown">
                                                <button
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <span>Most Popular</span>{" "}
                                                    <i className="fa-solid fa-angle-down" />
                                                </button>
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                >
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="pop"
                                                            href="javascript:void(0)"
                                                        >
                                                            Popularity
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="low"
                                                            href="javascript:void(0)"
                                                        >
                                                            Low - High Price
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="high"
                                                            href="javascript:void(0)"
                                                        >
                                                            High - Low Price
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="rating"
                                                            href="javascript:void(0)"
                                                        >
                                                            Average Rating
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="aToz"
                                                            href="javascript:void(0)"
                                                        >
                                                            A - Z Order
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="zToa"
                                                            href="javascript:void(0)"
                                                        >
                                                            Z - A Order
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            id="off"
                                                            href="javascript:void(0)"
                                                        >
                                                            % Off - Hight To Low
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="grid-option d-none d-md-block">
                                            <ul>
                                                <li className="three-grid">
                                                    <a href="javascript:void(0)">
                                                        <img
                                                            src="../assets/svg/grid-3.svg"
                                                            className="blur-up lazyloaded"
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li className="grid-btn d-xxl-inline-block d-none active">
                                                    <a href="javascript:void(0)">
                                                        <img
                                                            src="../assets/svg/grid-4.svg"
                                                            className="blur-up d-lg-inline-block d-none lazyloaded"
                                                            alt=""
                                                        />
                                                        <img
                                                            src="../assets/svg/grid.svg"
                                                            className="blur-up lazyload img-fluid d-lg-none d-inline-block"
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                                <li className="list-btn">
                                                    <a href="javascript:void(0)">
                                                        <img
                                                            src="../assets/svg/list.svg"
                                                            className="blur-up lazyloaded"
                                                            alt=""
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}


                                {/* // show product detail code  */}
                                <div className="section-b-space">
                                <div className="row row-cols-xxl-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-sm-4 g-3 no-arrow">
                                    {itemTable && itemTable.length !== 0 && itemTable?.map((product, index) => (
                                        <div>
                                        <div key={index} className="product-box-3 h-100 wow fadeInUp"

                                            style={{ visibility: "visible", animationName: "fadeInUp" }}>
                                            <div className="product-header">
                                                <div className="product-image">
                                                    <img
                                                        src={process.env.REACT_APP_API_BASE_URL +product.img_url}
                                                        className="img-fluid blur-up lazyloaded"
                                                        alt=""
                                                        onClick={() => { hanldeProductDetail(product.id) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="product-footer">
                                                <div className="product-detail position-relative">
                                                    <span className="span-name">{product.product_sub_category}</span>
                                                   
                                                        <h5 className="name" onClick={() => { hanldeProductDetail(product.id) }}
                                                        >
                                                            {product.product_title}
                                                        </h5>
                                                    

                                                    {
                                                        product?.reviewMaster && product?.reviewMaster.length != 0 ?
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <Box
                                                                        sx={{
                                                                            '& > legend': { mt: 2 },
                                                                        }}
                                                                    >
                                                                        <Rating name="read-only" value={calculateAverageRating(product?.reviewMaster)} readOnly />
                                                                    </Box>

                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <Box
                                                                        sx={{
                                                                            '& > legend': { mt: 2 },
                                                                        }}
                                                                    >
                                                                        <Rating name="read-only" value={0} readOnly />
                                                                    </Box>

                                                                </div>
                                                            </>
                                                    }





                                                    {/* <div className="product-rating mt-2">

                                                        <ul className="rating">
                                                            <li>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star fill"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                            </li>
                                                            <li>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star fill"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                            </li>
                                                            <li>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                            </li>
                                                            <li>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                            </li>
                                                            <li>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={24}
                                                                    height={24}
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                            </li>
                                                        </ul>
                                                        <span>(2.4)</span>






                                                    </div> */}
                                                    {/* <h6 className="unit">250 ml</h6> */}
                                                    <h5 className="price">
                                                        <span className="theme-color">Rs {product.product_price}</span>{" "}
                                                        <del>Rs {product.product_del_price}</del>
                                                    </h5>
                                                    {/* <div className="add-to-cart-box bg-white">
                                                        <button className="btn btn-add-cart addcart-button" onClick={() => handleNavigration(product.id)}>
                                                            Add
                                                            <span className="add-icon bg-light-gray">
                                                                <i className="fa-solid fa-plus" />
                                                            </span>
                                                        </button>

                                                    </div> */}


                                                    <div className="add-to-cart-box theme-color">
                                                        <button className="btn btn-add-cart addcart-button text-light theme-bg-color" onClick={() => handleNavigration(product.id)} >
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))}
                                </div>
                                </div>


                                {/* <nav className="custome-pagination">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                            <a
                                                className="page-link"
                                                href="javascript:void(0)"
                                                tabIndex={-1}
                                                aria-disabled="true"
                                            >
                                                <i className="fa-solid fa-angles-left" />
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="javascript:void(0)">
                                                1
                                            </a>
                                        </li>
                                        <li className="page-item" aria-current="page">
                                            <a className="page-link" href="javascript:void(0)">
                                                2
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="javascript:void(0)">
                                                3
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="javascript:void(0)">
                                                <i className="fa-solid fa-angles-right" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Shop Section End */}
            </>

        </>
    )
}

export default Product;