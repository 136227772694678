const Banner = () => {
    return(
        <>
        {/* Banner Section Start */}
        <section className="banner-section ratio_60 wow fadeInUp">
                <div className="container-fluid-lg">
                    <div className="banner-slider">
                        <div>
                            <div className="banner-contain hover-effect">
                                <img
                                    src="../assets/images/vegetable/banner/4.jpg"
                                    className="bg-img  lazyload"
                                    alt=""
                                />
                                <div className="banner-details">
                                    <div className="banner-box">
                                        <h6 className="text-danger">5% OFF</h6>
                                        <h5>Hot Deals on New Items</h5>
                                        <h6 className="text-content">
                                            Daily Essentials Eggs &amp; Dairy
                                        </h6>
                                    </div>
                                    <a
                                        href="shop-left-sidebar.html"
                                        className="banner-button text-white"
                                    >
                                        Shop Now <i className="fa-solid fa-right-long ms-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-contain hover-effect">
                                <img
                                    src="../assets/images/vegetable/banner/5.jpg"
                                    className="bg-img  lazyload"
                                    alt=""
                                />
                                <div className="banner-details">
                                    <div className="banner-box">
                                        <h6 className="text-danger">5% OFF</h6>
                                        <h5>Buy More &amp; Save More</h5>
                                        <h6 className="text-content">Fresh Vegetables</h6>
                                    </div>
                                    <a
                                        href="shop-left-sidebar.html"
                                        className="banner-button text-white"
                                    >
                                        Shop Now <i className="fa-solid fa-right-long ms-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-contain hover-effect">
                                <img
                                    src="../assets/images/vegetable/banner/6.jpg"
                                    className="bg-img  lazyload"
                                    alt=""
                                />
                                <div className="banner-details">
                                    <div className="banner-box">
                                        <h6 className="text-danger">5% OFF</h6>
                                        <h5>Organic Meat Prepared</h5>
                                        <h6 className="text-content">Delivered to Your Home</h6>
                                    </div>
                                    <a
                                        href="shop-left-sidebar.html"
                                        className="banner-button text-white"
                                    >
                                        Shop Now <i className="fa-solid fa-right-long ms-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-contain hover-effect">
                                <img
                                    src="../assets/images/vegetable/banner/7.jpg"
                                    className="bg-img  lazyload"
                                    alt=""
                                />
                                <div className="banner-details">
                                    <div className="banner-box">
                                        <h6 className="text-danger">5% OFF</h6>
                                        <h5>Buy More &amp; Save More</h5>
                                        <h6 className="text-content">Nuts &amp; Snacks</h6>
                                    </div>
                                    <a
                                        href="shop-left-sidebar.html"
                                        className="banner-button text-white"
                                    >
                                        Shop Now <i className="fa-solid fa-right-long ms-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}
        </>
    )
}

export default Banner;