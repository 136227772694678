import React, { useState, useEffect } from 'react';
import { apiService } from '../ApiService';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { resetToken } from '../../reducetool/features/authSlice';
import { allRemoveItem } from '../../reducetool/features/cardSlice';
import { AddAllItem } from '../../reducetool/features/cardSlice';
import ProductList from '../../pages/Product/ProductList';

const Header = () => {

    
    //useScript('./assets/js/theme-setting.js');

    const [categoryTable, setCategoryTable] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shoppingCart = useSelector(state => state.cardState);
    const authData = useSelector(state => state.authState);
    const [ searchKeyData , setSearchKeyData] = useState("");
    const [ showCategory , setShowCategory] = useState(false);

    const showCategoryFx = () => {
        if(showCategory===true){
            setShowCategory(false);
        }else{
            setShowCategory(true);
        }
    }


    const getCategoryData = async (page = 1, limit = 10, search = "") => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/CategoryMaster/allcategory?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response.categoryMasterData)
                setCategoryTable(response.categoryMasterData)
                // setTotalPage(response.totalPages)
                // console.log(response.message);
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    const getAddToCartData = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/AddToCartMaster/addToCartByUserId?userId=${authData.user_id ? authData.user_id : ""}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                if(response.addToCartMasterData.length != 0)
                {
                    dispatch(AddAllItem(response.addToCartMasterData))
                }
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }


    }

    useEffect(() => {
        getAddToCartData();
        getCategoryData();
    }, [authData.login, !categoryTable]);

    const userLogout = () => {
        navigate("/Login")
        dispatch(resetToken())
        dispatch(allRemoveItem())
        return
    }


    const goToProductList = (categoryName, subcategoryName) => {
        // navigate(`/ProductList?categoryName=${categoryName}&subcategoryName=${subcategoryName}`);
        setShowCategory(false);
        navigate('/ProductList', { state: { categoryName: categoryName, subcategoryName: subcategoryName } });
    }



    const onHandleSearchKeyItem = () => {
        navigate("/searchProduct", { state: { searchKey: searchKeyData } })
    }

    return (
        <>

            {/* Header Start */}
            <header className="pb-md-4 pb-0">

                <div className="top-nav top-header sticky-header">
                    <div className="container-fluid-lg">
                        <div className="row">
                            <div className="col-12">
                                <div className="navbar-top">
                                    <button
                                        className="navbar-toggler d-xl-none d-inline navbar-menu-button"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#primaryMenu"
                                    >
                                        <span className="navbar-toggler-icon">
                                            <i className="fa-solid fa-bars" />
                                        </span>
                                    </button>
                                    <Link to="/" className="web-logo nav-logo">
                                        <img
                                            src="../assets/images/logo/SHIVKMS.png"
                                            className="img-fluid lazyload"
                                            alt=""
                                        />
                                    </Link>
                                    <div className="middle-box">

                                        <div className="search-box">
                                            <div className="input-group">
                                                <input
                                                    type="search"
                                                    className="form-control"
                                                    placeholder="I'm searching for..."
                                                    aria-label="Recipient's Indiaername"
                                                    aria-describedby="button-addon2"
                                                    onChange={(e) => { setSearchKeyData(e.target.value) }}
                                                    />
                                                <button className="btn" type="button" id="button-addon2" onClick={()=>{onHandleSearchKeyItem()}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="rightside-box">
                                        <div className="search-full">
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <i data-feather="search" className="font-light" />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control search-type"
                                                    placeholder="Search here.."
                                                />
                                                <span className="input-group-text close-search">
                                                    <i data-feather="x" className="font-light" />
                                                </span>
                                            </div>
                                        </div>
                                        <ul className="right-side-menu">
                                            <li className="right-side">
                                                <div className="delivery-login-box">
                                                    <div className="delivery-icon">
                                                        <div className="search-box">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="right-side">
                                                {/* <a href="contact-India.html" className="delivery-login-box"> */}
                                                    <div className="delivery-icon">
                                                        <i data-feather="phone-call" />
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h6>24/7 Delivery</h6>
                                                        <h5>+91 9212552928</h5>
                                                    </div>
                                                {/* </a> */}
                                            </li>

                                            {/*  user cart  */}


                                            <li className="right-side">
                                                <div className="onhover-dropdown header-badge">
                                                    <button
                                                        type="button"
                                                        onClick={() => { navigate("/Cart") }}
                                                        className="btn p-0 position-relative header-wishlist"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                                        <span className="position-absolute top-0 start-100 translate-middle badge">
                                                            {shoppingCart.items.length}
                                                        </span>
                                                    </button>
                                                    {/* <div className="onhover-div">
                                                        <ul className="cart-list">
                                                            <li className="product-box-contain">
                                                                <div className="drop-cart">
                                                                    <a
                                                                        href="product-left-thumbnail.html"
                                                                        className="drop-image"
                                                                    >
                                                                        <img
                                                                            src="../assets/images/vegetable/product/SHIVKMS.png"
                                                                            className=" lazyload"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                    <div className="drop-contain">
                                                                        <a href="product-left-thumbnail.html">
                                                                            <h5>Fantasy Crunchy Choco Chip Cookies</h5>
                                                                        </a>
                                                                        <h6>
                                                                            <span>1 x</span> ₹80.58
                                                                        </h6>
                                                                        <button className="close-button close_button">
                                                                            <i className="fa-solid fa-xmark" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="product-box-contain">
                                                                <div className="drop-cart">
                                                                    <a
                                                                        href="product-left-thumbnail.html"
                                                                        className="drop-image"
                                                                    >
                                                                        <img
                                                                            src="../assets/images/vegetable/product/2.png"
                                                                            className=" lazyload"
                                                                            alt=""
                                                                        />
                                                                    </a>
                                                                    <div className="drop-contain">
                                                                        <a href="product-left-thumbnail.html">
                                                                            <h5>
                                                                                Peanut Butter Bite Premium Butter Cookies
                                                                                600 g
                                                                            </h5>
                                                                        </a>
                                                                        <h6>
                                                                            <span>1 x</span> ₹25.68
                                                                        </h6>
                                                                        <button className="close-button close_button">
                                                                            <i className="fa-solid fa-xmark" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="price-box">
                                                            <h5>Total :</h5>
                                                            <h4 className="theme-color fw-bold">₹106.58</h4>
                                                        </div>
                                                        <div className="button-group">
                                                            <a
                                                                href="cart.html"
                                                                className="btn btn-sm cart-button"
                                                            >
                                                                View Cart
                                                            </a>
                                                            <a
                                                                href="checkout.html"
                                                                className="btn btn-sm cart-button theme-bg-color
                                                                text-white"
                                                            >
                                                                Checkout
                                                            </a>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </li>

                                            {/*  user profile  */}

                                            <li className="right-side onhover-dropdown">
                                                <div className="delivery-login-box">
                                                    <div className="delivery-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h6>{authData.user_name}</h6>
                                                        <h5>My Account</h5>
                                                    </div>
                                                </div>


                                                <div class="onhover-div onhover-div-login">
                                                    <ul class="user-box-name">
                                                        {!authData.login ?
                                                            <>
                                                                <li class="product-box-contain">
                                                                    <Link to="/Register">Register</Link>
                                                                </li>
                                                                <li class="product-box-contain">
                                                                    <i></i>
                                                                    <Link to="/Login">Log In</Link>
                                                                </li>


                                                            </>
                                                            :
                                                            <>

                                                                <li class="product-box-contain">
                                                                    <Link to="/userdashboard">User Detail</Link>
                                                                </li>

                                                                <li class="product-box-contain">
                                                                    <a onClick={() => { userLogout() }} >Log Out</a>
                                                                </li>
                                                            </>}


                                                        {/* <li class="product-box-contain">
                                                                    <a href="" onClick={() => { userLogout() }} >Log Out</a>
                                                                </li>    */}

                                                        {/* <li class="product-box-contain">
                                                            <a href="/forget">Forget Password</a>
                                                        </li> */}


                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">


                            <div className="header-nav">
                                <div className="header-nav-left">
                                    <button className="dropdown-category">
                                        <i data-feather="align-left" />
                                        <span>All Categories</span>
                                    </button>

                                    <div className="category-dropdown">
                                    <ul className="category-list">
                                        {categoryTable.map((categoryData, index) => (
                                            <>
                                                {/* <div className="category-dropdown"> */}
                                             
                                                    <li className="onhover-category-list">
                                                        <a href="javascript:void(0)" className="category-name">
                                                            {/* <img src="../assets/svg/1/vegetable.svg" alt="" /> */}
                                                            <h6>{categoryData.category_name}</h6>
                                                            <i className="fa-solid fa-angle-right" />
                                                        </a>


                                                        {categoryData.subCategoryMasters.length != 0 && 
                                                        <div className="onhover-category-box" style={{ minHeight: 100 }}>
                                                            <div className="list-1">
                                                                {/* <div className="category-title-box">
                                                                    <h5>Organic Vegetables</h5>
                                                                </div> */}
                                                                <ul>
                                                                    {categoryData?.subCategoryMasters.map((subCategoryData, index) => (
                                                                        <li>
                                                                            <a href="#" onClick={(event) => {
                                                                                event.preventDefault();
                                                                                goToProductList(categoryData.category_name, subCategoryData.sub_category_name);
                                                                            }}>
                                                                                {subCategoryData.sub_category_name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>}
                                                    </li>
                                            </>
                                            
                                        ))}
                                    </ul>

                                    </div>
                                    <div style={{overflowX:showCategory?"scroll":"visible"}} className={showCategory? "category-dropdown show":"category-dropdown"}>
                                        <div className="category-title">
                                            <h5>Categories</h5>
                                            <button
                                                type="button"
                                                onClick={showCategoryFx}
                                                className="btn p-0 close-button text-content"
                                            >
                                                <i className="fa-solid fa-xmark" />
                                            </button>
                                        </div>

                                        <ul className="category-list">
                                        {categoryTable.map((categoryData, index) => (
                                            <>
                                            <li className="onhover-category-list">
                                                <a href="javascript:void(0)" className="category-name">
                                                    {/* <img src="../assets/svg/1/vegetable.svg" alt="" /> */}
                                                    <h6>{categoryData.category_name}</h6>
                                                    <i className="fa-solid fa-angle-right" />
                                                </a>
                                                {categoryData.subCategoryMasters.length != 0 && 
                                                <div className="onhover-category-box">
                                                    <div className="list-1">
                                                        {/* <div className="category-title-box">
                                                            <h5>Organic Vegetables</h5>
                                                        </div> */}
                                                         <ul>
                                                        {categoryData?.subCategoryMasters.map((subCategoryData, index) => (
                                                       
                                                            <li>
                                                                <a href="#" onClick={(event) => {
                                                                    event.preventDefault();
                                                                    goToProductList(categoryData.category_name, subCategoryData.sub_category_name);
                                                                }}> {subCategoryData.sub_category_name}</a>
                                                            </li>
                                                        ))} 
                                                        </ul>
                                                    </div>
                                                   
                                                </div>}
                                            </li>
                                            </>
                                        ))}
                                        </ul>
                                        
                                    </div>
                                </div >






                                <div className="header-nav-middle">
                                    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
                                        <div
                                            className="offcanvas offcanvas-collapse order-xl-2"
                                            id="primaryMenu"
                                        >
                                            <div className="offcanvas-header navbar-shadow">
                                                <h5>Menu</h5>
                                                <button
                                                    className="btn-close lead"
                                                    type="button"
                                                    data-bs-dismiss="offcanvas"
                                                    aria-label="Close"
                                                />
                                            </div>
                                            <div className="offcanvas-body">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item">
                                                        <Link
                                                            className="nav-link"
                                                            to="/"
                                                        >
                                                            Home
                                                        </Link>
                                                    </li>

                                                    <li className="nav-item">
                                                        <Link
                                                            className="nav-link"
                                                            to="/ProductList"
                                                        >
                                                            Products
                                                        </Link>
                                                    </li>


                                                    {/* <li className="nav-item dropdown dropdown-mega">
                                                        <a
                                                            className="nav-link dropdown-toggle ps-xl-2 ps-0"
                                                            href="javascript:void(0)"
                                                            data-bs-toggle="dropdown"
                                                        >
                                                            Mega Menu
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-2">
                                                            <div className="row">
                                                                <div className="dropdown-column col-xl-3">
                                                                    <h5 className="dropdown-header">
                                                                        Daily Vegetables
                                                                    </h5>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Beans &amp; Brinjals
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Broccoli &amp; Cauliflower
                                                                    </a>
                                                                    <a
                                                                        href="shop-left-sidebar.html"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Chilies, Garlic
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Vegetables &amp; Salads
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Gourd, Cucumber
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Herbs &amp; Sprouts
                                                                    </a>
                                                                    <a
                                                                        href="demo-personal-portfolio.html"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Lettuce &amp; Leafy
                                                                    </a>
                                                                </div>
                                                                <div className="dropdown-column col-xl-3">
                                                                    <h5 className="dropdown-header">Baby Tender</h5>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Beans &amp; Brinjals
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Broccoli &amp; Cauliflower
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Chilies, Garlic
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Vegetables &amp; Salads
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Gourd, Cucumber
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Potatoes &amp; Tomatoes
                                                                    </a>
                                                                    <a
                                                                        href="shop-left-sidebar.html"
                                                                        className="dropdown-item"
                                                                    >
                                                                        Peas &amp; Corn
                                                                    </a>
                                                                </div>
                                                                <div className="dropdown-column col-xl-3">
                                                                    <h5 className="dropdown-header">
                                                                        Exotic Vegetables
                                                                    </h5>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        AsparagIndia &amp; Artichokes
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Avocados &amp; Peppers
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Broccoli &amp; Zucchini
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Celery, Fennel &amp; Leeks
                                                                    </a>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        href="shop-left-sidebar.html"
                                                                    >
                                                                        Chilies &amp; Lime
                                                                    </a>
                                                                </div>
                                                                <div className="dropdown-column dropdown-column-img col-3" />
                                                            </div>
                                                        </div>
                                                    </li> */}
                                                    <li className="nav-item dropdown">
                                                        <Link
                                                            className="nav-link"
                                                            to="/blog"
                                                        // data-bs-toggle="dropdown"
                                                        >
                                                            Blog
                                                        </Link>
                                                        {/* <ul className="dropdown-menu">
                                                            <li>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="blog-detail.html"
                                                                >
                                                                    Blog Detail
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className="dropdown-item" href="blog-list.html">
                                                                    Blog List
                                                                </a>
                                                            </li>
                                                        </ul> */}
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <Link
                                                            className="nav-link"
                                                            to="/about"
                                                        // data-bs-toggle="dropdown"
                                                        >
                                                            About us
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item dropdown">
                                                        <Link
                                                            className="nav-link"
                                                            to="/PointsData"
                                                        // data-bs-toggle="dropdown"
                                                        >
                                                            Points Section
                                                        </Link>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-nav-right">
                                    
                                        <span>Happy to have you, {authData.user_name}</span>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Header End */}
            {/* mobile fix menu start */}
            <div className="mobile-menu d-md-none d-block mobile-cart">
                <ul>
                    <li className="active">
                        <Link to="/">
                            <i className="iconly-Home icli" />
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="mobile-category">
                        <a onClick={showCategoryFx} href="javascript:void(0)">
                            <i className="iconly-Category icli js-link" />
                            <span>Category</span>
                        </a>
                    </li>
                    <li>
                        <Link to="/searchProduct" className="search-box">
                            <i className="iconly-Search icli" />
                            <span>Search</span>
                        </Link>
                    </li>
                    {/* <li>
                        <a href="wishlist.html" className="notifi-wishlist">
                            <i className="iconly-Heart icli" />
                            <span>My Wish</span>
                        </a>
                    </li> */}
                    <li>
                        <Link to="/Cart">
                            <i className="iconly-Bag-2 icli fly-cate" />
                            <span>Cart</span>
                        </Link>
                    </li>
                </ul>
            </div>
            {/* mobile fix menu end */}

            <div onClick={showCategoryFx} className={showCategory?"bg-overlay show ":"bg-overlay"}></div>

        </>

    )

}

export default Header;