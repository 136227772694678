import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { apiService } from '../../constants/ApiService';

const Register = () => {

    const initial = {
        "id": 0,
        "user_name": "",
        "user_mobile": "",
        "sponsored_user_mobile":"",
        "user_email": "",
        "user_pancard": "",
        "user_pass": "",
        "confirm_user_pass": ""
    }
    const [registerData, setRegisterData] = useState(initial)
    const [ loader , setLoader ] = useState(false)
    const [errorRegisterDetail, setErrorRegisterDetail] = useState({})
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [errorCheckboxMessage, setErrorCheckboxMessage] = useState('');
    const [errorApiError, setErrorApiError] = useState('');


    const navigate = useNavigate();


    const onChangeHandle = (e) => {
        const { name, value, type } = e.target;
        setRegisterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    }

    
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setErrorCheckboxMessage('');
    };


    function validateInput() {
        const errors = {};

        // Validation for user_name
        if (!registerData.user_name.trim()) {
            errors.user_name = "Username is required";
        }

        // Validation for user_mobile
        // if (!registerData.user_mobile || isNaN(registerData.user_mobile) || registerData.user_mobile.toString().length !== 10) {
        //     errors.user_mobile = "Please enter a valid 10-digit mobile number";
        // }

        // Validation for sponsored_user_mobile
        // if(registerData.sponsored_user_mobile){ 
        //     if (registerData.sponsored_user_mobile.toString().length !== 10) {
        //         errors.sponsored_user_mobile = "Please enter a valid 10-digit mobile number";
        //     }
        // }

        // Validation for user_email
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (!registerData.user_email) {
        //     errors.user_email = "Please enter a email address";
        // } else if (!emailRegex.test(registerData.user_email)) {
        //     errors.user_email = "Please enter a valid email address";
        // }


        // Validation for user_pancard
        // if (!registerData.user_pancard.trim() || !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(registerData.user_pancard.trim())) {
        //     errors.user_pancard = "Please enter a valid PAN card number";
        // }

        // Validation for user_pass
        if (!registerData.user_pass.trim()) {
            errors.user_pass = "Password is required";
        }

        const minLength = 8;
        if (registerData.user_pass.length < minLength) {
          errors.user_pass = 'Password must be at least 8 characters long.';
        }
        
        const hasUpperCase = /[A-Z]/.test(registerData.user_pass);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(registerData.user_pass);
        const hasNumeric = /\d/.test(registerData.user_pass);
    
        
        if (!hasUpperCase) {
          errors.user_pass = 'Password must contain at least one uppercase letter.';
        }
        if (!hasSpecialChar) {
          errors.user_pass = 'Password must contain at least one special character.';
        }
        if (!hasNumeric) {
          errors.user_pass = 'Password must contain at least one numeric digit.';
        }

        // Validation for confirm_user_pass
        if (registerData.user_pass !== registerData.confirm_user_pass) {
            errors.confirm_user_pass = "Passwords do not match";
        }

        setErrorRegisterDetail(errors);
        const isValid = Object.keys(errors).length === 0;
        return isValid;
    }

   

    const userRegister = async () => {


        const isValid = await validateInput();
        if (!isValid) {
            return
        }

        if (!isCheckboxChecked) {
            setErrorCheckboxMessage('Please agree to the terms and privacy.');
            return;
        }
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };
            

            setLoader(true)
            const response = await apiService.post(`/api/admin/User_Detail/InsertRegistration`, registerData, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            setLoader(false)
            if (response.status === true) {
                // console.log("Register Succefully");                
                navigate("/otp",{state : { registerData : registerData, purpose:"Registration"}})
                //navigate("/otp",{state : { registerData : registerData}})
                setRegisterData(initial)
                return
            }
            else {
                setErrorApiError(response.message)
                // console.log(response.message);
                // console.log("Register Succefully");
                // setRegisterData(initial)
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Sign In</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">Sign In</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* log in section start */}
            <section className="log-in-section section-b-space">
                <div className="container-fluid-lg w-100">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div className="image-contain">
                                <img
                                    src="../assets/images/inner-page/sign-up.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">


                            <div className="log-in-box">
                                <div className="log-in-title">
                                    <h3>Welcome To SHIVKMS</h3>
                                    <h4>Create a new account</h4>
                                </div>

                                {errorApiError && <div className="text-danger mt-3" style={{fontSize:18, marginBottom:20}}>{errorApiError}</div>}
                                <div className="input-box">
                                    <form className="row g-4">
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Full Name"
                                                    name="user_name"
                                                    id="user_name"
                                                    value={registerData.user_name}
                                                    onChange={(e) => onChangeHandle(e)}
                                                />
                                                <label htmlFor="fullname">Full Name</label>
                                            </div>
                                            {errorRegisterDetail.user_name && <div className="text-danger">{errorRegisterDetail.user_name}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    max={10}
                                                    type="number"
                                                    className="form-control"
                                                    name="user_mobile"
                                                    id="user_mobile"
                                                    value={registerData.user_mobile}
                                                    onChange={(e) => onChangeHandle(e)}
                                                    placeholder="Mobile Number"
                                                />
                                                <label htmlFor="email">Mobile No.</label>
                                            </div>
                                            {errorRegisterDetail.user_mobile && <div className="text-danger">{errorRegisterDetail.user_mobile}</div>}

                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    max={10}
                                                    type="text"
                                                    className="form-control"
                                                    name="user_email"
                                                    id="user_email"
                                                    value={registerData.user_email}
                                                    onChange={(e) => onChangeHandle(e)}
                                                    placeholder="Email"
                                                />
                                                <label htmlFor="email">Email</label>
                                            </div>
                                            {errorRegisterDetail.user_email && <div className="text-danger">{errorRegisterDetail.user_email}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    max={10}
                                                    type="number"
                                                    className="form-control"
                                                    name="sponsored_user_mobile"
                                                    id="sponsored_user_mobile"
                                                    value={registerData.sponsored_user_mobile}
                                                    onChange={(e) => onChangeHandle(e)}
                                                    placeholder="Mobile Number"
                                                />
                                                <label htmlFor="sponsored_user_mobile">Sponsor's Mobile No. (optional)</label>
                                            </div>
                                            {errorRegisterDetail.sponsored_user_mobile && <div className="text-danger">{errorRegisterDetail.sponsored_user_mobile}</div>}

                                        </div>

                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"

                                                    name="user_pass"
                                                    id="user_pass"
                                                    value={registerData.user_pass}
                                                    onChange={(e) => onChangeHandle(e)}
                                                />
                                                <label htmlFor="password">Password</label>
                                            </div>
                                            {errorRegisterDetail.user_pass && <div className="text-danger">{errorRegisterDetail.user_pass}</div>}

                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    name="confirm_user_pass"
                                                    id="confirm_user_pass"
                                                    value={registerData.confirm_user_pass}
                                                    onChange={(e) => onChangeHandle(e)}
                                                />
                                                <label htmlFor="password">Confirm Password</label>
                                            </div>
                                            {errorRegisterDetail.confirm_user_pass && <div className="text-danger">{errorRegisterDetail.confirm_user_pass}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="forgot-box">
                                                <div className="form-check ps-0 m-0 remember-box">
                                                    <input
                                                        className="checkbox_animated check-box"
                                                        type="checkbox"
                                                        id="flexCheckDefault"
                                                        checked={isCheckboxChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        I agree with
                                                        <span>Terms</span> and <span>Privacy</span>
                                                    </label><br/>
                                                    {errorCheckboxMessage && 
                                                    <>
                                                    <div className="text-danger">{errorCheckboxMessage}</div>
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                         
                                           <button className="btn btn-animation w-100" type="button" onClick={() => { userRegister() }}>
                                               {loader ? "Loading ..... ": "Sign Up"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="other-log-in">
                                    <h6>or</h6>
                                </div>
                                <div className="log-in-button">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://accounts.google.com/signin/v2/identifier?flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                                className="btn google-button w-100"
                                            >
                                                <img
                                                    src="../assets/images/inner-page/google.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />
                                                Sign up with Google
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/"
                                                className="btn google-button w-100"
                                            >
                                                <img
                                                    src="../assets/images/inner-page/facebook.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />{" "}
                                                Sign up with Facebook
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="other-log-in">
                                    <h6 />
                                </div> */}
                                <div className="sign-up-box">
                                    <h4>Already have an account?</h4>
                                    <Link to="/Login">Log In</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6 col-lg-6" />
                    </div>
                </div>
            </section>
            {/* log in section end */}
        </>

    )
}

export default Register;