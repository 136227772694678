

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';




const Privacy_policy = () => {

    const navigate = useNavigate();
    const [privacyPolicy , setPrivacyPolicy] = useState("")
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setPrivacyPolicy(location.state.privatePolicy)
        }
      }, [location.state]);
    
      
    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Privacy policy </h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index-2.html">
                                                <i className="fa-solid fa-house" />
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Privacy policy
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* 404 Section Start */}
            <section className="section-404 section-lg-space">
                <div className="container-fluid-lg">
                    <div className="row">

                        <div className="col-12">
                            <div>
                                <p>
                                    {/* **Privacy Policy** */}

                                    {privacyPolicy}
{/* 
                                    This Privacy Policy outlines how [Your Company Name] ("we," "us," or "our") collects, uses, discloses, and protects your personal information when you visit our website, purchase our beauty products, or interact with our services. By using our website, you consent to the terms outlined in this Privacy Policy.

                                    **1. Information We Collect:**

                                    1.1 **Personal Information:**
                                    - When you make a purchase, we collect personal information such as your name, shipping address, billing address, email address, and phone number.

                                    1.2 **Payment Information:**
                                    - We do not store credit card information. All payment transactions are securely processed through third-party payment processors adhering to industry standards.

                                    1.3 **User Account Information:**
                                    - If you create a user account on our website, we collect information such as your username and password.

                                    1.4 **Communication Data:**
                                    - We may collect data when you contact us or subscribe to our newsletter, including email communication and preferences.

                                    1.5 **Website Usage Information:**
                                    - We use cookies and similar technologies to collect information about your browsing behavior, preferences, and device information to enhance your experience on our website.

                                    **2. How We Use Your Information:**

                                    2.1 **Order Processing:**
                                    - We use your personal information to process and fulfill your orders, including shipping and communication related to your purchase.

                                    2.2 **Customer Service:**
                                    - Your information helps us respond to your customer service requests more effectively.

                                    2.3 **Marketing and Communication:**
                                    - With your consent, we may send you promotional emails, newsletters, or updates about our products and services.

                                    2.4 **Website Improvement:**
                                    - We analyze website usage data to enhance our website's functionality, user experience, and to address any technical issues.

                                    **3. Information Sharing and Disclosure:**

                                    3.1 **Third-Party Service Providers:**
                                    - We may share your information with third-party service providers who assist us in operating our website, conducting business, or servicing you. These service providers are obligated to maintain the confidentiality and security of your information.

                                    3.2 **Legal Compliance:**
                                    - We may disclose your information to comply with legal obligations, enforce our site policies, or protect our or others' rights, property, or safety.

                                    **4. Security:**

                                    We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure. We strive to use commercially acceptable means to protect your personal information, but we cannot guarantee absolute security.

                                    **5. Your Choices:**

                                    You have the right to opt-out of receiving marketing communications and can manage your communication preferences through your account settings or by contacting us.

                                    **6. Changes to Privacy Policy:**

                                    We reserve the right to update or modify this Privacy Policy at any time without prior notice. Changes will be effective immediately upon posting on the website.

                                    **7. Contact Information:**

                                    If you have any questions, concerns, or requests regarding your personal information, please contact us at [Contact Information].

                                    Effective Date: [Date]

                                    By using our website, you acknowledge that you have read and understood this Privacy Policy. */}
                                </p>
                                <button
                                    onClick={() => navigate("/")}
                                    className="btn btn-md text-white theme-bg-color mt-4 mx-auto"
                                >
                                    Back To Home Screen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 404 Section End */}
        </>
    )
}

export default Privacy_policy;