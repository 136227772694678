import { redirect, useNavigate } from "react-router-dom";
import { apiService } from "../../constants/ApiService";
import { useEffect, useState } from "react";

const PointsData = () => {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("");
    const [sponsormobile, setSponsorMobile] = useState("");
    const [orderMobile, setOrderMobile] = useState("");
    const [coinsEarned, setCoinsEarned] = useState(0);
    const [searchKeyData, setSearchKeyData] = useState("");


    const [level, setLevel] = useState(1);
    const [levelData, setLevelData] = useState([]);

    const onHandleSearchKeyItem = () => {
        getOrderDetail();
    }
    useEffect(() => {
        getOrderDetail()
    }, []);

    const getOrderDetail = async () => {

        try {

            const response = await apiService.get(`/api/TreeTesting/GetLvWisePointsData?LevelName=${level}`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setLevelData(response.lvDataList)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const addUser = async () => {

        try {

            const response = await apiService.post(`/api/admin/User_Detail/AddTestUser?mobile=${mobile}&sponsor_mobile=${sponsormobile}`);

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            alert(response.message);
            getOrderDetail();

        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    const addOrder = async () => {

        try {

            const response = await apiService.post(`/api/admin/User_Detail/AddCompletedOrder?userMob=${orderMobile}&coins=${coinsEarned}`);

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            alert(response.message);
            getOrderDetail();

        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    const createLv1 = async () => {

        try {

            const response = await apiService.post(`/api/TreeTesting/Create_L1_Data`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            alert(response);
            getOrderDetail();

        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const createLv2 = async () => {

        try {

            const response = await apiService.post(`/api/TreeTesting/Create_L2_Data`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            alert(response);
            getOrderDetail();

        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const DeleteLv1 = async () => {

        try {

            const response = await apiService.delete(`/api/TreeTesting/Delete_L1_Data`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            alert(response.message);
            getOrderDetail();
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const DeleteLv2 = async () => {

        try {

            const response = await apiService.delete(`/api/TreeTesting/Delete_Level_Data?level=${level}`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            alert(response.message);
            getOrderDetail();

        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const closeId = async (user_code) => {

        try {

            const response = await apiService.put(`/api/TreeTesting/CloseIdStatus?userCode=${user_code}&level=${level}`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            console.log(response)

            if (response.status === true) {
                //alert(response.message);

                getOrderDetail();

            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const openId = async (user_code) => {

        try {

            const response = await apiService.put(`/api/TreeTesting/OpenIdStatus?userCode=${user_code}&level=${level}`)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                //alert(response.message);
                getOrderDetail();
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const treeId = (user_code) => {
        navigate("/tree", { state: { user_code: user_code } })
    }

    return (
        <>

            <section className="order-table-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12 mt-3 mb-3">
                            <div className="search-box">
                                <div className="input-group">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Enter mobile no."
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setMobile(e.target.value) }}
                                    />
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Enter Sponsor mobile no."
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setSponsorMobile(e.target.value) }}
                                    />
                                    <button className="btn btn-animation proceed-btn fw-bold" type="button" id="button-addon2" onClick={() => { addUser() }}>
                                        Create User
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-3">
                            <div className="search-box">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter mobile no."
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setOrderMobile(e.target.value) }}
                                    />
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Coins earned"
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setCoinsEarned(e.target.value) }}
                                    />

                                    <button className="btn btn-animation  proceed-btn fw-bold" type="button" id="button-addon2" onClick={() => { addOrder() }}>
                                        Create Order
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className="col-sm-12 border">

                            <h2 className="text-center mt-3 mb-3">Level-Wise Data</h2>
                            <div className="row">
                                <div className="col-sm-3 mt-3 mb-3">
                                    <button type="button" className="btn btn-sm bg-success text-white" onClick={() => { createLv1() }}>Create Level 1</button>
                                </div>
                                <div className="col-sm-3 mt-3 mb-3">
                                    <button type="button" className="btn btn-sm bg-danger text-white" onClick={() => { DeleteLv1() }}>Delete Level 1</button>
                                </div>
                                <div className="col-sm-3 mt-3 mb-3">
                                    <button type="button" className="btn btn-sm bg-info text-white" onClick={() => { createLv2() }}>Create All Levels</button>
                                </div>
                            </div>
                            <div className="search-box mb-2">
                                <div className="input-group">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search User Code ... (Live Search)"
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setSearchKeyData(e.target.value) }}
                                    />
                                    <select className="form-select" onChange={(e) => { setLevel(e.target.value) }}>
                                        <option value={1}>Level-1</option>
                                        <option value={2}>Level-2</option>
                                        <option value={3}>Level-3</option>
                                        <option value={4}>Level-4</option>
                                        <option value={5}>Level-5</option>
                                    </select>
                                    <button className="btn btn-animation proceed-btn fw-bold" style={{ background: "green" }} type="button" id="button-addon2" onClick={() => { onHandleSearchKeyItem() }}>
                                        Fetch
                                    </button>
                                    <button className="btn btn-animation proceed-btn fw-bold" type="button" id="button-addon2" onClick={() => { DeleteLv2() }}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table order-tab-table" style={{ overflowX: "scroll", height: "30vh" }}>
                                    <thead>
                                        <tr>
                                            <th>Action</th>

                                            <th>User Code</th>
                                            <th>Left/Right</th>
                                            {/* <th>Right count</th> */}
                                            <th>Mobile</th>
                                            <th>Left side User</th>
                                            <th>Right side User</th>
                                            <th>Under User Code</th>
                                            <th>Sponsor User Code</th>

                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {levelData.filter((row) =>{
                                            if(searchKeyData!="")
                                            {
                                                const data = row.user_code.toLowerCase().includes(searchKeyData.toLowerCase());  
                                                return data; 
                                            }
                                            return row;
                                        }
                                    ).map((subitem, index) => (
                                            <>
                                                <tr>
                                                    <td>{subitem.status == "open" ?
                                                        <>
                                                            <button type="button" className="btn btn-sm bg-danger text-white" onClick={() => { closeId(subitem.user_code) }} >Close the Status</button>
                                                        </>
                                                        :
                                                        <>
                                                            <button type="button" className="btn btn-sm bg-warning text-white" onClick={() => { openId(subitem.user_code) }} >Convert to Open</button>
                                                        </>
                                                    }
                                                        <button type="button" className="btn btn-sm bg-success text-white mt-2" onClick={() => { treeId(subitem.user_code) }} >Show User Tree</button>

                                                    </td>
                                                    <td>{subitem.user_code}</td>
                                                    <td>{subitem.left_count}/{subitem.right_count}</td>

                                                    <td>{subitem.user_mobile}</td>
                                                    <td>{subitem.left_side_user_code}</td>
                                                    <td>{subitem.right_side_user_code}</td>
                                                    <td>{subitem.under_user_code}</td>
                                                    <td>{subitem.sponsored_user_code}</td>
                                                    {/* <td>{subitem.left_count}</td>
                                                    <td>{subitem.right_count}</td> */}
                                                    <td>{subitem.status}</td>


                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default PointsData;