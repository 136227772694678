import React, { useEffect, useState } from 'react';
import { apiService } from '../../constants/ApiService';
import { useLocation } from 'react-router-dom';

function TreeComponent() {
    const location = useLocation();
    const [treeData, setTreeData] = useState({});
    const [ searchKeyData , setSearchKeyData] = useState("");
    const [ levelData , setLevelData] = useState("1");

    const onHandleSearchKeyItem = () => {
        getTreeData(searchKeyData);
    }

    const getTreeData = async (user_code) => {
        try {
            //console.log(searchKeyData,"data")
            const response = await apiService.get(`/api/TreeTesting/GetTree?LevelName=${levelData}&userCode=${user_code}`)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            // Convert to array of [key, value] pairs
            // const entriesArray = [];
            // entriesArray.push(response)
            // console.log(entriesArray)

            setTreeData(response);

        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    }


    useEffect(() => {
        if (location.state) {
        //console.log(location.state.user_code,"state");
        setSearchKeyData(location.state.user_code)
        getTreeData(location.state.user_code);

        }
    }, []);

    return (
        <div className="container">
             <div className="search-box">
                                            <div className="input-group">
                                                {/* <input
                                                    type="search"
                                                    className="form-control"
                                                    placeholder="Enter User Code"
                                                    aria-describedby="button-addon2"
                                                    onChange={(e) => { setSearchKeyData(e.target.value) }}
                                                    /> */}
                                                <select className="form-select" onChange={(e) => { setLevelData(e.target.value) }}>
                                                    <option value="1">Level-1</option>
                                                    <option value="2">Level-2</option>
                                                </select>
                                                <button className="btn btn-animation proceed-btn fw-bold" type="button" id="button-addon2" onClick={()=>{onHandleSearchKeyItem()}}>
                                                    Get User Tree 
                                                </button>
                                            </div>
            </div>
                                    
            <table class="table " style={{borderBottomWidth:"0px"}}>
  
  <tbody>
    <tr className='text-center'>
      <td colSpan={16} scope="row">{treeData.s1_col_1}</td>
    </tr>
    <tr className='text-center'>
      <td className='text-center bg-warning' width="50%" colSpan={8}>{treeData.s2_col_1}</td>
      <td className='text-center' width="50%" colSpan={8}>{treeData.s2_col_2}</td>
    </tr>

    <tr>
      <td className='text-center bg-warning' width="25%" colSpan={4}>{treeData.s3_col_1}</td>
      <td className='text-center bg-warning' width="25%" colSpan={4}>{treeData.s3_col_2}</td>      
      <td className='text-center' width="25%" colSpan={4}>{treeData.s3_col_3}</td>
      <td className='text-center' width="25%" colSpan={4}>{treeData.s3_col_4}</td>
    </tr>

    <tr>
      <td className='text-center bg-warning' width="12.5%" colSpan={2}>{treeData.s4_col_1}</td>
      <td className='text-center bg-warning' width="12.5%" colSpan={2}>{treeData.s4_col_2}</td>      
      <td className='text-center bg-warning' width="12.5%" colSpan={2}>{treeData.s4_col_3}</td>
      <td className='text-center bg-warning' width="12.5%" colSpan={2}>{treeData.s4_col_4}</td>
      <td className='text-center' width="12.5%" colSpan={2}>{treeData.s4_col_5}</td>
      <td className='text-center' width="12.5%" colSpan={2}>{treeData.s4_col_6}</td>      
      <td className='text-center' width="12.5%" colSpan={2}>{treeData.s4_col_7}</td>
      <td className='text-center' width="12.5%" colSpan={2}>{treeData.s4_col_8}</td>
    </tr>

    <tr>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_1}</td>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_2}</td>      
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_3}</td>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_4}</td>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_5}</td>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_6}</td>      
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_7}</td>
      <td className='text-center bg-warning' width="6.25%" colSpan={1}>{treeData.s5_col_8}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_9}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_10}</td>      
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_11}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_12}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_13}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_14}</td>      
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_15}</td>
      <td className='text-center' width="6.25%" colSpan={1}>{treeData.s5_col_16}</td>
    </tr>

  </tbody>
</table>
            {/* <div className="row">
        
                <div className="tree">
                    <ul>
                        <li>
                            <a href="#">{treeData.s1_col_1}</a>
                            <ul>
                                <li>
                                    <a href="#">{treeData.s2_col_1}</a>
                                    <ul>
                                        <li>
                                            <a href="#">{treeData.s3_col_1}</a>
                                            <ul>
                                                <li>
                                                    <a href="#">{treeData.s4_col_1}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_1}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_2}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">{treeData.s4_col_2}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_3}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_4}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">{treeData.s3_col_2}</a>
                                            <ul>
                                                <li>
                                                    <a href="#">{treeData.s4_col_3}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_5}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_6}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">{treeData.s4_col_4}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_7}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_8}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">{treeData.s2_col_2}</a>
                                    <ul>
                                        <li>
                                            <a href="#">{treeData.s3_col_3}</a>
                                            <ul>
                                                <li>
                                                    <a href="#">{treeData.s4_col_5}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_9}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_10}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">{treeData.s4_col_6}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_11}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_12}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">{treeData.s3_col_4}</a>
                                            <ul>
                                                <li>
                                                    <a href="#">{treeData.s4_col_7}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_13}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_14}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">{treeData.s4_col_8}</a>
                                                    <ul>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_15}</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">{treeData.s5_col_16}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div> */}
        
        </div>

    );

}

export default TreeComponent;
