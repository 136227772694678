import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiService } from '../../constants/ApiService';
import { useLocation, useNavigate } from 'react-router-dom';
import AddressModal from './AddressModal';
import TestimoinalModal from './TestimoinalModal';
import InfoModal from './InfoModal';
import GiftInfoModal from './GiftInfoModal';
import RewardCardStatsModal from './RewardCardStatsModal';



const OrderStatus = {
    0: "Pending",
    1: "Processing",
    2: "Shipping",
    3: "Delivered",
    4: "Cancelled"
};

const OrderStatusColors = {
    0: '#3498db',  // Blue for Pending
    1: '#f39c12',  // Orange for Processing
    2: '#2ecc71',  // Green for Shipping
    3: '#9b59b6',  // Purple for Delivered
    4: '#e74c3c'   // Red for Cancelled
};



const UserDashboard = (props) => {

    const location = useLocation();
    const authData = useSelector(state => state.authState);
    const orderOtpServiceActive = useSelector(state => state.companyState);

    const [show, setShow] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [orderPendingList, setOrderPendingList] = useState([]);
    const [userDetail, setUserDetail] = useState({});
    const [editMode, setEditMode] = useState(false);
    const handleEditModeToggle = () => {
        setEditMode(!editMode);
    };
    const navigate = useNavigate();
    const [userMultipleAddress, setUserMultipleAddress] = useState([])
    const [userMultipleTestimonial, setUserMultipleTestimonial] = useState([])
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const initialNewAddress = {
        id: 0,
        userId: authData.user_id,
        m_form_name: '',
        m_address: '',
        m_city: '',
        m_pin_code: '',
        m_country: '',
        m_address_type: ''
    }
    const initialNewTestimonial = {
        id: 0,
        m_form_name: '',
        userId: authData.user_id,
        client_feedback: ''
    }
    const [newAddressData, setNewAddressData] = useState(initialNewAddress);
    const [newTestimonialData, setNewTestimonialData] = useState(initialNewTestimonial);
    const [editAddressMode, setEditAddressMode] = useState(false)

    const [isAddingTestimonial, setIsAddingTestimonial] = useState(false)
    const [editTestimonialMode, setEditTestimonialMode] = useState(false)

    const [active, setActive] = useState(false)

    const [clientTable, setClientTable] = useState([]);

    const [isViewInfo, setIsViewInfo] = useState(false);
    const [isViewGift, setIsViewGift] = useState(false);
    const [isViewRewardCardStat, setIsViewRewardCardStat] = useState(false);   


    const showMenuFx = () => {
        if (show === true) {
            setShow(false);
        } else {
            setShow(true);
        }
    }


    const getClientData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/ClientMaster/allclient?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                console.log(response)
                setClientTable(response.clientMasterData)
                // setTotalPage(response.totalPages)
                // alert(response.message);
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };



    const onChangeHandle = (e) => {
        //console.log("run the function for chnag the valeie")
        const { name, value, type } = e.target;

        setUserDetail((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const getOrderList = async () => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/OrderMaster/orderDetailByUserId?userId=${authData.user_id}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setOrderList(response.orderMasterData)
                setOrderPendingList(response.orderMasterPendingData)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const getUserDetail = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/admin/User_Detail/GetUserById?Id=${authData.user_id}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                setUserDetail(response.user_Detail)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    const [erroruserDetail, setErroruserDetail] = useState({})

    const validationUserDetail = () => {

        const errors = {};

        if (!userDetail.user_name) {
            errors.user_name = "Name is required";
        }

        if (!userDetail.user_mobile) {
            errors.user_mobile = "Mobile is required";
        } else if (!/^\d{10}$/.test(userDetail.user_mobile)) {
            errors.user_mobile = "Mobile number must be 10 digits";
        }

        setErroruserDetail(errors);

        // Return true if there are no errors
        return Object.keys(errors).length === 0;
    }

    const UpdateUserDetail = async () => {

        let validation = await validationUserDetail()
        if (!validation) {
            return
        }

        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.post(`/api/admin/User_Detail/Edit_User`, userDetail, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                // setUserDetail(response.user_Detail)
                setEditMode(false);
                // setEditMode(false)
            }
            else {
                setEditMode(false);
                console.log("update successfully");
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    }

    const getUserMultipleAddress = async () => {
        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'User_id': newAddressData.userId
                }
            };

            const response = await apiService.get(`/api/admin/User_Detail/GetUserMultiAddress`, config)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                // console.log(response.multiUserAddData)

                setUserMultipleAddress(response.multiUserAddData)
            }
            else {
                // console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    }

    const getUserMultipleTestimonial = async () => {
        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'User_id': newTestimonialData.userId
                }
            };

            const response = await apiService.get(`/api/ClientMaster/userWiseTestimonials`, config)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                // console.log(response.multiUserAddData)

                setUserMultipleTestimonial(response.clientMasterData)
            }
            else {
                // console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    }

    useEffect(() => {

        if (authData.login) {
            getOrderList()
            getUserDetail()
            getUserMultipleAddress()
            getUserMultipleTestimonial()
        }

    }, [])


    const handleNavigration = (orderId) => {
        setShow(false);
        navigate("/orderTracking", { state: { orderId: orderId } });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAddressData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setNewTestimonialData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const [errorAddressData, setErrorAddressData] = useState({});
    const [errorTestimonialData, setErrorTestimonialData] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!newAddressData.m_form_name) {
            errors.m_form_name = "Name is required";
        }

        if (!newAddressData.m_address) {
            errors.m_address = "Address is required";
        }

        if (!newAddressData.m_city) {
            errors.m_city = "City is required";
        }

        if (!newAddressData.m_pin_code) {
            errors.m_pin_code = "Pin code is required";
        }

        if (!newAddressData.m_country) {
            errors.m_country = "Country is required";
        }

        if (!newAddressData.m_address_type) {
            errors.m_address_type = "Address type is required";
        }

        setErrorAddressData(errors)
        return Object.keys(errors).length === 0;
    };


    const handleAddAddress = async () => {

        let validate = await validateForm()
        if (!validate) {
            return
        }

        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };
            const response = await apiService.post(`/api/admin/User_Detail/AddMultiAddress`, newAddressData, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                console.log("Add New Address");
                setNewAddressData(initialNewAddress)
                getUserMultipleAddress()
                setEditAddressMode(false)
            }
            else {
                console.log(response);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    };

    const handleAddTestimonial = async () => {

        // let validate = await validateForm()
        // if (!validate) {
        //     return
        // }
        console.log(newTestimonialData)

        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };
            const response = await apiService.post(`/api/ClientMaster/create`, newTestimonialData, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                console.log("Add New Testimonial");
                setNewTestimonialData(initialNewTestimonial)
                getUserMultipleTestimonial()
                setEditTestimonialMode(false)
            }
            else {
                console.log(response);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    };

    const handleUpdateAddAddress = async () => {


        let validate = await validateForm()
        if (!validate) {
            return
        }
        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.put(`/api/admin/User_Detail/UpdateUserMultiAdd?id=${newAddressData.id}`, newAddressData, config)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                console.log(response.message);
                setNewAddressData(initialNewAddress)
                setEditAddressMode(false)
                getUserMultipleAddress()
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    };

    const handleUpdateAddTestimonial = async () => {


        // let validate = await validateForm()
        // if (!validate) {
        //     return
        // }
        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.put(`/api/ClientMaster/update?clientId=${newTestimonialData.id}`, newTestimonialData, config)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                console.log(response.message);
                setNewTestimonialData(initialNewTestimonial)
                setEditTestimonialMode(false)
                getUserMultipleTestimonial()
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            console.log('SomeThing went wrong!!');
        }
    };

    const onHanldeNewTestimonial = () => {
        setEditTestimonialMode(true)
        setIsAddingTestimonial(true)
    }
 
    const onHanldeRewardCardStats = () => {
        setIsViewRewardCardStat(true);
        //document.body.classList.add("modal-open-blur");
    }
    const onhandleRewardCardStatsClose = () => {
        setIsViewRewardCardStat(false);
        //document.body.classList.remove("modal-open-blur");
    }

    const onHanldeLevelInfo = () => {
        setIsViewInfo(true);
        //document.body.classList.add("modal-open-blur");
    }

    const onHanldeGiftInfo = () => {
        setIsViewGift(true);
        //document.body.classList.add("modal-open-blur");
    }

    const handleGiftClose = () => {
        setIsViewGift(false);
        //document.body.classList.remove("modal-open-blur");
    }

    const handleInfoClose = () => {
        setIsViewInfo(false);
        //document.body.classList.remove("modal-open-blur");
    }

    const onHanldeNewAddress = () => {
        setEditAddressMode(true)
        setIsAddingAddress(true)
    }

    const onHandleUpdateAddress = (id) => {
        let findArray = userMultipleAddress.find((data) => data.id == id)
        setNewAddressData(findArray)
        setEditAddressMode(true)
        setIsAddingAddress(false)
    }

    const onHandleUpdateTestimonial = (id) => {
        let findArray = userMultipleTestimonial.find((data) => data.id == id)
        console.log(findArray)
        setNewTestimonialData(findArray);
        setEditTestimonialMode(true)
        setIsAddingTestimonial(false)
    }

    const handleEditConfirm = () => {
        setEditAddressMode(false);
        setNewAddressData(initialNewAddress)
        setErrorAddressData({})

        setEditTestimonialMode(false);
        setNewTestimonialData(initialNewTestimonial)
        setErrorTestimonialData({})
    };

    const onHandleDeleteAddress = async (id) => {

        const isDeleteConfirmed = window.confirm('Are you sure you want to delete this address?');
        if (isDeleteConfirmed) {
            try {
                let config = {
                    headers: {
                        'Content-Type': 'application/json', // Important for file upload//s
                        'accept': 'application/json'
                    }
                };

                const response = await apiService.delete(`/api/admin/User_Detail/DeletUserMulAdd?id=${id}`, config)
                if (response.response && response.response.data.errors) {
                    console.log(response.response?.data?.errors)
                }

                if (response.status === true) {
                    console.log(response.message);
                    getUserMultipleAddress()
                }
                else {
                    alert(response.message);
                }
            } catch (error) {
                console.log(error)
                alert('SomeThing went wrong!!');
            }
        } else {
            console.log('Deletion canceled.');
        }
    };

    const onHandleDeleteTestimonial = async (id) => {

        const isDeleteConfirmed = window.confirm('Are you sure you want to delete this Testimonial?');
        if (isDeleteConfirmed) {
            try {
                let config = {
                    headers: {
                        'Content-Type': 'application/json', // Important for file upload//s
                        'accept': 'application/json'
                    }
                };

                const response = await apiService.delete(`/api/ClientMaster/delete?clientId=${id}`, config)
                if (response.response && response.response.data.errors) {
                    console.log(response.response?.data?.errors)
                }

                if (response.status === true) {
                    console.log(response.message);
                    getUserMultipleTestimonial()
                }
                else {
                    alert(response.message);
                }
            } catch (error) {
                console.log(error)
                alert('SomeThing went wrong!!');
            }
        } else {
            console.log('Deletion canceled.');
        }
    };

    const onHandleCancelOrder = async (orderId) => {

        if (orderOtpServiceActive.company_order_otp_active) {
            setShow(false);
            navigate("/verifyCancellation", { state: { orderId: orderId, user_email: userDetail.user_email } })
        } else {
            setShow(false);
            navigate("/ordercancel", { state: { orderId: orderId } });
        }
    }

    useEffect(() => {
        const option = new URLSearchParams(location.search).get('option');
        if (option) {
            setActive(option)
        }
        // Cleanup effect if the component is unmounted while modal is open
        // return () => {
        //     document.body.classList.remove("modal-open-blur");
        //   };
    })

    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>User Dashboard</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="index-2.html">
                                                <i className="fa-solid fa-house" />
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            User Dashboard
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* User Dashboard Section Start */}

            <section className="user-dashboard-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-xxl-3 col-lg-4">

                            <div className={show ? "dashboard-left-sidebar show" : "dashboard-left-sidebar"}>
                                <div className="close-button d-flex d-lg-none">
                                    <button onClick={showMenuFx} className="close-sidebar">
                                        <i className="fa-solid fa-xmark" />
                                    </button>
                                </div>
                                <div className="profile-box">
                                    <div className="cover-image">
                                        <img
                                            src="../assets/images/inner-page/cover-img.jpg"
                                            className="img-fluid  lazyload"
                                            alt=""
                                        />
                                    </div>
                                    <div className="profile-contain">
                                        <div className="profile-image">
                                            <div className="position-relative">
                                                <img
                                                    src="../assets/images/inner-page/user/user.jpg"
                                                    className=" lazyload update_img"
                                                    alt=""
                                                />
                                                <div className="cover-icon">
                                                    <i className="fa-solid fa-pen">
                                                        <input type="file" onchange="readURL(this,0)" />
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile-name">
                                            <h3>{userDetail.user_name}</h3>
                                            <h6 className="text-content">{userDetail.user_email}</h6>
                                        </div>
                                    </div>
                                </div>
                                <ul
                                    className="nav nav-pills user-nav-pills"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            // className="nav-link active"
                                            className={!active ? "nav-link active" : "nav-link"}
                                            id="pills-dashboard-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-dashboard"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-dashboard"
                                            aria-selected="true"
                                        >
                                            <i data-feather="home" />
                                            Dashboard
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={showMenuFx}
                                            className="nav-link"
                                            id="pills-order-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-order"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-order"
                                            aria-selected="false"
                                        >
                                            <i data-feather="shopping-bag" />
                                            Orders
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-cashback-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-cashback"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-cashback"
                                            aria-selected="false"
                                        >
                                            <i data-feather="heart" />
                                            Gifts
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-security-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-security"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-security"
                                            aria-selected="false"
                                        >
                                            <i data-feather="shield" />
                                            Levels
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-card-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-card"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-card"
                                            aria-selected="false"
                                        >
                                            <i data-feather="credit-card" /> Rewards
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={showMenuFx}
                                            className={active ? "nav-link active" : "nav-link"}
                                            // className="nav-link"
                                            id="pills-address-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-address"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-address"
                                            aria-selected="false"
                                        >
                                            <i data-feather="map-pin" />
                                            Address
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={showMenuFx}
                                            //className={active ? "nav-link active" : "nav-link"}
                                            className="nav-link"
                                            id="pills-testimonial-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-testimonial"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-testimonial"
                                            aria-selected="false"
                                        >
                                            <i data-feather="map-pin" />
                                            Testimonial
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            onClick={showMenuFx}
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                        >
                                            <i data-feather="user" />
                                            Profile
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-xxl-9 col-lg-8">
                            <button onClick={showMenuFx} className="btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none">
                                Show Menu
                            </button>
                            <div className="dashboard-right-sidebar">
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        // className="tab-pane fade show active"

                                        className={!active ? "tab-pane fade show active" : "tab-pane fade show"}
                                        id="pills-dashboard"
                                        role="tabpanel"
                                        aria-labelledby="pills-dashboard-tab"
                                    >
                                        <div className="dashboard-home">
                                            <div className="title">
                                                <h2>My Dashboard</h2>
                                                <span className="title-leaf">
                                                    <svg className="icon-width bg-gray">
                                                        <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="dashboard-user-name">
                                                <h6 className="text-content">
                                                    Hello, <b className="text-title">{userDetail.user_name}</b>
                                                </h6>
                                                <p className="text-content">
                                                    From your My Account Dashboard you have the ability to
                                                    view a snapshot of your recent account activity and update
                                                    your account information. Select a link below to view or
                                                    edit information.
                                                </p>
                                            </div>
                                            <div className="total-box">
                                                <div className="row g-sm-4 g-3">
                                                    <div className="col-xxl-4 col-lg-6 col-md-4 col-sm-6">
                                                        <div className="totle-contain">
                                                            <img
                                                                src="../assets/images/svg/order.svg"
                                                                className="img-1  lazyload"
                                                                alt=""
                                                            />
                                                            <img
                                                                src="../assets/images/svg/order.svg"
                                                                className=" lazyload"
                                                                alt=""
                                                            />
                                                            <div className="totle-detail">
                                                                <h5>Total Order</h5>
                                                                <h3>{orderList.length ? orderList.length : 0}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-4 col-lg-6 col-md-4 col-sm-6">
                                                        <div className="totle-contain">
                                                            <img
                                                                src="../assets/images/svg/pending.svg"
                                                                className="img-1  lazyload"
                                                                alt=""
                                                            />
                                                            <img
                                                                src="../assets/images/svg/pending.svg"
                                                                className=" lazyload"
                                                                alt=""
                                                            />
                                                            <div className="totle-detail">
                                                                <h5>Total Pending Orders</h5>
                                                                <h3>{orderPendingList.length ? orderPendingList.length : 0}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-xxl-4 col-lg-6 col-md-4 col-sm-6">
                                                        <div className="totle-contain">
                                                            <img
                                                                src="../assets/images/svg/wishlist.svg"
                                                                className="img-1  lazyload"
                                                                alt=""
                                                            />
                                                            <img
                                                                src="../assets/images/svg/wishlist.svg"
                                                                className=" lazyload"
                                                                alt=""
                                                            />
                                                            <div className="totle-detail">
                                                                <h5>Total Wishlist</h5>
                                                                <h3>32158</h3>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <div className="dashboard-title">
                                                <h3>Account Information</h3>
                                            </div> */}
                                            {/* <div className="row g-4">
                                                <div className="col-xxl-6">
                                                    <div className="dashboard-contant-title">
                                                        <h4>
                                                            Contact Information{" "}
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editProfile"
                                                            >
                                                                Edit
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div className="dashboard-detail">
                                                        <h6 className="text-content">MARK JECNO</h6>
                                                        <h6 className="text-content">vicki.pope@gmail.com</h6>
                                                        <a href="javascript:void(0)">Change Password</a>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6">
                                                    <div className="dashboard-contant-title">
                                                        <h4>
                                                            Newsletters{" "}
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editProfile"
                                                            >
                                                                Edit
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div className="dashboard-detail">
                                                        <h6 className="text-content">
                                                            You are currently not subscribed to any newsletter
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="dashboard-contant-title">
                                                        <h4>
                                                            Address Book{" "}
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editProfile"
                                                            >
                                                                Edit
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-xxl-6">
                                                            <div className="dashboard-detail">
                                                                <h6 className="text-content">
                                                                    Default Billing Address
                                                                </h6>
                                                                <h6 className="text-content">
                                                                    You have not set a default billing address.
                                                                </h6>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editProfile"
                                                                >
                                                                    Edit Address
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="dashboard-detail">
                                                                <h6 className="text-content">
                                                                    Default Shipping Address
                                                                </h6>
                                                                <h6 className="text-content">
                                                                    You have not set a default shipping address.
                                                                </h6>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editProfile"
                                                                >
                                                                    Edit Address
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div
                                        className="tab-pane fade show"
                                        id="pills-wishlist"
                                        role="tabpanel"
                                        aria-labelledby="pills-wishlist-tab"
                                    >
                                        <div className="dashboard-wishlist">
                                            <div className="title">
                                                <h2>My Wishlist History</h2>
                                                <span className="title-leaf title-leaf-gray">
                                                    <svg className="icon-width bg-gray">
                                                        <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="row g-sm-4 g-3">
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/2.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Vegetable</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Fresh Bread and Pastry Flour 200 g
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Cheesy feet cheesy grin brie. Mascarpone cheese
                                                                    and wine hard cheese the big cheese everyone loves
                                                                    smelly cheese macaroni cheese croque monsieur.
                                                                </p>
                                                                <h6 className="unit mt-1">250 ml</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$08.02</span>
                                                                    <del>$15.15</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/3.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Vegetable</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Peanut Butter Bite Premium Butter Cookies 600 g
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Feta taleggio croque monsieur swiss manchego
                                                                    cheesecake dolcelatte jarlsberg. Hard cheese
                                                                    danish fontina boursin melted cheese fondue.
                                                                </p>
                                                                <h6 className="unit mt-1">350 G</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$04.33</span>
                                                                    <del>$10.36</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/4.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Snacks</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        SnackAmor Combo Pack of Jowar Stick and Jowar
                                                                        Chips
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Lancashire hard cheese parmesan. Danish fontina
                                                                    mozzarella cream cheese smelly cheese cheese and
                                                                    wine cheesecake dolcelatte stilton. Cream cheese
                                                                    parmesan who moved my cheese when the cheese comes
                                                                    out everybody's happy cream cheese red leicester
                                                                    ricotta edam.
                                                                </p>
                                                                <h6 className="unit mt-1">570 G</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$12.52</span>
                                                                    <del>$13.62</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/5.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Snacks</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Yumitos Chilli Sprinkled Potato Chips 100 g
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Cheddar cheddar pecorino hard cheese hard cheese
                                                                    cheese and biscuits bocconcini babybel. Cow goat
                                                                    paneer cream cheese fromage cottage cheese
                                                                    cauliflower cheese jarlsberg.
                                                                </p>
                                                                <h6 className="unit mt-1">100 G</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$10.25</span>
                                                                    <del>$12.36</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/6.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Vegetable</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Fantasy Crunchy Choco Chip Cookies
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Bavarian bergkase smelly cheese swiss cut the
                                                                    cheese lancashire who moved my cheese manchego
                                                                    melted cheese. Red leicester paneer cow when the
                                                                    cheese comes out everybody's happy croque monsieur
                                                                    goat melted cheese port-salut.
                                                                </p>
                                                                <h6 className="unit mt-1">550 G</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$14.25</span>
                                                                    <del>$16.57</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/7.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Vegetable</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Fresh Bread and Pastry Flour 200 g
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Melted cheese babybel chalk and cheese. Port-salut
                                                                    port-salut cream cheese when the cheese comes out
                                                                    everybody's happy cream cheese hard cheese cream
                                                                    cheese red leicester.
                                                                </p>
                                                                <h6 className="unit mt-1">1 Kg</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$12.68</span>
                                                                    <del>$14.69</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6">
                                                    <div className="product-box-3 theme-bg-white h-100">
                                                        <div className="product-header">
                                                            <div className="product-image">
                                                                <a href="product-left-thumbnail.html">
                                                                    <img
                                                                        src="../assets/images/cake/product/2.png"
                                                                        className="img-fluid  lazyload"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                                <div className="product-header-top">
                                                                    <button className="btn wishlist-button close_button">
                                                                        <i data-feather="x" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="product-footer">
                                                            <div className="product-detail">
                                                                <span className="span-name">Vegetable</span>
                                                                <a href="product-left-thumbnail.html">
                                                                    <h5 className="name">
                                                                        Fresh Bread and Pastry Flour 200 g
                                                                    </h5>
                                                                </a>
                                                                <p className="text-content mt-1 mb-2 product-content">
                                                                    Squirty cheese cottage cheese cheese strings. Red
                                                                    leicester paneer danish fontina queso lancashire
                                                                    when the cheese comes out everybody's happy
                                                                    cottage cheese paneer.
                                                                </p>
                                                                <h6 className="unit mt-1">250 ml</h6>
                                                                <h5 className="price">
                                                                    <span className="theme-color">$08.02</span>
                                                                    <del>$15.15</del>
                                                                </h5>
                                                                <div className="add-to-cart-box mt-2">
                                                                    <button
                                                                        className="btn btn-add-cart addcart-button"
                                                                        tabIndex={0}
                                                                    >
                                                                        Add
                                                                        <span className="add-icon">
                                                                            <i className="fa-solid fa-plus" />
                                                                        </span>
                                                                    </button>
                                                                    <div className="cart_qty qty-box">
                                                                        <div className="input-group">
                                                                            <button
                                                                                type="button"
                                                                                className="qty-left-minus"
                                                                                data-type="minus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-minus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                            <input
                                                                                className="form-control input-number qty-input"
                                                                                type="text"
                                                                                name="quantity"
                                                                                defaultValue={0}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="qty-right-plus"
                                                                                data-type="plus"
                                                                                data-field=""
                                                                            >
                                                                                <i
                                                                                    className="fa fa-plus"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div
                                        className="tab-pane fade show"
                                        id="pills-order"
                                        role="tabpanel"
                                        aria-labelledby="pills-order-tab"
                                    >
                                        <div className="dashboard-order">
                                            <div className="title">
                                                <h2>My Orders History</h2>
                                                <span className="title-leaf title-leaf-gray">
                                                    <svg className="icon-width bg-gray">
                                                        <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="order-contain">
                                                {/* <div className="order-box dashboard-bg-box">
                                                    <div className="order-container">
                                                        <div className="order-icon">
                                                            <i data-feather="box" />
                                                        </div>
                                                        <div className="order-detail">
                                                            <h4>
                                                                Delivere <span>Panding</span>
                                                            </h4>
                                                            <h6 className="text-content">
                                                                Gouda parmesan caerphilly mozzarella cottage cheese
                                                                cauliflower cheese taleggio gouda.
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="product-order-detail">
                                                        <a
                                                            href="product-left-thumbnail.html"
                                                            className="order-image"
                                                        >
                                                            <img
                                                                src="../assets/images/vegetable/product/SHIVKMS.png"
                                                                className=" lazyload"
                                                                alt=""
                                                            />
                                                        </a>
                                                        <div className="order-wrap">
                                                            <a href="product-left-thumbnail.html">
                                                                <h3>Fantasy Crunchy Choco Chip Cookies</h3>
                                                            </a>
                                                            <p className="text-content">
                                                                Cheddar dolcelatte gouda. Macaroni cheese cheese
                                                                strings feta halloumi cottage cheese jarlsberg
                                                                cheese triangles say cheese.
                                                            </p>
                                                            <ul className="product-size">
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Price : </h6>
                                                                        <h5>$20.68</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Rate : </h6>
                                                                        <div className="product-rating ms-2">
                                                                            <ul className="rating">
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Sold By : </h6>
                                                                        <h5>Fresho</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Quantity : </h6>
                                                                        <h5>250 G</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-box dashboard-bg-box">
                                                    <div className="order-container">
                                                        <div className="order-icon">
                                                            <i data-feather="box" />
                                                        </div>
                                                        <div className="order-detail">
                                                            <h4>
                                                                Delivered{" "}
                                                                <span className="success-bg">Success</span>
                                                            </h4>
                                                            <h6 className="text-content">
                                                                Cheese on toast cheesy grin cheesy grin cottage
                                                                cheese caerphilly everyone loves cottage cheese the
                                                                big cheese.
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="product-order-detail">
                                                        <a
                                                            href="product-left-thumbnail.html"
                                                            className="order-image"
                                                        >
                                                            <img
                                                                src="../assets/images/vegetable/product/2.png"
                                                                alt=""
                                                                className=" lazyload"
                                                            />
                                                        </a>
                                                        <div className="order-wrap">
                                                            <a href="product-left-thumbnail.html">
                                                                <h3>Cold Brew Coffee Instant Coffee 50 g</h3>
                                                            </a>
                                                            <p className="text-content">
                                                                Pecorino paneer port-salut when the cheese comes out
                                                                everybody's happy red leicester mascarpone blue
                                                                castello cauliflower cheese.
                                                            </p>
                                                            <ul className="product-size">
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Price : </h6>
                                                                        <h5>$20.68</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Rate : </h6>
                                                                        <div className="product-rating ms-2">
                                                                            <ul className="rating">
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Sold By : </h6>
                                                                        <h5>Fresho</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Quantity : </h6>
                                                                        <h5>250 G</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-box dashboard-bg-box">
                                                    <div className="order-container">
                                                        <div className="order-icon">
                                                            <i data-feather="box" />
                                                        </div>
                                                        <div className="order-detail">
                                                            <h4>
                                                                Delivere <span>Panding</span>
                                                            </h4>
                                                            <h6 className="text-content">
                                                                Cheesy grin boursin cheesy grin cheesecake blue
                                                                castello cream cheese lancashire melted cheese.
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="product-order-detail">
                                                        <a
                                                            href="product-left-thumbnail.html"
                                                            className="order-image"
                                                        >
                                                            <img
                                                                src="../assets/images/vegetable/product/3.png"
                                                                alt=""
                                                                className=" lazyload"
                                                            />
                                                        </a>
                                                        <div className="order-wrap">
                                                            <a href="product-left-thumbnail.html">
                                                                <h3>
                                                                    Peanut Butter Bite Premium Butter Cookies 600 g
                                                                </h3>
                                                            </a>
                                                            <p className="text-content">
                                                                Cow bavarian bergkase mascarpone paneer squirty
                                                                cheese fromage frais cheese slices when the cheese
                                                                comes out everybody's happy.
                                                            </p>
                                                            <ul className="product-size">
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Price : </h6>
                                                                        <h5>$20.68</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Rate : </h6>
                                                                        <div className="product-rating ms-2">
                                                                            <ul className="rating">
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Sold By : </h6>
                                                                        <h5>Fresho</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Quantity : </h6>
                                                                        <h5>250 G</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {
                                                    orderList.length != 0 ?
                                                        <>
                                                            {orderList.map((item, index) => (
                                                                <div className="order-box dashboard-bg-box" key={index}>
                                                                    <div className="order-container">
                                                                        <div className="order-icon">
                                                                            <i data-feather="box" />
                                                                        </div>
                                                                        {/* <div className="order-detail">
                                                                            <h4 style={{ color: OrderStatusColors[item.order_status] }}>
                                                                                {OrderStatus[item.order_status]}
                                                                            </h4>
                                                                        </div> */}
                                                                        <div className="order-detail">
                                                                            <h4 >
                                                                                <td>{item.created_on ? new Date(item.created_on).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ""}</td>
                                                                            </h4>
                                                                        </div>
                                                                    </div>

                                                                    {item.orderItemMasters.map((itemData, key) => (
                                                                        <>

                                                                            <div className="product-order-detail" key={key}>
                                                                                <a href="product-left-thumbnail.html" className="order-image">
                                                                                    <img src={process.env.REACT_APP_API_BASE_URL + itemData.img_url} style={{ height: "150px", width: "150px", objectFit: "cover" }} alt="" />
                                                                                </a>

                                                                                <div className="order-wrap">

                                                                                    <ul className="product-size">
                                                                                        <li>
                                                                                            {/* <div className="size-box"> */}
                                                                                            <h5 style={{ height: 32, fontSize: 25 }}>{itemData.product_title}</h5>
                                                                                            {/* </div> */}
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="size-box">
                                                                                                <h6 className="text-content">Price : </h6>
                                                                                                <h5>${itemData.product_price}</h5>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="size-box">
                                                                                                <h6 className="text-content">Rate : </h6>
                                                                                                <div className="product-rating ms-2">
                                                                                                    <ul className="rating">
                                                                                                        <li>
                                                                                                            <i data-feather="star" className="fill" />
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i data-feather="star" className="fill" />
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i data-feather="star" className="fill" />
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i data-feather="star" className="fill" />
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i data-feather="star" />
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>

                                                                                        {/* <li>
                                                                                                <div className="size-box">
                                                                                                    <h6 className="text-content">Sold By : </h6>
                                                                                                    <h5>Fresho</h5>
                                                                                                </div>
                                                                                            </li> 
                                                                                        */}
                                                                                        <li>
                                                                                            <div className="size-box">
                                                                                                <h6 className="text-content">Quantity : </h6>
                                                                                                <h5>{itemData.quantity}</h5>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    ))}

                                                                    {item.order_status == 4 ?
                                                                        <>
                                                                            <h2 style={{ marginTop: 10, color: "#e74c3c" }}>Cancelled</h2>
                                                                        </> : <button
                                                                            type='button'
                                                                            onClick={() => handleNavigration(item.id)}
                                                                            className="btn btn-md bg-warning cart-button text-white mt-3"
                                                                        >
                                                                            Track your order
                                                                        </button>}

                                                                    {item.order_status >= 3 ? <></> : <button
                                                                        type='button'
                                                                        onClick={() => onHandleCancelOrder(item.id)}
                                                                        className="btn btn-md bg-danger cart-button text-white mt-3"
                                                                    >
                                                                        Cancel
                                                                    </button>}

                                                                </div>
                                                            ))}
                                                        </>

                                                        :
                                                        <>
                                                            <h2> No Orders placed till now... </h2>
                                                            <br />

                                                            <button
                                                                onClick={() => { navigate("/") }}
                                                                className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                                                            >
                                                                <i className="fa-solid fa-arrow-left-long" />
                                                                Continue Shopping....
                                                            </button>

                                                        </>
                                                }


                                                {/* <div className="order-box dashboard-bg-box">
                                                    <div className="order-container">
                                                        <div className="order-icon">
                                                            <i data-feather="box" />
                                                        </div>
                                                        <div className="order-detail">
                                                            <h4>
                                                                Delivered{" "}
                                                                <span className="success-bg">Success</span>
                                                            </h4>
                                                            <h6 className="text-content">
                                                                Caerphilly port-salut parmesan pecorino croque
                                                                monsieur dolcelatte melted cheese cheese and wine.
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="product-order-detail">
                                                        <a
                                                            href="product-left-thumbnail.html"
                                                            className="order-image"
                                                        >
                                                            <img
                                                                src="../assets/images/vegetable/product/4.png"
                                                                className=" lazyload"
                                                                alt=""
                                                            />
                                                        </a>
                                                        <div className="order-wrap">
                                                            <a href="product-left-thumbnail.html">
                                                                <h3>
                                                                    SnackAmor Combo Pack of Jowar Stick and Jowar
                                                                    Chips
                                                                </h3>
                                                            </a>
                                                            <p className="text-content">
                                                                The big cheese cream cheese pepper jack cheese
                                                                slices danish fontina everyone loves cheese on toast
                                                                bavarian bergkase.
                                                            </p>
                                                            <ul className="product-size">
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Price : </h6>
                                                                        <h5>$20.68</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Rate : </h6>
                                                                        <div className="product-rating ms-2">
                                                                            <ul className="rating">
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" className="fill" />
                                                                                </li>
                                                                                <li>
                                                                                    <i data-feather="star" />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Sold By : </h6>
                                                                        <h5>Fresho</h5>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="size-box">
                                                                        <h6 className="text-content">Quantity : </h6>
                                                                        <h5>250 G</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Address */}
                                    <div
                                        // className="tab-pane fade show"
                                        className={active ? "tab-pane fade show active" : "tab-pane fade show"}

                                        id="pills-address"
                                        role="tabpanel"
                                        aria-labelledby="pills-address-tab"
                                    >
                                        <div className="dashboard-address">
                                            <div className="title title-flex">
                                                <div>
                                                    <h2>My Address Book</h2>
                                                    <span className="title-leaf">
                                                        <svg className="icon-width bg-gray">
                                                            <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
                                                    onClick={() => { onHanldeNewAddress() }}
                                                >
                                                    <i data-feather="plus" className="me-2" /> Add New Address
                                                </button>
                                            </div>

                                            <div className="row g-sm-4 g-3">

                                                {userMultipleAddress.length != 0 ?
                                                    <>
                                                        {userMultipleAddress.map((key, index) => (
                                                            <>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                                    <div className="address-box">
                                                                        <div>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="jack"
                                                                                    id={`flexRadioDefault${index}`}
                                                                                    defaultChecked=""
                                                                                />
                                                                            </div>
                                                                            <div className="label">
                                                                                <label>{key.m_address_type}</label>
                                                                            </div>

                                                                            <div className="table-responsive address-table">
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td colSpan={2}>
                                                                                                <input type='text'
                                                                                                    disabled={!editMode}
                                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                                    value={key.m_form_name}
                                                                                                    id={`m_form_name_${index}`}
                                                                                                    name={`m_form_name_${index}`}
                                                                                                    onChange={onChangeHandle}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Address :</td>
                                                                                            <td>
                                                                                                <input type='text'
                                                                                                    disabled={!editMode}
                                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                                    value={key.m_address}
                                                                                                    id={`m_address_${index}`}
                                                                                                    name={`m_address_${index}`}
                                                                                                    onChange={onChangeHandle}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Pin Code :</td>
                                                                                            <td>
                                                                                                <input type='text'
                                                                                                    disabled={!editMode}
                                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                                    value={key.m_pin_code}
                                                                                                    id={`m_pin_code_${index}`}
                                                                                                    name={`m_pin_code_${index}`}
                                                                                                    onChange={onChangeHandle}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>City:</td>
                                                                                            <td>
                                                                                                <input type='text'
                                                                                                    disabled={!editMode}
                                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                                    value={key.m_city}
                                                                                                    id={`m_city_${index}`}
                                                                                                    name={`m_city_${index}`}
                                                                                                    onChange={onChangeHandle}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Country :</td>
                                                                                            <td>
                                                                                                <input type='text'
                                                                                                    disabled={!editMode}
                                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                                    value={key.m_country}
                                                                                                    id={`m_country_${index}`}
                                                                                                    name={`m_country_${index}`}
                                                                                                    onChange={onChangeHandle}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button-group">
                                                                            <button
                                                                                className="btn btn-sm add-button w-100"
                                                                                onClick={() => onHandleUpdateAddress(key.id)}
                                                                            >
                                                                                {/* <i data-feather="edit" /> */}
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-sm add-button w-100"
                                                                                onClick={() => onHandleDeleteAddress(key.id)}
                                                                            >
                                                                                {/* <i data-feather="trash-2" /> */}
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={{ display: "flex", justifyContent: "center", fontSize: 28, fontWeight: "bold" }}>Please add your address for further order</h3>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    {/* testimonial */}
                                    <div
                                        className="tab-pane fade show"
                                        //className={active ? "tab-pane fade show active" : "tab-pane fade show"}

                                        id="pills-testimonial"
                                        role="tabpanel"
                                        aria-labelledby="pills-testimonial-tab"
                                    >
                                        <div className="dashboard-address">
                                            <div className="title title-flex">
                                                <div>
                                                    <h2>My Testimonials</h2>
                                                    <span className="title-leaf">
                                                        <svg className="icon-width bg-gray">
                                                            <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
                                                    onClick={() => { onHanldeNewTestimonial() }}
                                                >
                                                    <i data-feather="plus" className="me-2" /> Add New Testimonial
                                                </button>
                                            </div>

                                            <div className="row g-sm-4 g-3">

                                                {userMultipleTestimonial.length != 0 ?
                                                    <>
                                                        {userMultipleTestimonial.map((key, index) => (
                                                            <>
                                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">

                                                                    <div className="address-box">
                                                                        <h5>Your testimonial no . {index + 1}</h5>

                                                                        <div>

                                                                            <div className="table-responsive address-table mt-3">
                                                                                <table className="table">
                                                                                    <tbody>

                                                                                        <thead>
                                                                                            <th>
                                                                                                <td className='mt-3'>Your feddback :</td>
                                                                                            </th>
                                                                                        </thead>

                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td >
                                                                                                    <input type='text'
                                                                                                        disabled={!editMode}
                                                                                                        style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "tdansparent", width: "100vh" }}
                                                                                                        value={key.client_feedback}
                                                                                                        id={`client_feedback_${index}`}
                                                                                                        name={`client_feedback_${index}`}
                                                                                                        onChange={onChangeHandle}
                                                                                                    />
                                                                                                </td>

                                                                                            </tr>

                                                                                        </tbody>



                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button-group">
                                                                            <button
                                                                                className="btn btn-sm add-button"
                                                                                onClick={() => onHandleUpdateTestimonial(key.id)}
                                                                            >
                                                                                {/* <i data-feather="edit" /> */}
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-sm add-button"
                                                                                onClick={() => onHandleDeleteTestimonial(key.id)}
                                                                            >
                                                                                {/* <i data-feather="trash-2" /> */}
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={{ display: "flex", justifyContent: "center", fontSize: 28, fontWeight: "bold" }}>Please add your address for further order</h3>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    {/* rewards */}
                                    <div
                                        className="tab-pane fade show"
                                        id="pills-card"
                                        role="tabpanel"
                                        aria-labelledby="pills-card-tab"
                                    >
                                        <div className="dashboard-card">
                                            <div className="title title-flex">
                                                <div>
                                                    <h2>My Reward Details</h2>
                                                    <span className="title-leaf">
                                                        <svg className="icon-width bg-gray">
                                                            <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                {/* <button
                                                    className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editCard"
                                                >
                                                    <i data-feather="plus" className="me-2" /> Add New Card
                                                </button> */}
                                            </div>
                                            <ul class="nav nav-tabs" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true">Level 1</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false">Level 2</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">Level 3</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content pt-5" id="tab-content">
                                                <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                                                    Level 1 selected

                                                    <div className="row g-4">
                                                
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                                                    <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0">
                                                        <i className="fa fa-info-circle float-end" onClick={onHanldeLevelInfo}></i>

                                                        <div className="grid-image">
                                                            <div className="image">
                                                                <img
                                                                    src="../assets/images/vendor-page/logo/2.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="contain-name">
                                                                <div>
                                                                    <div className="since-number">
                                                                        <h6>Reward Card no.</h6>
                                                                    </div>
                                                                    <h3>EC-8929552928-23544-4444</h3>
                                                                </div>
                                                                <div class="progress mb-2">
                                                                    <div class="progress-bar" role="progressbar" style={{ width: "25%", backgroundColor: "#0da487" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                                </div>
                                                                <label className="product-label">1500 Shiv Coins</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                {/* <div className="col-xxl-4 col-xl-6 col-lg-12 col-sm-6">
                                                    <div className="payment-card-detail">
                                                        <div className="card-details card-visa" style={{backgroundColor:"#0da487",padding: "7px 7px"}}>
                                                            <div className="card-number">
                                                                <h4>Reward Card Id - 
                                                                    <br/><span className='fw-bold'>EC-6546465-546-345</span></h4>
                                                            </div>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" style={{width:"25%",backgroundColor:"#dc3545"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                            </div>
                                                            <div className="valid-detail">
                                                                <div className="title">
                                                                    <span>ShivCoins</span>
                                                                    <span>Earned</span>
                                                                </div>
                                                                <div className="date">
                                                                    <h3>1200</h3>
                                                                </div> 
                                                                
                                                                
                                                                <div className="primary">
                                                                <span className="badge bg-pill badge-light">
                                                                        Earned : 1200 Shiv Coins
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="name-detail">
                                                                <div className="name">
                                                                    <h5>Audrey Carol</h5>
                                                                </div>
                                                                <div className="card-img">
                                                                    <img
                                                                        src="../assets/images/payment-icon/1.jpg"
                                                                        className="img-fluid  lazyloaded"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        {/* <div className="edit-card">
                                                            <a
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editCard"
                                                                href="javascript:void(0)"
                                                            >
                                                                <i className="far fa-edit" /> edit
                                                            </a>
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#removeProfile"
                                                            >
                                                                <i className="far fa-minus-square" /> delete
                                                            </a>
                                                        </div> 
                                                    </div>
                                                    {/* <div className="edit-card-mobile">
                                                        <a
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#editCard"
                                                            href="javascript:void(0)"
                                                        >
                                                            <i className="far fa-edit" /> edit
                                                        </a>
                                                        <a href="javascript:void(0)">
                                                            <i className="far fa-minus-square" />
                                                            delete
                                                        </a>
                                                    </div> 
                                                </div> */}
                                                {/* <div className="col-xxl-4 col-xl-6 col-lg-12 col-sm-6">
                                                    <div className="payment-card-detail">
                                                        <div className="card-details card-visa">
                                                            <div className="card-number">
                                                                <h4>XXXX - XXXX - XXXX - 1536</h4>
                                                            </div>
                                                            <div className="valid-detail">
                                                                <div className="title">
                                                                    <span>valid</span>
                                                                    <span>thru</span>
                                                                </div>
                                                                <div className="date">
                                                                    <h3>12/23</h3>
                                                                </div>
                                                                <div className="primary">
                                                                    <span className="badge bg-pill badge-light">
                                                                        primary
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="name-detail">
                                                                <div className="name">
                                                                    <h5>Leah Heather</h5>
                                                                </div>
                                                                <div className="card-img">
                                                                    <img
                                                                        src="../assets/images/payment-icon/2.jpg"
                                                                        className="img-fluid  lazyloaded"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edit-card">
                                                            <a
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editCard"
                                                                href="javascript:void(0)"
                                                            >
                                                                <i className="far fa-edit" /> edit
                                                            </a>
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#removeProfile"
                                                            >
                                                                <i className="far fa-minus-square" /> delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="edit-card-mobile">
                                                        <a
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#editCard"
                                                            href="javascript:void(0)"
                                                        >
                                                            <i className="far fa-edit" /> edit
                                                        </a>
                                                        <a href="javascript:void(0)">
                                                            <i className="far fa-minus-square" />
                                                            delete
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-xl-6 col-lg-12 col-sm-6">
                                                    <div className="payment-card-detail">
                                                        <div className="card-details dabit-card">
                                                            <div className="card-number">
                                                                <h4>XXXX - XXXX - XXXX - 1366</h4>
                                                            </div>
                                                            <div className="valid-detail">
                                                                <div className="title">
                                                                    <span>valid</span>
                                                                    <span>thru</span>
                                                                </div>
                                                                <div className="date">
                                                                    <h3>05/21</h3>
                                                                </div>
                                                                <div className="primary">
                                                                    <span className="badge bg-pill badge-light">
                                                                        primary
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="name-detail">
                                                                <div className="name">
                                                                    <h5>mark jecno</h5>
                                                                </div>
                                                                <div className="card-img">
                                                                    <img
                                                                        src="../assets/images/payment-icon/3.jpg"
                                                                        className="img-fluid  lazyloaded"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="edit-card">
                                                            <a
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editCard"
                                                                href="javascript:void(0)"
                                                            >
                                                                <i className="far fa-edit" /> edit
                                                            </a>
                                                            <a
                                                                href="javascript:void(0)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#removeProfile"
                                                            >
                                                                <i className="far fa-minus-square" /> delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="edit-card-mobile">
                                                        <a
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#editCard"
                                                            href="javascript:void(0)"
                                                        >
                                                            <i className="far fa-edit" /> edit
                                                        </a>
                                                        <a href="javascript:void(0)">
                                                            <i className="far fa-minus-square" />
                                                            delete
                                                        </a>
                                                    </div>
                                                </div> */}
                                            </div>
                                                </div>
                                                <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">Level 2 selected</div>
                                                <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">Level 3 selected</div>
                                            </div>

                                          
                                        </div>
                                    </div>

                                    {/* Gifts */}
                                    <div
                                        className="tab-pane fade show"
                                        id="pills-cashback"
                                        role="tabpanel"
                                        aria-labelledby="pills-cashback-tab"
                                    >
                                        <div className="dashboard-card">
                                            <div className="title title-flex">
                                                <div>
                                                    <h2>My Gifts</h2>
                                                    <span className="title-leaf">
                                                        <svg className="icon-width bg-gray">
                                                            <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="row g-4">

                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                                                    <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0">

                                                        <div className="grid-image">

                                                            <div className="image">
                                                                <img
                                                                    src="../assets/images/level/rupee.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="contain-name">
                                                                <div>
                                                                    <h3>100% Cashback 
                                                                        <span className='float-end'> <h5>X 9 times </h5></span> 
                                                                    </h3>

                                                                    <div className="since-number">

                                                                        <h5>Get 100% full cashback upto Rs 10,000 for every order placed by you</h5>

                                                                    </div>
                                                                    {/* <label style={{ backgroundColor: "rgb(164 13 13 / 10%)", color: "#ff4f4f" }} className="product-label mt-2">1500 Shiv Coins Remaining</label> */}
                                                                    <label className="product-label mt-2">On Completing Level 7</label>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <button className='btn btn-md bg-theme cart-button text-white w-100 mb-2' onClick={onHanldeGiftInfo}>Know when will you be eligible </button>
                                                        <button className='btn btn-md bg-danger cart-button text-white w-100 mb-2' onClick={onHanldeRewardCardStats}>See your reward cards status</button>

                                                    </div>
                                                </div>

                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                                                    <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0">

                                                        <div className="grid-image">

                                                            <div className="image">
                                                                <img
                                                                    src="../assets/images/level/insurance.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="contain-name">
                                                                <div>
                                                                    <h3>Insurance worth Rs 50,000
                                                                    <span className='float-end'> <h5>X 9 times </h5></span> 
                                                                    </h3>

                                                                    <div className="since-number">

                                                                        <h5>Get 100% full cashback upto Rs 10,000 for every order placed by you</h5>

                                                                    </div>
                                                                    {/* <label style={{ backgroundColor: "rgb(164 13 13 / 10%)", color: "#ff4f4f" }} className="product-label mt-2">1500 Shiv Coins Remaining</label> */}
                                                                    <label className="product-label mt-2">On Completing Level 7</label>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <button className='btn btn-md bg-theme cart-button text-white w-100 mb-2' onClick={onHanldeGiftInfo}>Know when will you be eligible </button>
                                                        <button className='btn btn-md bg-danger cart-button text-white w-100 mb-2' onClick={onHanldeRewardCardStats}>See your reward cards status</button>

                                                    </div>
                                                </div>

                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                                                    <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0">

                                                        <div className="grid-image">

                                                            <div className="image">
                                                                <img
                                                                    src="../assets/images/level/mediclaim.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="contain-name">
                                                                <div>
                                                                    <h3>Mediclaim worth Rs 1,00,000
                                                                    <span className='float-end'> <h5>X 9 times </h5></span> 
                                                                    </h3>

                                                                    <div className="since-number">

                                                                        <h5>Get 100% full cashback upto Rs 10,000 for every order placed by you</h5>

                                                                    </div>
                                                                    {/* <label style={{ backgroundColor: "rgb(164 13 13 / 10%)", color: "#ff4f4f" }} className="product-label mt-2">1500 Shiv Coins Remaining</label> */}
                                                                    <label className="product-label mt-2">On Completing Level 7</label>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <button className='btn btn-md bg-theme cart-button text-white w-100 mb-2' onClick={onHanldeGiftInfo}>Know when will you be eligible </button>
                                                        <button className='btn btn-md bg-danger cart-button text-white w-100 mb-2' onClick={onHanldeRewardCardStats}>See your reward cards status</button>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* profile */}
                                    <div
                                        className="tab-pane fade show"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <div className="dashboard-profile">
                                            <div className="title">
                                                <h2>My Profile</h2>
                                                <span className="title-leaf">
                                                    <svg className="icon-width bg-gray">
                                                        <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                    </svg>
                                                </span>
                                            </div>
                                            {/* <div className="profile-detail dashboard-bg-box">
                                                <div className="dashboard-title">
                                                    <h3>Profile Name</h3>
                                                </div>
                                                <div className="profile-name-detail">
                                                    <div className="d-sm-flex align-items-center d-block">
                                                        <h3>{userDetail.user_name}</h3>
                                                    </div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editProfile"
                                                    >
                                                        Edit
                                                    </a>
                                                </div>
                                                {/* <div className="location-profile">
                                                    <ul>
                                                        <li>
                                                            <div className="location-box">
                                                                <i data-feather="map-pin" />
                                                                <h6>Downers Grove, IL</h6>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="location-box">
                                                                <i data-feather="mail" />
                                                                <h6>vicki.pope@gmail.com</h6>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="location-box">
                                                                <i data-feather="check-square" />
                                                                <h6>Licensed for 2 years</h6>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="profile-description">
                                                    <p>
                                                        Residences can be classified by and how they are
                                                        connected to neighbouring residences and land. Different
                                                        types of housing tenure can be used for the same
                                                        physical type.
                                                    </p>
                                                </div> */}
                                            {/* </div> */}
                                            <div className="profile-about dashboard-bg-box">
                                                <div className="row">
                                                    <div className="col-xxl-7">
                                                        {/* <div className="dashboard-title mb-3">
                                                            <h3>Profile About</h3>
                                                        </div> */}
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Name :</td>
                                                                        <input
                                                                            type='text'
                                                                            disabled={!editMode}
                                                                            style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                            value={userDetail.user_name}
                                                                            id="user_name"
                                                                            name='user_name'
                                                                            onChange={onChangeHandle} // Corrected from onchange to onChange
                                                                        />
                                                                        {erroruserDetail.user_name && <div className="text-danger">{erroruserDetail.user_name}</div>}

                                                                        {/* <td>{userDetail.user_name}</td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>Gender :</td>
                                                                        <td>{userDetail.user_name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Birthday :</td>
                                                                        <td>21/05/1997</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td>Phone Number :</td>
                                                                        <td>
                                                                            <a href="javascript:void(0)">
                                                                                {" "}
                                                                                <input type='text'
                                                                                    disabled={!editMode}
                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                    value={userDetail.user_mobile}
                                                                                    id="user_mobile"
                                                                                    name='user_mobile'
                                                                                    onChange={onChangeHandle}
                                                                                />
                                                                                {erroruserDetail.user_mobile && <div className="text-danger">{erroruserDetail.user_mobile}</div>}
                                                                                {/* {userDetail.user_mobile} */}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>Address :</td>
                                                                    </tr> */}
                                                                    <tr>
                                                                        <td>Email :</td>
                                                                        <td>
                                                                            <a href="javascript:void(0)">
                                                                                <input type='text'
                                                                                    // disabled={!editMode}
                                                                                    style={{ border: editMode ? '1px solid black' : '0', backgroundColor: "transparent" }}
                                                                                    value={userDetail.user_email}
                                                                                    id="user_email"
                                                                                    name='user_email'
                                                                                    onChange={onChangeHandle}
                                                                                    disabled={true}
                                                                                />
                                                                                {/* {userDetail.user_email} */}
                                                                                <span onClick={editMode ? UpdateUserDetail : handleEditModeToggle}>
                                                                                    {editMode ? 'Save' : 'Edit'}
                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <div className="dashboard-title mb-3">
                                                            <h3>Login Details</h3>
                                                        </div> */}
                                                        {/* <div className="table-responsive">
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Email :</td>
                                                                        <td>
                                                                            <a href="javascript:void(0)">
                                                                                vicki.pope@gmail.com
                                                                                <span
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#editProfile"
                                                                                >
                                                                                    Edit
                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Password :</td>
                                                                        <td>
                                                                            <a href="javascript:void(0)">
                                                                                ●●●●●●
                                                                                <span
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#editProfile"
                                                                                >
                                                                                    Edit
                                                                                </span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-xxl-5">
                                                        <div className="profile-image">
                                                            <img
                                                                src="../assets/images/inner-page/dashboard-profile.png"
                                                                className="img-fluid  lazyload"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* levels */}
                                    <div
                                        className="tab-pane fade show"
                                        id="pills-security"
                                        role="tabpanel"
                                        aria-labelledby="pills-security-tab"
                                    >
                                        <div className="title">
                                            <h2>All Levels</h2>
                                            <span className="title-leaf">
                                                <svg className="icon-width bg-gray">
                                                    <use xlinkHref="../assets/svg/leaf.svg#leaf" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0">

                                            <div className="grid-image mb-0">
                                                <div className="contain-name">
                                                    <div>

                                                        <h3 className="mr-3">Level 1 <label className="product-label">1500 Shiv Coins Earned</label></h3>
                                                        <div className="since-number">
                                                            <h5>Earn a cashback of Rs 1000</h5>
                                                        </div>
                                                        <div className="since-number">
                                                            <h6>Each time a reward card completes level 1 , you will earn a cashback of Rs 1000</h6>
                                                        </div>
                                                    </div>
                                                    {/* <label className="product-label">Yet to be achieved</label> */}

                                                </div>
                                                <div className="image float-end">
                                                    <img
                                                        src="../assets/images/level/trophy-icon-color.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="seller-grid-box seller-grid-box-1 shadow-lg mb-0 mt-2 bg-secondary">
                                            <div className="grid-image mb-0">
                                                <div className="contain-name">
                                                    <div>
                                                        <h3>Level 2</h3>
                                                        <div className="since-number">
                                                            <h5>Earn a cashback of Rs 2000</h5>
                                                        </div>
                                                        <div className="since-number">
                                                            <h6>Each time a reward card completes level 2 , you will earn a cashback of Rs 2000</h6>
                                                        </div>
                                                    </div>
                                                    {/* <label className="product-label">Yet to be achieved</label> */}

                                                </div>
                                                <div className="image">
                                                    <img
                                                        src="../assets/images/level/trophy-icon-grayscale.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {isViewRewardCardStat &&
                <RewardCardStatsModal
                handleRewardCardStatsClose={onhandleRewardCardStatsClose} />
            }

            {isViewGift &&
                <GiftInfoModal
                    handleGiftClose={handleGiftClose} />
            }

            {isViewInfo &&
                <InfoModal
                    handleInfoClose={handleInfoClose} />
            }

            {editAddressMode &&
                <AddressModal
                    handleInputChange={handleInputChange}
                    newAddressData={newAddressData}
                    handleEditConfirm={handleEditConfirm}
                    handleUpdateAddAddress={handleUpdateAddAddress}
                    isAddingAddress={isAddingAddress}
                    handleAddAddress={handleAddAddress}
                    errorAddressData={errorAddressData}
                />}

            {editTestimonialMode &&
                <TestimoinalModal
                    handleInputChange={handleInputChange}
                    newTestimonialData={newTestimonialData}
                    handleEditConfirm={handleEditConfirm}
                    handleUpdateAddTestimonial={handleUpdateAddTestimonial}
                    isAddingTestimonial={isAddingTestimonial}
                    handleAddTestimonial={handleAddTestimonial}
                    errorTestimonialData={errorTestimonialData}
                />}
            {/* 
            <div className={isAddingTestimonial||isAddingAddress||editAddressMode||editTestimonialMode?"bg-overlay show ":"bg-overlay"}></div> */}


        </>

    )
}

export default UserDashboard;