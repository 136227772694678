import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const OrderSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [orderData,setOrderData] = useState({
         id: 0,
         unique_trn_id: null,
         client_purchaser_code: null,
         client_purchaser_name: null,
         agent_code: null,
         agent_name: null,
         vender_code: null,
         vender_name: null,
         customer_id: 0,
         grand_total: 0,
         order_status: 0,
         deleted: 0,
         img_url: null,
         username: null,
         created_on: "",
         updated_on: "",
         order_number: "",
         order_shipping_done: null,
         order_shipping_time: null,
         order_processing_done: null,
         order_processing_time: null,
         order_delivery_done: null,
         order_delivery_time: null,
         order_cancel_done: null,
         order_cancel_time: null,
         order_cancel_reason: null,
         order_cancel_option: null,
         order_cancel_by: null,
         order_user_name: "",
         order_user_address: "",
         order_user_pincode: "",
         order_user_address_type: "",
         order_user_city: "",
         order_user_country: "",
         user_id: null,
         user_profile: null,
         user_mobile: null,
         user_name: null,
         user_email: null,
         delievery_charges: 50,
         order_total_items: 1,
         orderItemMasters: [ ]
    });
    useEffect(()=>{
        console.log("location.state");
        console.log(location.state)
        if(location.state.orderData){
            setOrderData(location.state.orderData);
        }else{
            navigate("/")
        }
    });

    const handleNavigation = (orderId) => {
        navigate("/orderTracking", { state: { orderId: orderId } });
    }

    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 10);

    const hanldeProductDetail = (productId) => {

        //console.log(shoppingCart)
        let selectedItem = orderData.orderItemMasters.find(item => item.id === productId);
        navigate("/ProductDetail", { state: { productId: selectedItem.id } });
    }
    return (
        <>
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain breadscrumb-order">
                                <div className="order-box">
                                    <div className="order-image">
                                        <div className="checkmark">
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="star"
                                                height={19}
                                                viewBox="0 0 19 19"
                                                width={19}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                                            </svg>
                                            <svg
                                                className="checkmark__check"
                                                height={36}
                                                viewBox="0 0 48 36"
                                                width={48}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
                                            </svg>
                                            <svg
                                                className="checkmark__background"
                                                height={115}
                                                viewBox="0 0 120 115"
                                                width={120}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="order-contain">
                                        <h3 className="theme-color">Order Success</h3>
                                        <h5 className="text-content">
                                           Your Order hass been successfully placed !
                                        </h5>
                                        <h6>Order number: {orderData.order_number}</h6>
                                        <button className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                                         onClick={() => { handleNavigation(orderData.id) }}>
                                        Track your Order
                                        </button>
                                        {/* <h6>Track your Order{orderData.order_number}</h6> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <section className="cart-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row g-sm-4 g-3">
                        <div className="col-xxl-9 col-lg-8">
                            <div className="cart-table order-table order-table-2">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <tbody>
                                        { orderData.orderItemMasters.map((product, index) => (
                                            
                                                    <tr key={index} className="product-box-contain">
                                                        <td width="50%" className="product-detail">
                                                            <div className="product border-0">
                                                                <a className="product-image" onClick={()=>{hanldeProductDetail(product.product_id)}}>
                                                                    <img src={process.env.REACT_APP_API_BASE_URL +product.img_url} className="img-fluid lazyload" alt={product.product_title} />
                                                                </a>
                                                                <div className="product-detail">
                                                                    <ul>
                                                                        <li className="name">
                                                                            <a onClick={()=>{hanldeProductDetail(product.product_id)}}>{product.product_title.substring(0,40)}...</a>
                                                                        </li>
                                                                        <li className="text-content">
                                                                            <span className="text-title">Quantity</span> - {product.quantity}
                                                                        </li>
                                                                        {/* <li className="text-content">
                                                                            <span className="text-title">Sold By:</span> {product.vender_name}
                                                                        </li> */}
                                                                       
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td width="10%" className="price">
                                                            <h4 className="table-title text-content"></h4>
                                                            <h5>
                                                            Price - Rs {product.product_price} 
                                                            </h5>
                                                            {/* <h6 className="theme-color">You Save : Rs{product.product_del_price - product.product_price}</h6> */}
                                                        </td>
                                                       
                                                        <td className="subtotal">
                                                            <h4 className="table-title text-content">Total</h4>
                                                            <h5>Rs {product.quantity * product.product_price}</h5>
                                                        </td>
                                                       
                                                    </tr>
                                                ))}


                                         
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-lg-4">
                            <div className="row g-4">
                                <div className="col-lg-12 col-sm-6">
                                    <div className="summery-box">
                                        <div className="summery-header">
                                            <h3>Price Details</h3>
                                            <h5 className="ms-auto theme-color">({orderData.order_total_items} Items)</h5>
                                        </div>
                                        <ul className="summery-contain">
                                            <li>
                                                <h4>Total</h4>
                                                <h4 className="price">₹{orderData.grand_total}</h4>
                                            </li>
                                            <li>
                                                <h4>Savings</h4>
                                                <h4 className="price theme-color">-</h4>
                                            </li>
                                            <li>
                                                <h4>Coupon Discount</h4>
                                                <h4 className="price text-danger">-</h4>
                                            </li>
                                        </ul>
                                        <ul className="summery-total">
                                            <li className="list-total">
                                                <h4>Grand Total (Rs)</h4>
                                                <h4 className="price">₹{orderData.grand_total}</h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-6">
                                    <div className="summery-box">
                                        <div className="summery-header d-block">
                                            <h3>Shipping Address</h3>
                                        </div>
                                        <ul className="summery-contain pb-0 border-bottom-0">
                                            <li className="d-block">
                                                <h4>{orderData.order_user_address}</h4>
                                                <h4 className="mt-2">{orderData.order_user_city}</h4>
                                            </li>
                                            <li className="pb-0">
                                                <h4>Expected Date Of Delivery:</h4>
                                                <h4 className="price theme-color">
                                                    <a onClick={()=>{handleNavigation(orderData.id)}} className="text-danger">
                                                        Track Order
                                                    </a>
                                                </h4>
                                            </li>
                                        </ul>
                                        <ul className="summery-total">
                                            <li className="list-total border-top-0 pt-2">
                                                <h4 className="fw-bold">{futureDate.toDateString()}</h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section> 


        </>

    )
}

export default OrderSuccess;