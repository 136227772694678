import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './constants/layout/Footer';
import Header from './constants/layout/Header';
import { Pages } from './constants/Pages';
import Home from './pages/Home';
import { useSelector } from 'react-redux';



// import Footer from './constants/layout/Footer';
// import Header from './constants/layout/Header';
import About from '../src/pages/Auth/About';
import Banner from '../src/pages/About/Banner';
import Cart from '../src/pages/Order/Cart';
import Checkout from '../src/pages/Order/Checkout';
import Contact from '../src/pages/About/Contact';
// import Home from '../src/pages/Home';
import Login from '../src/pages/Auth/Login';
import OrderSuccess from '../src/pages/Order/OrderSuccess';
import Pg404 from '../src/pages/Pg404';
import Product from '../src/pages/Product/Product';
import ProductDetail from '../src/pages/Product/ProductDetail';
import UserDashboard from '../src/pages/User/UserDashboard';
import Register from '../src/pages/Auth/Register';
import ListPage from '../src/pages/demo';
import BlogList from '../src/pages/About/BlogList';
import BlogDetail from '../src/pages/About/BlogDetail';
import Faq from '../src/pages/About/Faq';
import ForgotPassword from '../src/pages/Auth/ForgotPassword';
import OTP from '../src/pages/Auth/OTP';
import OrderTracking from '../src/pages/Order/OrderTracking';
import Term_cond from '../src/pages/terms&Condition/Term_cond';
import Privacy_policy from '../src/pages/terms&Condition/Privacy_policy';
import Return_policy from '../src/pages/terms&Condition/Return_policy';
import ForgetConfirm from '../src/pages/Auth/ForgotConfirm';
import OrderCancellation from '../src/pages/User/Cancellation';
import OrderOtp from '../src/pages/User/OrderOtp';
import ProductList from '../src/pages/Product/ProductList';
import Invoice from '../src/pages/Invoice';
import OrderReturn from '../src/pages/Order/OrderReturn';
import ProductSearchPage from '../src/pages/Product/ProductSearchPage';
import { useEffect } from 'react';
import Search from './pages/About/Search';
import TreeComponent from './pages/Points/TreeComponent';
import PointsData from './pages/Points/PointsData';

function App() {

  const authData = useSelector(state => state.authState);

  const scripts = [
    "./js/jquery-3.6.0.min.js",
    "./js/jquery-ui.min.js",
    "./js/bootstrap/bootstrap.bundle.min.js",
    "./js/bootstrap/bootstrap-notify.min.js",
    "./js/bootstrap/popper.min.js",
    "./js/feather/feather.min.js",
    "./js/feather/feather-icon.js",
    "./js/lazysizes.min.js",
    "./js/slick/slick.js",
    "./js/slick/slick-animation.min.js",
    "./js/custom-slick-animated.js",
    "./js/slick/custom_slick.js",
    "./js/ion.rangeSlider.min.js",
    "./js/js/filter-sidebar.js",
    "./js/quantity-2.js",
    "./js/jquery.elevatezoom.js",
    "./js/zoom-filter.js",
    "./js/timer1.js",
    "./js/sticky-cart-bottom.js",
    "./js/wow.min.js",
    "./js/custom-wow.js",
    "./js/script.js",
    //"./js/theme-setting.js"
  
  ]

  useEffect(() => {
    scripts.forEach((element) => {
      const script = document.createElement('script');

      script.src = element;
      script.type = "text/css";
      script.async = true;
      
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
    };
    });

    
}, []);


  return (
    <>
      <Header />
      <Routes>

        {/* Public Routes */}

        <Route path="/" element={<Home />} key="home" />
        <Route path="/tree" element={<TreeComponent/>} key="tree" />
        <Route path="/PointsData" element={<PointsData/>} key="tree" />
        <Route path="/About" element={<About />} key="about" />
        <Route path="/Banner" element={<Banner />} key="banner" />
        <Route path="/Cart" element={<Cart />} key="cart" />
        <Route path="/Checkout" element={<Checkout />} key="checkout" />
        <Route path="/Contact" element={<Contact />} key="contact" />
        <Route path="/Login" element={<Login />} key="login" />
        <Route path="/Pg404" element={<Pg404 />} key="pg404" />
        <Route path="/Product" element={<Product />} key="product" />
        <Route path="/ProductDetail" element={<ProductDetail />} key="productDetail" />
        {/* <Route path="/UserDashboard" element={<UserDashboard />} key="userDashboard" /> */}
        <Route path="/Register" element={<Register />} key="register" />
        <Route path="/demo" element={<ListPage />} key="listPage" />
        <Route path="/blog" element={<BlogList />} key="blogList" />
        <Route path="/blogDetail" element={<BlogDetail />} key="blogDetail" />
        <Route path="/faq" element={<Faq />} key="faq" />
        <Route path="/Forget" element={<ForgotPassword />} key="forgotPassword" />
        <Route path="/otp" element={<OTP />} key="otp" />
        <Route path="/term" element={<Term_cond />} key="termCond" />
        <Route path="/privacyPolicy" element={<Privacy_policy />} key="privacyPolicy" />
        <Route path="/returnPolicy" element={<Return_policy />} key="returnPolicy" />
        <Route path="/Confirm" element={<ForgetConfirm />} key="forgetConfirm" />
        {/* <Route path="/ordercancel" element={<OrderCancellation />} key="orderCancellation" /> */}
        <Route path="/ProductList" element={<ProductList />} key="productList" />
        <Route path="/invoice" element={<Invoice />} key="invoice" />
        <Route path="/searchProduct" element={<ProductSearchPage />} key="productSearchPage" />
        <Route path="/search" element={<Search />} key="SearchPage" />

        {/* Private Routes */}

        {authData.login && (
          <>
            <Route path="/UserDashboard" element={<UserDashboard />} key="userDashboard" />
            <Route path="/ordercancel" element={<OrderCancellation />} key="orderCancellation" />
            <Route path="/verifyCancellation" element={<OrderOtp />} key="orderOtp" />
            <Route path="/return" element={<OrderReturn />} key="orderReturn" />
            <Route path="/ordertracking" element={<OrderTracking />} key="orderTracking" />
            <Route path="/order" element={<OrderSuccess />} key="orderSuccess" />

          </>
        )}

        {/* {Pages.map((page) => {
          return (
            
            <Route
              path={page.path}
              element={<page.component />}
              key={page.name}
            />)

        })} */}
      </Routes>
      <Footer />

    </>
  );

}

export default App;
