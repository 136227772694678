import { useState, useEffect } from "react";
import { apiService } from "../../constants/ApiService";
import { useLocation, useNavigate } from 'react-router-dom';






const OrderCancellation = () => {




    const [orderId, setOrderId] = useState("");
    const [loader, setLoader] = useState(false);
    const [orderCancelReason, setOrderCancelReason] = useState("");
    const [ orderCancelOption , setOrderCancelOption] = useState("");

    const location = useLocation();
    const navigate = useNavigate();


    const cancelOrderDetail = async () => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            let params = {
                order_status: 4,
                order_cancel_reason: orderCancelReason,
                order_cancel_option :orderCancelOption,
                order_cancel_by:"user"
            }

            setLoader(true)
            const response = await apiService.put(`/api/OrderMaster/updateOrderStatus?orderId=${orderId}`, params, config)
            setLoader(false)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                alert(response.message);
                navigate("/userdashboard")
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    useEffect(() => {
        if (location.state) {
            setOrderId(location.state.orderId)
        }
    }, [location.state]);


    const [helperTable, setHelperTable] = useState([]);
    const getHelperData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/HelperMaster/allOption?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                alert(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response)
                setHelperTable(response.helperMasterData)
                // alert(response.message);
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    useEffect(() => {
        getHelperData();
    }, [!helperTable]);


    return (
        <>
            <section className="faq-breadscrumb pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain" style={{ textAlign: "left" }}>
                                <h2 className="text-left mb-3" >Why do you want to cancel your order?</h2>
                                <h6 className="text-left mb-3">
                                    Please write a reason for your cancellation below:
                                </h6>


                                <div className="input-group">
                                    <select
                                        className="form-control mb-3"
                                        id="field_data"
                                        name="field_data"
                                        onChange={(e) => setOrderCancelOption(e.target.value)}
                                    >
                                        <option value="select">Select</option>
                                        {helperTable.map((data, index) => (
                                            <option key={index} value={data.field_data}>{data.field_data}</option>
                                        ))}
                                    </select>

                                </div>

                                <div className="input-group">
                                    <textarea
                                        className="form-control mb-3"
                                        id="exampleFormControlInput1"
                                        placeholder="Type here...."
                                        onChange={(e) => setOrderCancelReason(e.target.value)}
                                    ></textarea>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => { cancelOrderDetail() }}
                                    className="btn btn-animation proceed-btn fw-bold"
                                    disabled={!orderCancelReason}
                                >
                                    {loader ? "Loading ...." : "Proceed Cancellation"}
                                </button>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OrderCancellation;