

import React, { useState, useEffect } from 'react';
import { apiService } from '../../constants/ApiService';
import { useNavigate, useLocation, Link } from "react-router-dom";



const ForgetConfirm = () => {

    const navigate = useNavigate();
    const [ email , setEmail]= useState("")
    const [ loader , setLoader ] = useState(false)
    const location = useLocation();
    const initial ={
        otp: '',
        password: '',
        confirmPassword: ''
    }
    const [confirmDetail, setConfirmDetail] = useState(initial);
    const [ errorConfirmPasswordDetail,setErrorConfirmPasswordDetail] = useState({})
    const [ apiError , setApiError ]= useState()


    const onChangeHandle = (e) => {
        const { name, value } = e.target;
        setConfirmDetail(prevInputs => ({
            ...prevInputs,
            [name]: value
        }));
    };



    
    const validateInput = () => {
        let errors = {};

        if (!confirmDetail.otp || confirmDetail.otp.length !== 5) {
            errors.otp = "OTP must be 5 digits";
        }

        if (!confirmDetail.password) {
            errors.password = "Password is required";
        }

        if (!confirmDetail.confirmPassword) {
            errors.confirmPassword = "Confirm Password is required";
        } else if (confirmDetail.password !== confirmDetail.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }

        setErrorConfirmPasswordDetail(errors);
        const isValid = Object.keys(errors).length === 0;
        return isValid;
    };

    const ConfirmForgetPassword = async() => {

        const isValid = await validateInput();
        if (!isValid) {
            return
        }

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };


            setLoader(true)
            const response = await apiService.get(`/api/admin/User_Detail/ForgotPassword?password=${confirmDetail.password}&confirmPassword=${confirmDetail.confirmPassword}&email=${email}&otp=${confirmDetail.otp}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            setLoader(false)
            if (response.status === true) {
                navigate("/")
                setConfirmDetail(initial)
                console.log("Your password successfully changed");
                return
            }
            else {
                setApiError("Wrong OTP!")
                // console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    useEffect(() => {
        if (location.state) {
            console.log(location.state)
            setEmail(location.state.email)
        }
    }, [location.state]);


    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Forgot Password</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">Forgot Password</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* log in section start */}
            <section className="log-in-section section-b-space forgot-section">
                <div className="container-fluid-lg w-100">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div className="image-contain">
                                <img
                                    src="../assets/images/inner-page/forgot.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className="log-in-box">
                                    <div className="log-in-title">
                                        <h3>Welcome To SHIVKMS</h3>
                                        <h4>Forgot your password</h4>
                                    </div>
                                    {apiError && <div className="text-danger mt-3" style={{ fontSize: 18, marginBottom: 20 }}>{apiError}</div>}
                                    <div className="input-box">
                                        <form className="row g-4">
                                            <div className="col-12">
                                                <div className="form-floating theme-form-floating log-in-form">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="otp"
                                                        name="otp"
                                                        placeholder="Otp"
                                                        maxLength={5}
                                                        value={confirmDetail.otp}
                                                        onChange={(e) => onChangeHandle(e)}
                                                        />
                                                    <label htmlFor="email">OTP</label>
                                                    {errorConfirmPasswordDetail.otp && <div className="text-danger">{errorConfirmPasswordDetail.otp}</div>}

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating theme-form-floating">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        name="password"
                                                        id="password"
                                                        value={confirmDetail.password}
                                                        onChange={(e) => onChangeHandle(e)}
                                                    />
                                                    <label htmlFor="password">Password</label>
                                                </div>
                                                {errorConfirmPasswordDetail.password && <div className="text-danger">{errorConfirmPasswordDetail.password}</div>}

                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating theme-form-floating">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        value={confirmDetail.confirmPassword}
                                                        onChange={(e) => onChangeHandle(e)}
                                                    />
                                                    <label htmlFor="password">Confirm Password</label>
                                                </div>
                                                {errorConfirmPasswordDetail.confirmPassword && <div className="text-danger">{errorConfirmPasswordDetail.confirmPassword}</div>}
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-animation w-100" type="button" onClick={()=>{ConfirmForgetPassword()}}>
                                                    {loader ? "Loading ...." : "Confirm"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* log in section end */}
        </>

    )
}

export default ForgetConfirm;