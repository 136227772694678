import axios from 'axios';
import { store } from '../reducetool/store';
import { resetToken } from '../reducetool/features/authSlice';
const API_URL = process.env.REACT_APP_API_BASE_URL;





const axiosInstance = axios.create({
    baseURL: API_URL
});


axiosInstance.interceptors.request.use(

    (config) => {
        const authData = store.getState().authState;
        //const expirationTime =  addHours(authData.issue_dt, 24); 

        if (authData.login) {
            const userId = authData.user_id
            const token = authData.token_data
            const tokenId = authData.token_id
            if (token) {
                config.headers['Token_Data'] = token;
                config.headers['Token_ID'] = tokenId;
                config.headers['Id'] = userId;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => {

        switch (response.status) {
            case 401:
                window.location.href = "/Login";
                store.dispatch(resetToken());
                throw new Error(response.message);
            case 403:
                throw new Error(response.message);
            default:
                break;
        }

        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch(resetToken());
            const authData = store.getState().authState;
            //console.log(authData, "auth data rij in a asiox api  ")
        }
        return Promise.reject(error);
    }
);


// useEffect(()=>{
//     const fetchToken=()=>{
//         authData = useSelector(state => state.authState);
//     }
//     fetchToken()
// })

export const apiService = {

    // A function to make a GET request
    get: async (url, config = {}) => {
        try {
            const response = await axiosInstance.get(url, config);
            return response.data;
        } catch (error) {
            return error;
        }
    },

    // A function to make a POST request
    post: async (url, data = {}, config = {}) => {
        try {
            const response = await axiosInstance.post(API_URL + url, data, config);
            return response.data;
        } catch (error) {
            return error;
        }
    },

    // A function to make a PUT request
    put: async (url, data = {}, config = {}) => {
        try {
            const response = await axiosInstance.put(API_URL + url, data, config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    // A function to make a DELETE request
    delete: async (url, data = {}, config = {}) => {
        try {
            const response = await axiosInstance.delete(API_URL + url, data, config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};
