


import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate,Link, redirect } from 'react-router-dom';
import { apiService } from "../../constants/ApiService";
import { resetToken } from "../../reducetool/features/authSlice";
import { useDispatch } from "react-redux";

import { setToken } from '../../reducetool/features/authSlice';

const OTP = () => {

    const dispatch = useDispatch();
    const [otp, setOtp] = useState(""); 
    const [mobile, setMobile] = useState(0);
    const [email, setEmail] = useState("");
    const [cred, setCred] = useState("");
    
    const [apiMessage, setApiMessage] = useState('');
    const [btnMsg, setBtnMsg] = useState("Validate");

    const inputRefs = Array.from({ length: 4 }).map(() => React.createRef());
    
    const location = useLocation();
    const navigate = useNavigate();


    const handleOnChange = (e, index) => {
        const { value } = e.target;
    
        if (value.length === 0 && index > 0 && inputRefs[index - 1]) {
            inputRefs[index - 1].current.focus();
        } 
        else if (value.length === 1 && index < 4 && inputRefs[index + 1]) {
            inputRefs[index + 1].current.focus();
        }
    
        setOtp((prevOtp) => {
            let newOtp = prevOtp.slice(0, index) + value + prevOtp.slice(index + 1);
            newOtp = newOtp.substring(0, 6);
            return newOtp;
        });
    };
    
    const verifyOtp = async() => {

        setBtnMsg("Validating... Please wait");

        if (otp.length < 4) {
            alert("Please enter the complete OTP.");
            return;
        }

        try {
            
            debugger;
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            let response;

            if (location.state.purpose === "Registration") {
                response = await apiService.get(`api/admin/User_Detail/ValidateMobileOTPforRegistration?mobile=${mobile}&otp=${otp}`, config);
            }

            if (location.state.purpose === "Login") {
                response = await apiService.get(`api/admin/User_Detail/ValidateLoginOTP?identifier=${cred}&otp=${otp}`, config);
            }

            //previous register function
            //response = await apiService.get(`/api/admin/User_Detail/ValidateEmailOTP?email=${email}&otp=${otp}`, config);

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                debugger;
                setApiMessage(response.message);
                if (location.state.purpose === "Registration") {
                    userLogin();
                    //navigate('/login');
                }

                if (location.state.purpose === "Login") {

                    //const { token_data, token_id } = response.authToken;
                    const user = response.user;

                    // Exclude the specific field (e.g., 'description')
                    const { user_pass, ...filteredUserData } = user;

                    let authObj = {
                        token_data: response.authToken.token_data,
                        token_id: response.authToken.token_id,
                        user_id: response.user.id,
                        user_name : response.user.user_name,
                        user:filteredUserData
                    }
                    dispatch(setToken(authObj));
                    console.log("Login Succefully");   
                    
                    navigate("/UserDashboard");               
                    
                    // if (user.user_email_verified_yn === "yes") {
                    //     navigate('/Dashboard');

                    // } else {
                    //     navigate('/Dashboard/UserProfile');
                    // }

                }
            }
            else {
                setApiMessage(response.message)
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
        setBtnMsg("Validate");

    };

    const userLogin = async () => {

        const loginData = {
        auth_type: "profile_type",
        user_mobile: location.state.registerData.user_mobile,
        user_pass: location.state.registerData.user_pass,
        }

        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };
            //setLoader(true)
            const response = await apiService.post(`/api/admin/User_Detail/Login`, loginData, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            //setLoader(false)


            if (response.status === true) {
                const user = response.user;
                // Exclude the specific field 
                const { user_pass, ...filteredUserData } = user;

                let authObj = {
                    token_data: response.authToken.token_data,
                    token_id: response.authToken.token_id,
                    user_id: response.user.id,
                    user_name : response.user.user_name,
                    user:filteredUserData
                }
                dispatch(setToken(authObj));
                console.log("Login Succefully");
                
                navigate("/UserDashboard");
            }
            else {
                // console.log(response.errorMessage)
                setApiMessage(response.errorMessage)
                // console.log(response.errorMessage);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    const resendOtp = async() => {
        setApiMessage("Sending.... Please wait!");
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/admin/User_Detail/GenerateOTP?email=${email}&otp_purpose=${"New Registration"}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setApiMessage("OTP sent! Check your Email");
                //alert("OTP sent! Check your Email")
                // alert("Register Succefully");
                // navigate("/")
                return

            }
            else {
                setApiMessage(response.message);

                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };
    useEffect(() => {
        if (location.state) {
            if (location.state.purpose === "Registration") {
                console.log(location.state)
                setMobile(location.state.registerData.user_mobile)
            }

            if (location.state.purpose === "Login") {
                setCred(location.state.loginData.identifier);
                if (/^\d+$/.test(location.state.loginData.identifier)) {
                    setMobile(location.state.loginData.identifier);
                } else {
                    setEmail(location.state.loginData.identifier);
                }
            }
            
        }else{
            redirect("/")
        }

      }, [location.state]);

      const padFunction = (number) => {
        let string = String(number)
        let sliced = string.slice(-4);
        let mask = String(sliced).padStart(string.length, "*")
        return mask;
    }

    const emailPadFunction = (email) => {

        let sliced = email.slice(10);
        let mask = String(sliced).padStart(email.length, "*")
        return mask;
    }


    return (
        <>
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>OTP</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">OTP</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="log-in-section otp-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div className="image-contain">
                                <img
                                    src="../assets/images/inner-page/otp.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className="log-in-box">
                                    <div className="log-in-title">
                                        <h3 className="text-title">
                                            Please enter the one time password to verify your account
                                        </h3>
                                        
                                        {apiMessage && <div className="text-danger mt-3" style={{fontSize:18, marginBottom:20}}>{apiMessage}</div>}
                                        <h5 className="text-content">A code has been sent to
                                        <span>{mobile !== 0 && padFunction(mobile)}</span>
                                        <span>{email && (<>{emailPadFunction(email)} <br />Note : Please check your spam folder as well</>)}</span>
                                        </h5>
                                    </div>
                                    {/* <div
                                        id="otp"
                                        className="inputs d-flex flex-row justify-content-center"
                                    >
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="first"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="second"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="third"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="fourth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="fifth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            id="sixth"
                                            maxLength={1}
                                            // placeholder={0}
                                        />
                                    </div> */}

                                    <div id="otp" className="inputs d-flex flex-row justify-content-center">
                                        {inputRefs.map((inputRef, index) => (
                                            <input
                                                key={index}
                                                ref={inputRef}
                                                className="text-center form-control rounded"
                                                type="text"
                                                maxLength={1}
                                                // onKeyDown={(e) => handleKeyPress(e, index)}
                                                onChange={(e) => handleOnChange(e, index)}
                                            />
                                        ))}
                                    </div>
                                    <div className="send-box pt-4">
                                        <h5>
                                            Didn't get the code?{" "}
                                            <a href="javascript:void(0)" className="theme-color fw-bold"
                                            onClick={()=>{resendOtp()}}
                                            >
                                                Resend It
                                            </a>
                                        </h5>
                                    </div>
                                    <button
                                        onclick="location.href = 'index-2.html';"
                                        className="btn btn-animation w-100 mt-3"
                                        type="button"
                                        onClick={()=>{verifyOtp()}}
                                    >
                                        {btnMsg}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default OTP;