import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { apiService } from '../../constants/ApiService';
import { allRemoveItem } from '../../reducetool/features/cardSlice';
import UserDashboard from '../User/UserDashboard';
const Checkout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shoppingCart = useSelector(state => state.cardState);
    const authData = useSelector(state => state.authState);
    const [ selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const [userMultipleAddressList, setUserMultipleAddressList] = useState([])
    const [selectedAddressOption,setSelectedAddressOption]= useState({})

    let SubTotal = shoppingCart.items.reduce((total, item) => total + (item.product_price * item.quantity), 0)


    const handlePaymentOptionChange = (e) => {
        setSelectedPaymentOption(e.target.value);
    };

    const payment = async () => {

        if (selectedPaymentOption === null) {
            alert("Please select a Payment option");
            return
        }

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };


            let params = {
                payment_mode: selectedPaymentOption,
                user_id: authData.user_id,
                amount: SubTotal
            }

            const response = await apiService.post(`/api/PaymentMaster/create`, params, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // console.log(response.message)
                return true;
            }
            else {
                console.log(response.message);
                return false;
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    const deleteAddToCardData = async()=>{
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.post(`/api/AddToCartMaster/delete?userId=${authData.user_id}`, shoppingCart.items, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                // dispatch(allRemoveItem())
                // console.log(response.message)
                // dispatch(allRemoveItem())
                // navigate('/order')
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    const checkOutOrder = async () => {

        if(userMultipleAddressList.length == 0)
        {
            navigate("/userdashboard?option=true");
            return
        }else{
            if (Object.keys(selectedAddressOption).length === 0 ) {
                alert("Please select an Address option");
                return
            }
        }
    
        //let payemntResponse = await payment()
        //if (payemntResponse) {

            await  deleteAddToCardData()

            try {
                let config = {
                    headers: {
                        'Content-Type': 'application/json', // Important for file upload//s
                        'accept': 'application/json'
                    }
                };


                let params = {
                    grand_total: SubTotal,
                    order_status: 0,
                    orderItemMasterKey: shoppingCart.items,
                    customer_id: authData.user_id,
                    order_user_name : selectedAddressOption.m_form_name,
                    order_user_address : selectedAddressOption.m_address,
                    order_user_pincode : selectedAddressOption.m_pin_code,
                    order_user_address_type : selectedAddressOption.m_address_type,
                    order_user_city : selectedAddressOption.m_city,
                    order_user_country : selectedAddressOption.m_country,
                    order_payment_method : selectedPaymentOption
                }

                const response = await apiService.post(`/api/OrderMaster/create`, params, config)
                if (response.response && response.response.data.errors) {
                    console.log(response.response?.data?.errors)
                }

                if (response.status === true) {
                    console.log(response.orderMasterDataByID);
                    dispatch(allRemoveItem());
                    navigate('/order',{state:{orderData:response.orderMasterDataByID}})
                }
                else {
                    console.log(response.message);
                }
            } catch (error) {
                console.log(error)
                alert('SomeThing went wrong!!');
            }


        //}
    }

    const handleAddressOptionChange = (id) => {
        let findArray = userMultipleAddressList.find((data)=> data.id == id)
        setSelectedAddressOption(findArray);
    };

    const getUserMultipleAddress = async () => {
        try {

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'User_id': authData.user_id
                }
            };

            const response = await apiService.get(`/api/admin/User_Detail/GetUserMultiAddress`, config)

            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }
            if (response.status === true) {
                setUserMultipleAddressList(response.multiUserAddData)
            }
            else {
                // console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }


    // const  getAddToCartData = async()=>{
    //     try {
    //         let config = {
    //             headers: {
    //                 'Content-Type': 'application/json', // Important for file upload//s
    //                 'accept': 'application/json'
    //             }
    //         };

    //         const response = await apiService.get(`/api/AddToCartMaster/addToCartByUserId?userId=${authData.user_id}`, config)
    //         if (response.response && response.response.data.errors) {
    //             alert(response.response?.data?.errors)
    //         }

    //         if (response.status === true) {
    //             console.log(response)
    //             // dispatch(allRemoveItem())
    //             // alert(response.message)
    //             // dispatch(allRemoveItem())
    //             // navigate('/order')
    //         }
    //         else {
    //             alert(response.message);
    //         }
    //     } catch (error) {
    //         console.log(error)
    //         alert('SomeThing went wrong!!');
    //     }
    // }

    useEffect(()=>{
        if(authData.login){
            getUserMultipleAddress()
        }
        // getAddToCartData()
    },[!userMultipleAddressList])




    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0 ">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Checkout</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Checkout
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* Checkout section Start */}
            <section className="checkout-section-2 section-b-space mb-5">
                <div className="container-fluid-lg">
                    <div className="row g-sm-4 g-3">
                        <div className="col-lg-8">
                            <div className="left-sidebar-checkout">
                                <div className="checkout-detail-box">
                                    <ul>
                                        <li>
                                            <div className="checkout-icon">
                                                <lord-icon
                                                    target=".nav-item"
                                                    src="https://cdn.lordicon.com/ggihhudh.json"
                                                    trigger="loop-on-hover"
                                                    colors="primary:#121331,secondary:#646e78,tertiary:#0baf9a"
                                                    className="lord-icon"
                                                ></lord-icon>
                                            </div>
                                            <div className="checkout-box">
                                                <div className="checkout-title">
                                                    <h4>Delivery Address</h4>
                                                </div>
                                                <div className="checkout-detail">
                                                    <div className="row g-4">
                                                        {userMultipleAddressList? userMultipleAddressList.map((key,i) => (
                                                            <div className="col-xxl-6 col-lg-12 col-md-6">
                                                                <div className="delivery-address-box">
                                                                    <div>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="jack"
                                                                                id="flexRadioDefault2"
                                                                                //checked={i===0} // Check if the current key is selected
                                                                                onChange={() => handleAddressOptionChange(key.id)}

                                                                            />
                                                                        </div>
                                                                        <div className="label">
                                                                            <label>{key.m_address_type}</label>
                                                                        </div>
                                                                        <ul className="delivery-address-detail">
                                                                            <li>
                                                                                <h4 className="fw-500">{key.m_form_name}</h4>
                                                                            </li>
                                                                            <li>
                                                                                <p className="text-content">
                                                                                    <span className="text-title">
                                                                                        Address :
                                                                                    </span>
                                                                                    {key.m_address}
                                                                                </p>
                                                                            </li>
                                                                            <li>
                                                                                <h6 className="text-content">
                                                                                    <span className="text-title">
                                                                                        Pin Code :
                                                                                    </span>
                                                                                    {key.m_pin_code}
                                                                                </h6>
                                                                            </li>

                                                                            <li>
                                                                                <h6 className="text-content">
                                                                                    <span className="text-title">
                                                                                        City :
                                                                                    </span>
                                                                                    {key.m_city}
                                                                                </h6>
                                                                            </li>
                                                                            <li>
                                                                                <h6 className="text-content">
                                                                                    <span className="text-title">
                                                                                        Country :
                                                                                    </span>
                                                                                    {key.m_country}
                                                                                </h6>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )):<Link to="/userdashboard?option= true">Add your address from here</Link>}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/* <div className="checkout-icon">
                                                <lord-icon
                                                    target=".nav-item"
                                                    src="https://cdn.lordicon.com/oaflahpk.json"
                                                    trigger="loop-on-hover"
                                                    colors="primary:#0baf9a"
                                                    className="lord-icon"
                                                ></lord-icon>
                                            </div> */}
                                            {/* <div className="checkout-box">
                                                <div className="checkout-title">
                                                    <h4>Delivery Option</h4>
                                                </div>
                                                <div className="checkout-detail">
                                                    <div className="row g-4">
                                                        <div className="col-xxl-6">
                                                            <div className="delivery-option">
                                                                <div className="delivery-category">
                                                                    <div className="shipment-detail">
                                                                        <div className="form-check custom-form-check hide-check-box">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="standard"
                                                                                id="standard"
                                                                                defaultChecked=""
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="standard"
                                                                            >
                                                                                Standard Delivery Option
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="delivery-option">
                                                                <div className="delivery-category">
                                                                    <div className="shipment-detail">
                                                                        <div className="form-check mb-0 custom-form-check show-box-checked">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="standard"
                                                                                id="future"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="future"
                                                                            >
                                                                                Future Delivery Option
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 future-box">
                                                            <div className="future-option">
                                                                <div className="row g-md-0 gy-4">
                                                                    <div className="col-md-6">
                                                                        <div className="delivery-items">
                                                                            <div>
                                                                                <h5 className="items text-content">
                                                                                    <span>3 Items</span>@ ₹693.48
                                                                                </h5>
                                                                                <h5 className="charge text-content">
                                                                                    Delivery Charge ₹34.67
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn p-0"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        title="Extra Charge"
                                                                                    >
                                                                                        <i className="fa-solid fa-circle-exclamation" />
                                                                                    </button>
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <form className="form-floating theme-form-floating date-box">
                                                                            <input type="date" className="form-control" />
                                                                            <label>Select Date</label>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </li>
                                        <li>
                                            <div className="checkout-icon">
                                                <lord-icon
                                                    target=".nav-item"
                                                    src="https://cdn.lordicon.com/qmcsqnle.json"
                                                    trigger="loop-on-hover"
                                                    colors="primary:#0baf9a,secondary:#0baf9a"
                                                    className="lord-icon"
                                                ></lord-icon>
                                            </div>
                                            <div className="checkout-box">
                                                <div className="checkout-title">
                                                    <h4>Payment Option</h4>
                                                </div>
                                                <div className="checkout-detail">
                                                    <div
                                                        className="accordion accordion-flush custom-accordion"
                                                        id="accordionFlushExample"
                                                    >
                                                        <div className="accordion-item">
                                                            <div
                                                                className="accordion-header"
                                                                id="flush-headingFour"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#flush-collapseFour"
                                                                >
                                                                    <div className="custom-form-check form-check mb-0">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="cash"
                                                                        >
                                                                            <input
                                                                                className="form-check-input mt-0"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="cash"
                                                                                value="cash"
                                                                                onChange={handlePaymentOptionChange}
                                                                                //defaultChecked="cash"
                                                                                //checked
                                                                            />
                                                                            Cash On Delivery
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="flush-collapseFour"
                                                                className="accordion-collapse collapse show"
                                                                data-bs-parent="#accordionFlushExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <p className="cod-review">
                                                                        Pay at the time of delievery through Cash/UPI
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="accordion-item">
                                                            <div
                                                                className="accordion-header"
                                                                id="flush-headingOne"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#flush-collapseOne"
                                                                >
                                                                    <div className="custom-form-check form-check mb-0">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="credit"
                                                                        >
                                                                            <input
                                                                                className="form-check-input mt-0"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="credit"
                                                                                value="credit"
                                                                                onChange={handlePaymentOptionChange}
                                                                            />
                                                                            Credit or Debit Card
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="flush-collapseOne"
                                                                className="accordion-collapse collapse"
                                                                data-bs-parent="#accordionFlushExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="row g-2">
                                                                        <div className="col-12">
                                                                            <div className="payment-method">
                                                                                <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        id="credit2"
                                                                                        placeholder="Enter Credit & Debit Card Number"
                                                                                    />
                                                                                    <label htmlFor="credit2">
                                                                                        Enter Credit &amp; Debit Card Number
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xxl-4">
                                                                            <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="expiry"
                                                                                    placeholder="Enter Expiry Date"
                                                                                />
                                                                                <label htmlFor="expiry">Expiry Date</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xxl-4">
                                                                            <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="cvv"
                                                                                    placeholder="Enter CVV Number"
                                                                                />
                                                                                <label htmlFor="cvv">CVV Number</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xxl-4">
                                                                            <div className="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                <input
                                                                                    type="password"
                                                                                    className="form-control"
                                                                                    id="password"
                                                                                    placeholder="Enter Password"
                                                                                />
                                                                                <label htmlFor="password">Password</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="button-group mt-0">
                                                                            <ul>
                                                                                <li>
                                                                                    <button className="btn btn-light shopping-button">
                                                                                        Cancel
                                                                                    </button>
                                                                                </li>
                                                                                <li>
                                                                                    <button className="btn btn-animation">
                                                                                        Use This Card
                                                                                    </button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div
                                                                className="accordion-header"
                                                                id="flush-headingTwo"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#flush-collapseTwo"
                                                                >
                                                                    <div className="custom-form-check form-check mb-0">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="banking"
                                                                        >
                                                                            <input
                                                                                className="form-check-input mt-0"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="banking"
                                                                                value="banking"
                                                                                onChange={handlePaymentOptionChange}
                                                                            />
                                                                            Net Banking
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="flush-collapseTwo"
                                                                className="accordion-collapse collapse"
                                                                data-bs-parent="#accordionFlushExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <h5 className="text-uppercase mb-4">
                                                                        Select Your Bank
                                                                    </h5>
                                                                    <div className="row g-2">
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank1"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank1"
                                                                                >
                                                                                    Industrial &amp; Commercial Bank
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank2"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank2"
                                                                                >
                                                                                    Agricultural Bank
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank3"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank3"
                                                                                >
                                                                                    Bank of America
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank4"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank4"
                                                                                >
                                                                                    Construction Bank Corp.
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank5"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank5"
                                                                                >
                                                                                    HSBC Holdings
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="bank6"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="bank6"
                                                                                >
                                                                                    JPMorgan Chase &amp; Co.
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="select-option">
                                                                                <div className="form-floating theme-form-floating">
                                                                                    <select
                                                                                        className="form-select theme-form-select"
                                                                                        aria-label="Default select example"
                                                                                    >
                                                                                        <option value="hsbc">
                                                                                            HSBC Holdings
                                                                                        </option>
                                                                                        <option value="loyds">
                                                                                            Lloyds Banking Group
                                                                                        </option>
                                                                                        <option value="natwest">
                                                                                            Nat West Group
                                                                                        </option>
                                                                                        <option value="Barclays">
                                                                                            Barclays
                                                                                        </option>
                                                                                        <option value="other">
                                                                                            Others Bank
                                                                                        </option>
                                                                                    </select>
                                                                                    <label>Select Other Bank</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div
                                                                className="accordion-header"
                                                                id="flush-headingThree"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#flush-collapseThree"
                                                                >
                                                                    <div className="custom-form-check form-check mb-0">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="wallet"
                                                                        >
                                                                            <input
                                                                                className="form-check-input mt-0"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="wallet"
                                                                                value="wallet"
                                                                                onChange={handlePaymentOptionChange}

                                                                            />
                                                                            My Wallet
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="flush-collapseThree"
                                                                className="accordion-collapse collapse"
                                                                data-bs-parent="#accordionFlushExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    <h5 className="text-uppercase mb-4">
                                                                        Select Your Wallet
                                                                    </h5>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="amazon"
                                                                                >
                                                                                    <input
                                                                                        className="form-check-input mt-0"
                                                                                        type="radio"
                                                                                        name="flexRadioDefault"
                                                                                        id="amazon"
                                                                                    />
                                                                                    Amazon Pay
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="gpay"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="gpay"
                                                                                >
                                                                                    Google Pay
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="airtel"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="airtel"
                                                                                >
                                                                                    Airtel Money
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="paytm"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="paytm"
                                                                                >
                                                                                    Paytm Pay
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="jio"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="jio"
                                                                                >
                                                                                    JIO Money
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="custom-form-check form-check">
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="radio"
                                                                                    name="flexRadioDefault"
                                                                                    id="free"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor="free"
                                                                                >
                                                                                    Freecharge
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="right-side-summery-box">
                                <div className="summery-box-2">
                                    {shoppingCart.items.length != 0 ?
                                        <>
                                            <div className="summery-header">
                                                <h3>Order Summery</h3>
                                            </div>
                                            <ul className="summery-contain">
                                                {shoppingCart.items.map((data, index) => (
                                                    <>
                                                        <li>
                                                            <img
                                                                src={process.env.REACT_APP_API_BASE_URL +data.img_url}
                                                                className="img-fluid  lazyloaded checkout-image"
                                                                alt=""
                                                            />
                                                            <h4>
                                                                {data.name}<span>X {data.quantity}</span>
                                                            </h4>
                                                            <h4 className="price">₹ {data.quantity * data.product_price}</h4>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                            <ul className="summery-total">
                                                <li>
                                                    <h4>Subtotal</h4>
                                                    <h4 className="price">₹{SubTotal}</h4>
                                                </li>
                                                <li>
                                                    <h4>Shipping</h4>
                                                    <h4 className="price">₹100</h4>
                                                </li>
                                                <li>
                                                    <h4>Tax</h4>
                                                    <h4 className="price">₹10</h4>
                                                </li>
                                                <li>
                                                    <h4>Coupon/Code</h4>
                                                    <h4 className="price">₹-23.10</h4>
                                                </li>
                                                <li className="list-total">
                                                    <h4>Total (Rs)</h4>
                                                    <h4 className="price">₹{SubTotal + 100 + 10 - 23.10}</h4>
                                                </li>
                                            </ul>
                                        </>
                                        :
                                        <div className="empty-cart-container">
                                            <h3>Your cart is empty</h3>
                                        </div>
                                    }
                                </div>
                                <div className="checkout-offer">
                                    <div className="offer-title">
                                        <div className="offer-icon">
                                            <img
                                                src="../assets/images/inner-page/offer.svg"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="offer-name">
                                            <h6>Available Offers</h6>
                                        </div>
                                    </div>
                                    <ul className="offer-detail">
                                        <li>
                                            <p>
                                                Combo: BB Royal Almond/Badam Californian, Extra Bold 100
                                                gm...
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                combo: Royal Cashew Californian, Extra Bold 100 gm + BB
                                                Royal Honey 500 gm
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                {shoppingCart.items.length != 0 && <button className="btn theme-bg-color text-white btn-md w-100 mt-4 fw-bold"
                                    onClick={() => { checkOutOrder() }}
                                >
                                    Place Order
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Checkout section End */}
        </>

    )
}

export default Checkout;