const Invoice = () => {
    return (
        <>
            <section className="theme-invoice-2">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-8 mx-auto my-3">
                            <div className="invoice-wrapper">
                                <div className="invoice-header">
                                    <div className="header-contain">
                                        <div className="header-left">
                                            <img src="assets/images/logo.svg" alt="" />
                                        </div>
                                        <div className="header-right">
                                            <h3>INVOICE</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-body">
                                    <div className="top-sec">
                                        <div className="address-detail">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="details-box">
                                                        <div className="address-box">
                                                            <ul>
                                                                <li>2345 Tail Ends Road,</li>
                                                                <li>ORADELL, New Jersey</li>
                                                                <li>NJ, 38740</li>
                                                            </ul>
                                                        </div>
                                                        <div className="address-box">
                                                            <ul>
                                                                <li className="theme-color">
                                                                    Issue Date :{" "}
                                                                    <span className="text-content">20 March, 2022</span>
                                                                </li>
                                                                <li className="theme-color">
                                                                    Invoice No :{" "}
                                                                    <span className="text-content">904679</span>
                                                                </li>
                                                                <li className="theme-color">
                                                                    Email :{" "}
                                                                    <span className="text-content">user@gmail.com</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="body-date">
                                        <ul>
                                            <li className="text-content">
                                                <span className="theme-color">Issue Date : </span> 26 March,
                                                2022
                                            </li>
                                            <li className="text-content">
                                                <span className="theme-color">Invoice No : </span> 378407
                                            </li>
                                            <li className="text-content">
                                                <span className="theme-color">Email : </span> User@gmail.com
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="table-responsive table-borderless">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th className="text-start">Item detail</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                    <th>Amout</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-content">1</td>
                                                    <td>
                                                        <ul className="table-details">
                                                            <li className="text-title">
                                                                Meatigo Premium Goat Curry
                                                            </li>
                                                            <li className="text-content">
                                                                Lorem ipsum dolor sit, amet, consectetur adipisicing
                                                                elit.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>17</td>
                                                    <td>₹7.00</td>
                                                    <td>₹116.00</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-content">2</td>
                                                    <td>
                                                        <ul className="table-details">
                                                            <li className="text-title">
                                                                Meatigo Premium Goat Curry
                                                            </li>
                                                            <li className="text-content">
                                                                Lorem ipsum dolor sit, amet, consectetur adipisicing
                                                                elit.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>25</td>
                                                    <td>₹60.00</td>
                                                    <td>₹156.00</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-content">3</td>
                                                    <td>
                                                        <ul className="table-details">
                                                            <li className="text-title">
                                                                Meatigo Premium Goat Curry
                                                            </li>
                                                            <li className="text-content">
                                                                Lorem ipsum dolor sit, amet, consectetur adipisicing
                                                                elit.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>18</td>
                                                    <td>₹10.00</td>
                                                    <td>₹144.00</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-content">4</td>
                                                    <td>
                                                        <ul className="table-details">
                                                            <li className="text-title">
                                                                Meatigo Premium Goat Curry
                                                            </li>
                                                            <li className="text-content">
                                                                Lorem ipsum dolor sit, amet, consectetur adipisicing
                                                                elit.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>60</td>
                                                    <td>₹06.00</td>
                                                    <td>₹345.00</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-content">5</td>
                                                    <td>
                                                        <ul className="table-details">
                                                            <li className="text-title">
                                                                Meatigo Premium Goat Curry
                                                            </li>
                                                            <li className="text-content">
                                                                Lorem ipsum dolor sit, amet, consectetur adipisicing
                                                                elit.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>17</td>
                                                    <td>₹7.00</td>
                                                    <td>₹116.00</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={5}>
                                                        <ul className="table-price">
                                                            <li>GRAND TOTAL</li>
                                                            <li className="theme-color">₹325.00</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="invoice-footer pt-0">
                                    <div className="button-group">
                                        <ul>
                                            <li>
                                                <button
                                                    className="btn theme-bg-color text-white rounded"
                                                    onclick="window.print();"
                                                >
                                                    Export As PDF
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn text-white print-button rounded ms-2"
                                                    onclick="window.print();"
                                                >
                                                    Print
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Invoice;