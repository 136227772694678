import { Link, useLocation, useNavigate } from 'react-router-dom';
import { apiService } from '../../constants/ApiService';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { addItem, removeItem } from '../../reducetool/features/cardSlice';



import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';






const ProductDetail = () => {


  const location = useLocation();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({});
  const [multipleItemimages, setMultipleItemimages] = useState([]);
  const dispatch = useDispatch();
  const shoppingCart = useSelector(state => state.cardState);
  const [starValue, setStarValue] = useState(0);
  const [productReview, setProductReview] = useState("")
  const authData = useSelector(state => state.authState);
  const [productReviewList, setProductReviewList] = useState([])
  const [reviewErrorMessage, setReviewErrorMessage] = useState({})
  const [relatedProductList, setRelatedProductList] = useState([])

  const getItemData = async (productId) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json', // Important for file upload//s
          'accept': 'application/json'
        }
      };

      const response = await apiService.get(`/api/ProductMaster/productDetail?productId=${productId}`, config)
      if (response.response && response.response.data.errors) {
        alert(response.response?.data?.errors)
      }

      if (response.status === true) {
        setItemData(response.productMasterDataByID)
        setMultipleItemimages(response.productMasterDataByID.productMultipleImageTransMaster ? response.productMasterDataByID.productMultipleImageTransMaster : [])
        setProductReviewList(response.productMasterDataByID.reviewMaster ? response.productMasterDataByID.reviewMaster : [])
      }
      else {
        alert(response.message);
      }
    } catch (error) {
      console.log(error)
      alert('SomeThing went wrong!!');
    }
  };

  const getRelatedProduct = async (subcategoryName, productId) => {

    try {

      let config = {
        headers: {
          'Content-Type': 'application/json', // Important for file upload//s
          'accept': 'application/json'
        }
      };

      const response = await apiService.get(`/api/ProductMaster/relatedProduct?subcategoryName=${subcategoryName}&productId=${productId}`, config)
      if (response.response && response.response.data.errors) {
        alert(response.response?.data?.errors)
      }

      if (response.status === true) {
        // alert(response.message);
        setRelatedProductList(response.productMasterData)

      }
      else {
        alert(response.message);
      }
    } catch (error) {
      console.log(error)
      alert('SomeThing went wrong!!');
    }
  }

  useEffect(() => {
    if (location.state) {
      getItemData(location.state.productId)
      getRelatedProduct(location.state.subcategoryName, location.state.productId)

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [location.state]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }, []);

  const totalQty = shoppingCart ? (shoppingCart.items.find(product => product.id === itemData.id)?.productQty || 0) : 0;


  const handleNavigration = async () => {
    if (totalQty == 0) {
      await dispatch(addItem(itemData));
    }
    navigate("/Cart")
  }

  const validation = () => {
    let errorMessage = {};

    if (starValue == 0) {
      errorMessage.starValue = 'Please select a rating.';
    }

    if (!productReview) {
      errorMessage.productReview = 'Please write the review.';
    }

    setReviewErrorMessage(errorMessage);
    const isValid = Object.keys(errorMessage).length === 0;
    return isValid;
  };

  const reviewSubmit = async () => {
    try {

      const isValid = await validation();
      if (!isValid) {
        return
      }

      let config = {
        headers: {
          'Content-Type': 'application/json', // Important for file upload//s
          'accept': 'application/json'
        }
      };

      let params = {
        rating_stars: starValue,
        user_id: authData.user_id,
        product_id: itemData.id,
        product_review: productReview,
      }

      const response = await apiService.post(`/api/ReviewMaster/create`, params, config)
      if (response.response && response.response.data.errors) {
        alert(response.response?.data?.errors)
      }

      if (response.status === true) {
        alert(response.message);
        setStarValue("")
        setProductReview("")
        getItemData(location.state.productId)
      }
      else {
        alert(response.message);
      }
    } catch (error) {
      console.log(error)
      alert('SomeThing went wrong!!');
    }
  }

  const calculateAverageRating = (reviewMaster) => {

    if (!reviewMaster || reviewMaster.length === 0) {
      return 0;
    }

    const ratings = reviewMaster.map(review => review.rating_stars);
    const filteredRatings = ratings.filter(rating => rating != null);

    if (filteredRatings.length === 0) {
      return 0; // Return 0 if there are no valid ratings
    }

    const sum = filteredRatings.reduce((total, rating) => total + rating, 0);
    const averageRating = sum / filteredRatings.length;

    let roundedRating = Math.floor(averageRating); // Round down by default
    if (averageRating - Math.floor(averageRating) >= 0.5) {
      roundedRating++;
    }

    return roundedRating;
  };

  let averageRating = calculateAverageRating(productReviewList)

  const hanldeProductDetail = (productId) => {
    let selectedItem = relatedProductList.find(item => item.id === productId);
    navigate("/ProductDetail", { state: { productId: selectedItem.id, subcategoryName: selectedItem.product_sub_category } });
  }


  return (
    <>
      {/* Breadcrumb Section Start */}
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Product Detail Page</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="fa-solid fa-house" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Product Detail Page
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End */}

      {/* Product Left Sidebar Start */}
      <section className="product-section">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 wow fadeInUp">
              <div className="row g-4">

                <div className="col-xl-6 wow fadeInUp">
                  <div className="product-left-box">
                    <div className="row g-2">
            
  {/* <div className="col-xxl-10 col-lg-12 col-md-10 order-xxl-2 order-lg-1 order-md-2">
    <div className="product-main-2 no-arrow">
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/1.jpg"
            id="img-1"
            data-zoom-image="../assets/images/product/category/1.jpg"
            className="img-fluid image_zoom_cls-0 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/2.jpg"
            data-zoom-image="../assets/images/product/category/2.jpg"
            className="img-fluid image_zoom_cls-1 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/3.jpg"
            data-zoom-image="../assets/images/product/category/3.jpg"
            className="img-fluid image_zoom_cls-2 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/4.jpg"
            data-zoom-image="../assets/images/product/category/4.jpg"
            className="img-fluid image_zoom_cls-3 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/5.jpg"
            data-zoom-image="../assets/images/product/category/5.jpg"
            className="img-fluid image_zoom_cls-4 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="slider-image">
          <img
            src="../assets/images/product/category/6.jpg"
            data-zoom-image="../assets/images/product/category/6.jpg"
            className="img-fluid image_zoom_cls-5 blur-up lazyload"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div className="col-xxl-2 col-lg-12 col-md-2 order-xxl-1 order-lg-2 order-md-1">
    <div className="left-slider-image-2 left-slider no-arrow slick-top">
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/1.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/2.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/3.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/4.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/5.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
      <div>
        <div className="sidebar-image">
          <img
            src="../assets/images/product/category/6.jpg"
            className="img-fluid blur-up lazyload"
            alt=""
          />
        </div>
      </div>
    </div>
  </div> */}


                  
                      
             

                      {/* // show multiple image  */}

                      <div className="col-xxl-12 col-lg-12 col-md-2 order-xxl-1 order-lg-2 order-md-1">
                        <div className="left-slider-image-2 left-slider no-arrow slick-top">
                          <img
                            style={{ width: 350, height: 350, margin: 2 }}
                            src={process.env.REACT_APP_API_BASE_URL +itemData.img_url}
                            className="img-fluid  lazyload"
                            alt=""
                          />
                          {/* {multipleItemimages.map((imagePath, index) => (
                            <div>
                              <div className="sidebar-image">
                                <img
                                  style={{ width: 420, height: 420, margin: 2 }}
                                  src={imagePath.img_url}
                                  className="img-fluid  lazyload"
                                  alt=""
                                />
                              </div>
                            </div>
                          ))}*/}


                        </div>
                      </div>   



                    </div>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="right-box-contain">
                    <h6 className="offer-top">Special Discount</h6>
                    <h2 className="name">{itemData.product_title}</h2>
                    <div className="price-rating">
                      <h3 className="theme-color price">
                        Rs {itemData.product_price}<del className="text-content"> Rs {itemData.product_del_price}</del>
                        {/* <span className="offer theme-color">(8% off)</span> */}
                      </h3>

                      {/* <div className="product-rating custom-rate">
                        <ul className="rating">
                          <li>
                            <i data-feather="star" className="fill" />
                          </li>
                          <li>
                            <i data-feather="star" className="fill" />
                          </li>
                          <li>
                            <i data-feather="star" className="fill" />
                          </li>
                          <li>
                            <i data-feather="star" className="fill" />
                          </li>
                          <li>
                            <i data-feather="star" />
                          </li>
                        </ul>
                        <span className="review">23 Customer Review</span>
                      </div> */}
                    </div>
                    <div className="procuct-contain">
                      <p>
                        {itemData.product_description}
                      </p>
                    </div>
                    {/* <div className="product-packege">
                      <div className="product-title">
                        <h4>Weight</h4>
                      </div>
                      <ul className="select-packege">
                        <li>
                          <a href="javascript:void(0)" className="active">
                            1/2 KG
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">1 KG</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">1.5 KG</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Red Roses</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">With Pink Roses</a>
                        </li>
                      </ul>
                    </div> */}
                    <div
                      className="time deal-timer product-deal-timer mx-md-0 mx-auto"
                      id="clockdiv-1"
                      data-hours={1}
                      data-minutes={2}
                      data-seconds={3}
                    >
                      <div className="product-title">
                        <h4>Hurry up! Sale would End Soon</h4>
                      </div>
                     
                    </div>
                    <div className="note-box product-packege">
                      <div className="cart_qty qty-box product-qty">
                        <div className="input-group">
                          {/* <button
                            type="button"
                            className="qty-right-plus"
                            data-type="plus"
                            data-field=""
                            onClick={() => { handleAddToCart() }}
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                          </button>
                          <input
                            className="form-control input-number qty-input"
                            type="text"
                            name="quantity"
                            value={totalQty}
                          />
                          <button
                            type="button"
                            className="qty-left-minus"
                            data-type="minus"
                            data-field=""
                            onClick={() => { handleRemoveFromCart() }}
                          >
                            <i className="fa fa-minus" aria-hidden="true" />
                          </button> */}
                        </div>
                      </div>
                      <button
                        type='button'
                        onClick={() => handleNavigration()}
                        className="btn btn-md bg-dark cart-button text-white w-100"
                      >
                        Add To Cart
                      </button>
                    </div>
                    {/* <div className="buy-box">
                      <a href="wishlist.html">
                        <i data-feather="heart" />
                        <span>Add To Wishlist</span>
                      </a>
                      <a href="compare.html">
                        <i data-feather="shuffle" />
                        <span>Add To Compare</span>
                      </a>
                    </div>
                    <div className="pickup-box">
                      <div className="product-title">
                        <h4>Store Information</h4>
                      </div>
                      <div className="pickup-detail">
                        <h4 className="text-content">
                          Lollipop cake chocolate chocolate cake dessert jujubes.
                          Shortbread sugar plum dessert powder cookie sweet brownie.
                        </h4>
                      </div>
                      <div className="product-info">
                        <ul className="product-info-list product-info-list-2">
                          <li>
                            Type : <a href="javascript:void(0)">Black Forest</a>
                          </li>
                          <li>
                            SKU : <a href="javascript:void(0)">SDFVW65467</a>
                          </li>
                          <li>
                            MFG : <a href="javascript:void(0)">Jun 4, 2022</a>
                          </li>
                          <li>
                            Stock : <a href="javascript:void(0)">2 Items Left</a>
                          </li>
                          <li>
                            Tags : <a href="javascript:void(0)">Cake,</a>{" "}
                            <a href="javascript:void(0)">Backery</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="paymnet-option">
                      <div className="product-title">
                        <h4>Guaranteed Safe Checkout</h4>
                      </div>
                      <ul>
                        <li>
                          <a href="javascript:void(0)">
                            <img
                              src="../assets/images/product/payment/1.svg"
                              className=" lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <img
                              src="../assets/images/product/payment/2.svg"
                              className=" lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <img
                              src="../assets/images/product/payment/3.svg"
                              className=" lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <img
                              src="../assets/images/product/payment/4.svg"
                              className=" lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <img
                              src="../assets/images/product/payment/5.svg"
                              className=" lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="product-section-box">
                    <ul
                      className="nav nav-tabs custom-nav"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          Description
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="info-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#info"
                          type="button"
                          role="tab"
                          aria-controls="info"
                          aria-selected="false"
                        >
                          Additional info
                        </button>
                      </li> */}
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="care-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#care"
                          type="button"
                          role="tab"
                          aria-controls="care"
                          aria-selected="false"
                        >
                          Care Instuctions
                        </button>
                      </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="review-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#review"
                          type="button"
                          role="tab"
                          aria-controls="review"
                          aria-selected="false"
                        >
                          Review
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content custom-tab" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="product-description">
                          <div className="nav-desh">
                            {/* <textarea style={{width:1230}}>
                              {itemData.product_content}
                            </textarea> */}
                            <div dangerouslySetInnerHTML={{ __html: itemData.product_content }}className='editor'></div>
                             {/* <p dangerouslySetInnerHTML={{ __html: itemData.product_content }} ></p> */}
                            {/* {itemData.product_content} */}


                            {/* <pre>
                              <code dangerouslySetInnerHTML={{ __html: itemData.product_content }} />
                            </pre> */}
                          </div>

                        </div>
                      </div>


                      
                      <div
                        className="tab-pane fade"
                        id="review"
                        role="tabpanel"
                        aria-labelledby="review-tab"
                      >
                        <div className="review-box">
                          <div className="row g-4">
                            <div className="col-xl-6">
                              <div className="review-title">
                                <h4 className="fw-500">Customer reviews</h4>
                              </div>

                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Box
                                  sx={{
                                    '& > legend': { mt: 2 },
                                  }}
                                >
                                  <Rating name="read-only" value={averageRating} readOnly />
                                </Box>
                                <h6 className="ms-3">{productReviewList.length} Out Of 5</h6>

                              </div>
                              {/* <div className="rating-box">
                                <ul>
                                  <li>
                                    <div className="rating-list">
                                      <h5>5 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "68%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          68%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>4 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "67%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          67%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>3 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "42%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          42%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>2 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "30%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          30%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="rating-list">
                                      <h5>1 Star</h5>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "24%" }}
                                          aria-valuenow={100}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        >
                                          24%
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div> */}
                            </div>

                            <div className="col-12">
                              <div className="review-title">
                                <h4 className="fw-500">
                                  Customer questions &amp; answers
                                </h4>
                              </div>
                              <div className="review-people">
                                <ul className="review-list">
                                  {
                                    productReviewList.length>0 ?
                                  productReviewList.map((data) => (
                                    <li>
                                      <div className="people-box">
                                        {/* <div>
                                          <div className="people-image">
                                            <img
                                              src="../assets/images/review/1.jpg"
                                              className="img-fluid  lazyload"
                                              alt=""
                                            />
                                          </div>
                                        </div> */}
                                        <div className="people-comment">
                                          <a
                                            className="name"
                                            href="javascript:void(0)"
                                          >
                                            {data.user_Detail.user_name}
                                          </a>
                                          <div className="date-time">

                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                              <Box
                                                sx={{
                                                  '& > legend': { mt: 2 },
                                                }}
                                              >
                                                <Rating name="read-only" value={data.rating_stars} readOnly />
                                              </Box>

                                            </div>
                                            <h6 className="text-content">

                                              <td>{data.created_on ? new Date(data.created_on).toLocaleString() : ""}</td>

                                            </h6>
                                          </div>
                                          <div className="reply">
                                            <p>
                                              {data.product_review}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )):<p>No reviews to show</p>}
                                  {/* <li>
                                    <div className="people-box">
                                      <div>
                                        <div className="people-image">
                                          <img
                                            src="../assets/images/review/2.jpg"
                                            className="img-fluid  lazyload"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="people-comment">
                                        <a
                                          className="name"
                                          href="javascript:void(0)"
                                        >
                                          Olivia
                                        </a>
                                        <div className="date-time">
                                          <h6 className="text-content">
                                            01 May, 2022 at 08.31 AM
                                          </h6>
                                          <div className="product-rating">
                                            <ul className="rating">
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i data-feather="star" />
                                              </li>
                                              <li>
                                                <i data-feather="star" />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="reply">
                                          <p>
                                            Tootsie roll cake danish halvah powder
                                            Tootsie roll candy marshmallow cookie
                                            brownie apple pie pudding brownie
                                            chocolate bar. Jujubes gummi bears I
                                            love powder danish oat cake tart
                                            croissant.
                                            <a href="javascript:void(0)">Reply</a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="people-box">
                                      <div>
                                        <div className="people-image">
                                          <img
                                            src="../assets/images/review/3.jpg"
                                            className="img-fluid  lazyload"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="people-comment">
                                        <a
                                          className="name"
                                          href="javascript:void(0)"
                                        >
                                          Gabrielle
                                        </a>
                                        <div className="date-time">
                                          <h6 className="text-content">
                                            21 May, 2022 at 05.52 PM
                                          </h6>
                                          <div className="product-rating">
                                            <ul className="rating">
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i
                                                  data-feather="star"
                                                  className="fill"
                                                />
                                              </li>
                                              <li>
                                                <i data-feather="star" />
                                              </li>
                                              <li>
                                                <i data-feather="star" />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="reply">
                                          <p>
                                            Biscuit chupa chups gummies powder I
                                            love sweet pudding jelly beans. Lemon
                                            drops marzipan apple pie gingerbread
                                            macaroon croissant cotton candy pastry
                                            wafer. Carrot cake halvah I love tart
                                            caramels pudding icing chocolate gummi
                                            bears. Gummi bears danish cotton candy
                                            muffin marzipan caramels awesome feel.{" "}
                                            <a href="javascript:void(0)">Reply</a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                            {authData.login ? <div className="col-xl-6">
                              <div className="review-title">
                                <h4 className="fw-500">Add a review</h4>
                              </div>
                              <div className="row g-4" style={{}}>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <Box
                                    sx={{
                                      '& > legend': { mt: 2 },
                                    }}
                                  >
                                    <Rating
                                      name="simple-controlled"
                                      value={starValue}
                                      onChange={(event, newValue) => {
                                        setStarValue(newValue);
                                      }}
                                    />
                                  </Box>
                                  {reviewErrorMessage.starValue && <div className="text-danger">{reviewErrorMessage.starValue}</div>}
                                </div>

                                <div className="col-12">
                                  <div className="form-floating theme-form-floating">
                                    <textarea
                                      className="form-control"
                                      placeholder="Leave a comment here"
                                      id="floatingTextarea2"
                                      style={{ height: 150, width: 680 }} // Set width to 100% for responsiveness
                                      value={productReview}
                                      onChange={(e) => setProductReview(e.target.value)}
                                    />
                                    <label htmlFor="floatingTextarea2">
                                      Write Your Comment
                                    </label>
                                  </div>
                                  {reviewErrorMessage.productReview && <div className="text-danger">{reviewErrorMessage.productReview}</div>}

                                  <button
                                    type='button'
                                    style={{ marginTop: 20 }}
                                    onClick={() => { reviewSubmit() }}
                                    className="btn btn-md bg-primary cart-button text-white w-50"
                                  >
                                    Submit
                                  </button>
                                </div>

                              </div>
                            </div> : <></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/* Product Left Sidebar End */}
      {/* Releted Product Section Start */}
      <section className="product-list-section section-b-space">
        <div className="container-fluid-lg">
          <div className="title">
            <h2>Related Products</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <use xlinkHref="../assets/svg/leaf.svg#leaf" />
              </svg>
            </span>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="slider-6_1 product-wrapper">


                {/* {relatedProductList.map((data) => (
                  <div>
                    <div
                      className="product-box-3 wow fadeInUp"
                      data-wow-delay="0.05s"

                    >
                      <div className="product-header">
                        <div className="product-image">
                          <a onClick={() => { hanldeProductDetail(data.id) }}>
                            <img
                              src={data.img_url}
                              className="img-fluid  lazyload"
                              alt=""
                            />
                          </a>

                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                '& > legend': { mt: 2 },
                              }}
                            >
                              <Rating name="read-only" value={calculateAverageRating(data?.reviewMaster)} readOnly />
                            </Box>
                          </div>
                        </div>
                      </div>
                      <div className="product-footer">
                        <div className="product-detail">
                          <span className="span-name">{data.product_sub_category}</span>
                          <a href="product-left-thumbnail.html">
                            <h5 className="name">
                              {data.product_title}
                            </h5>
                          </a>

                          {/* <h6 className="unit">250 ml</h6> */}
                {/* <h5 className="price">
                            <span className="theme-color">Rs{data.product_price}</span>{" "}
                            <del>Rs{data.product_del_price}</del>
                          </h5>
                          <div className="add-to-cart-box bg-white"> */}
                {/* <button className="btn btn-add-cart addcart-button">
                              Add
                              <span className="add-icon bg-light-gray">
                                <i className="fa-solid fa-plus" />
                              </span>
                            </button> */}
                {/* <div className="cart_qty qty-box">
                              <div className="input-group bg-white">
                                <button
                                  type="button"
                                  className="qty-left-minus bg-gray"
                                  data-type="minus"
                                  data-field=""
                                >
                                  <i className="fa fa-minus" aria-hidden="true" />
                                </button>
                                <input
                                  className="form-control input-number qty-input"
                                  type="text"
                                  name="quantity"
                                  defaultValue={0}
                                />
                                <button
                                  type="button"
                                  className="qty-right-plus bg-gray"
                                  data-type="plus"
                                  data-field=""
                                >
                                  <i className="fa fa-plus" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div> */}
                {/* </div>
                      </div>
                    </div>
                  </div>
                ))} */}

                <div className="section-b-space">
                  <div className="row row-cols-xxl-5 row-cols-md-4 row-cols-sm-3 row-cols-2 g-sm-4 g-3 no-arrow">

                    {
                      relatedProductList>0 ?
                    relatedProductList.map((data) => (
                      <div>
                        <div
                          className="product-box product-white-bg wow fadeIn"
                          style={{ visibility: "visible", animationName: "fadeIn" }}
                        >
                          <div className="product-image">
                            <a onClick={() => { hanldeProductDetail(data.id) }}>
                              <img
                                src={process.env.REACT_APP_API_BASE_URL +data.img_url}
                                className="img-fluid blur-up lazyloaded"
                                alt=""
                              />
                            </a>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box
                              sx={{
                                '& > legend': { mt: 2 },
                              }}
                            >
                              <Rating name="read-only" value={calculateAverageRating(data?.reviewMaster)} readOnly />
                            </Box>
                          </div>
                          <div className="product-detail">
                            <span className="span-name">{data.product_sub_category}</span>
                            <a href="product-left-thumbnail.html">
                              <h5 className="name" style={{ fontWeight: "bold" }}>
                                {data.product_title}
                              </h5>
                            </a>

                            {/* <h6 className="unit">250 ml</h6> */}
                            <h5 className="price">
                              <span className="theme-color" >Rs{data.product_price}</span>{" "}
                              <del>Rs{data.product_del_price}</del>
                            </h5>
                          </div>

                          {/* <div className="add-to-cart-box theme-color">
                            <button className="btn btn-add-cart addcart-button text-light theme-bg-color" onClick={() => handleNavigration(product.id)} >
                              Add to Cart
                            </button>
                          </div> */}
                        </div>
                      </div>
                    )):<p>No related Products to show</p>}
                  </div>
                </div>




                {/* <div>
                  <div className="product-box-3 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="product-header">
                      <div className="product-image">
                        <a href="product-left-thumbnail.html">
                          <img
                            src="../assets/images/cake/product/3.png"
                            className="img-fluid  lazyload"
                            alt=""
                          />
                        </a>
                        <ul className="product-option">
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-toggle="modal"
                              data-bs-target="#view"
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Compare"
                          >
                            <a href="compare.html">
                              <i data-feather="refresh-cw" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Wishlist"
                          >
                            <a href="wishlist.html" className="notifi-wishlist">
                              <i data-feather="heart" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">Vegetable</span>
                        <a href="product-left-thumbnail.html">
                          <h5 className="name">
                            Peanut Butter Bite Premium Butter Cookies 600 g
                          </h5>
                        </a>
                        <div className="product-rating mt-2">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                          </ul>
                          <span>(2.4)</span>
                        </div>
                        <h6 className="unit">350 G</h6>
                        <h5 className="price">
                          <span className="theme-color">$04.33</span>{" "}
                          <del>$10.36</del>
                        </h5>
                        <div className="add-to-cart-box bg-white">
                          <button className="btn btn-add-cart addcart-button">
                            Add
                            <span className="add-icon bg-light-gray">
                              <i className="fa-solid fa-plus" />
                            </span>
                          </button>
                          <div className="cart_qty qty-box">
                            <div className="input-group bg-white">
                              <button
                                type="button"
                                className="qty-left-minus bg-gray"
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-minus" aria-hidden="true" />
                              </button>
                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                              />
                              <button
                                type="button"
                                className="qty-right-plus bg-gray"
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="product-box-3 wow fadeInUp"
                    data-wow-delay="0.15s"
                  >
                    <div className="product-header">
                      <div className="product-image">
                        <a href="product-left-thumbnail.html">
                          <img
                            src="../assets/images/cake/product/4.png"
                            className="img-fluid  lazyload"
                            alt=""
                          />
                        </a>
                        <ul className="product-option">
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-toggle="modal"
                              data-bs-target="#view"
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Compare"
                          >
                            <a href="compare.html">
                              <i data-feather="refresh-cw" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Wishlist"
                          >
                            <a href="wishlist.html" className="notifi-wishlist">
                              <i data-feather="heart" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">Snacks</span>
                        <a href="product-left-thumbnail.html">
                          <h5 className="name">
                            SnackAmor Combo Pack of Jowar Stick and Jowar Chips
                          </h5>
                        </a>
                        <div className="product-rating mt-2">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                          </ul>
                          <span>(5.0)</span>
                        </div>
                        <h6 className="unit">570 G</h6>
                        <h5 className="price">
                          <span className="theme-color">$12.52</span>{" "}
                          <del>$13.62</del>
                        </h5>
                        <div className="add-to-cart-box bg-white">
                          <button className="btn btn-add-cart addcart-button">
                            Add
                            <span className="add-icon bg-light-gray">
                              <i className="fa-solid fa-plus" />
                            </span>
                          </button>
                          <div className="cart_qty qty-box">
                            <div className="input-group bg-white">
                              <button
                                type="button"
                                className="qty-left-minus bg-gray"
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-minus" aria-hidden="true" />
                              </button>
                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                              />
                              <button
                                type="button"
                                className="qty-right-plus bg-gray"
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-box-3 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="product-header">
                      <div className="product-image">
                        <a href="product-left-thumbnail.html">
                          <img
                            src="../assets/images/cake/product/5.png"
                            className="img-fluid  lazyload"
                            alt=""
                          />
                        </a>
                        <ul className="product-option">
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-toggle="modal"
                              data-bs-target="#view"
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Compare"
                          >
                            <a href="compare.html">
                              <i data-feather="refresh-cw" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Wishlist"
                          >
                            <a href="wishlist.html" className="notifi-wishlist">
                              <i data-feather="heart" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">Snacks</span>
                        <a href="product-left-thumbnail.html">
                          <h5 className="name">
                            Yumitos Chilli Sprinkled Potato Chips 100 g
                          </h5>
                        </a>
                        <div className="product-rating mt-2">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                          </ul>
                          <span>(3.8)</span>
                        </div>
                        <h6 className="unit">100 G</h6>
                        <h5 className="price">
                          <span className="theme-color">$10.25</span>{" "}
                          <del>$12.36</del>
                        </h5>
                        <div className="add-to-cart-box bg-white">
                          <button className="btn btn-add-cart addcart-button">
                            Add
                            <span className="add-icon bg-light-gray">
                              <i className="fa-solid fa-plus" />
                            </span>
                          </button>
                          <div className="cart_qty qty-box">
                            <div className="input-group bg-white">
                              <button
                                type="button"
                                className="qty-left-minus bg-gray"
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-minus" aria-hidden="true" />
                              </button>
                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                              />
                              <button
                                type="button"
                                className="qty-right-plus bg-gray"
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="product-box-3 wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <div className="product-header">
                      <div className="product-image">
                        <a href="product-left-thumbnail.html">
                          <img
                            src="../assets/images/cake/product/6.png"
                            className="img-fluid  lazyload"
                            alt=""
                          />
                        </a>
                        <ul className="product-option">
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-toggle="modal"
                              data-bs-target="#view"
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Compare"
                          >
                            <a href="compare.html">
                              <i data-feather="refresh-cw" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Wishlist"
                          >
                            <a href="wishlist.html" className="notifi-wishlist">
                              <i data-feather="heart" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">Vegetable</span>
                        <a href="product-left-thumbnail.html">
                          <h5 className="name">
                            Fantasy Crunchy Choco Chip Cookies
                          </h5>
                        </a>
                        <div className="product-rating mt-2">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                          </ul>
                          <span>(4.0)</span>
                        </div>
                        <h6 className="unit">550 G</h6>
                        <h5 className="price">
                          <span className="theme-color">$14.25</span>{" "}
                          <del>$16.57</del>
                        </h5>
                        <div className="add-to-cart-box bg-white">
                          <button className="btn btn-add-cart addcart-button">
                            Add
                            <span className="add-icon bg-light-gray">
                              <i className="fa-solid fa-plus" />
                            </span>
                          </button>
                          <div className="cart_qty qty-box">
                            <div className="input-group bg-white">
                              <button
                                type="button"
                                className="qty-left-minus bg-gray"
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-minus" aria-hidden="true" />
                              </button>
                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                              />
                              <button
                                type="button"
                                className="qty-right-plus bg-gray"
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-box-3 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="product-header">
                      <div className="product-image">
                        <a href="product-left-thumbnail.html">
                          <img
                            src="../assets/images/cake/product/7.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                        <ul className="product-option">
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View"
                          >
                            <a
                              href="javascript:void(0)"
                              data-bs-toggle="modal"
                              data-bs-target="#view"
                            >
                              <i data-feather="eye" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Compare"
                          >
                            <a href="compare.html">
                              <i data-feather="refresh-cw" />
                            </a>
                          </li>
                          <li
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Wishlist"
                          >
                            <a href="wishlist.html" className="notifi-wishlist">
                              <i data-feather="heart" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">Vegetable</span>
                        <a href="product-left-thumbnail.html">
                          <h5 className="name">
                            Fresh Bread and Pastry Flour 200 g
                          </h5>
                        </a>
                        <div className="product-rating mt-2">
                          <ul className="rating">
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" className="fill" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                            <li>
                              <i data-feather="star" />
                            </li>
                          </ul>
                          <span>(3.8)</span>
                        </div>
                        <h6 className="unit">1 Kg</h6>
                        <h5 className="price">
                          <span className="theme-color">$12.68</span>{" "}
                          <del>$14.69</del>
                        </h5>
                        <div className="add-to-cart-box bg-white">
                          <button className="btn btn-add-cart addcart-button">
                            Add
                            <span className="add-icon bg-light-gray">
                              <i className="fa-solid fa-plus" />
                            </span>
                          </button>
                          <div className="cart_qty qty-box">
                            <div className="input-group bg-white">
                              <button
                                type="button"
                                className="qty-left-minus bg-gray"
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fa fa-minus" aria-hidden="true" />
                              </button>
                              <input
                                className="form-control input-number qty-input"
                                type="text"
                                name="quantity"
                                defaultValue={0}
                              />
                              <button
                                type="button"
                                className="qty-right-plus bg-gray"
                                data-type="plus"
                                data-field=""
                              >
                                <i className="fa fa-plus" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Releted Product Section End */}


    </>
  )
}

export default ProductDetail;