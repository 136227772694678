import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducetool/store';

import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';

// useEffect(() => {
//   window.scrollTo({
//     top: 0,
//     behavior: 'smooth' // This enables smooth scrolling
//   });
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>

      </PersistGate>
    </Provider>,
  </React.StrictMode >
);
