import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { apiService } from '../../constants/ApiService';



const OrderTracking = () => {

    const navigate = useNavigate();
    const [orderDetail, setOrderDetail] = useState("")
    const location = useLocation();
    const [processingStatus, setProcessingStatus] = useState(false)
    const [shippingStatus, setShippingStatus] = useState(false)
    const [deliveredStatus, setDeliveredStatus] = useState(false)


    const getOrderDetail = async (orderId) => {

        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/OrderMaster/orderDetailByOrderId?orderId=${orderId}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setOrderDetail(response.orderMasterDataByID)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }

    useEffect(() => {
        if (location.state) {
            getOrderDetail(location.state.orderId)
        }
    }, [location.state]);

    const updateOrderStatus = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/OrderMaster/orderDetailByOrderId?orderId=${1}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setOrderDetail(response.orderMasterDataByID)
            }
            else {
                alert(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    }
   
    const calculated = () => {

        if (orderDetail.created_on) {

            const specifiedDate = new Date(orderDetail.created_on);
            let currentDate = new Date();
            // currentDate.setHours(currentDate.getHours() + 6);

            const sixHoursLaterSpecifiedDate = new Date(specifiedDate.getTime() + (6 * 60 * 60 * 1000));  // Adding 6 hours
            const pendingStatus = currentDate.getTime() > sixHoursLaterSpecifiedDate.getTime()
            setProcessingStatus(pendingStatus)
           
            const twelveHoursLaterSpecifiedDate = new Date(specifiedDate.getTime() + (12 * 60 * 60 * 1000)); // Adding 12 hours
            let shippingStatus = currentDate.getTime() > twelveHoursLaterSpecifiedDate.getTime();
            setShippingStatus(shippingStatus)

            const eighteenHoursLaterSpecifiedDate = new Date(specifiedDate.getTime() + (18 * 60 * 60 * 1000)); // Adding 18 hours
            let DeliveredStatus = currentDate.getTime() > eighteenHoursLaterSpecifiedDate.getTime();
            setDeliveredStatus(DeliveredStatus)

        }

    }

    useEffect(()=>{
        calculated()
    },[orderDetail.created_on])


    const goToReturnable = (orderItemId,product_to_be_returned_by)=>{
        navigate("/return", { state: { orderItemId: orderItemId , product_to_be_returned_by:product_to_be_returned_by } });
    }

    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>Order Tracking</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Order Tracking
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* Order Detail Section Start */}
            <section className="order-detail">
                <div className="container-fluid-lg">
                    <div className="row g-sm-4 g-3" style={{ justifyContent: "center" }}>
                        {/* <div className="col-xxl-3 col-xl-4 col-lg-6">
                            <div className="order-image">
                                <img
                                    src="../assets/images/vegetable/product/6.png"
                                    className="img-fluid  lazyload"
                                    alt=""
                                />
                            </div>
                        </div> */}
                        <div className="col-xxl-9 col-xl-8 col-lg-6">
                            <div className="row g-sm-4 g-3">
                                <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i data-feather="package" className="text-content" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Order Number</h5>
                                            <h2 className="theme-color">{orderDetail.order_number}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i data-feather="package" className="text-content" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Total Amount</h5>
                                            <h2 className="theme-color">{orderDetail.grand_total}</h2>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i data-feather="package" className="text-content" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Status</h5>
                                            <h2 className="theme-color"><button type="button" className="btn btn-md bg-danger cart-button text-white" >Canecel</button></h2>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i data-feather="truck" className="text-content" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Total Amount</h5>
                                            <img
                                                src="../assets/images/inner-page/brand-name.svg"
                                                className="img-fluid  lazyload"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i className="text-content" data-feather="info" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Package Info</h5>
                                            <h4>Letter</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i className="text-content" data-feather="crosshair" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">From</h5>
                                            <h4>STR. Smardan 9, Bucuresti, romania.</h4>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i className="text-content" data-feather="map-pin" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Destination</h5>
                                            <h4>Flokagata 24, 105 Reykjavik, Iceland</h4>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-xl-4 col-sm-6">
                                    <div className="order-details-contain">
                                        <div className="order-tracking-icon">
                                            <i className="text-content" data-feather="calendar" />
                                        </div>
                                        <div className="order-details-name">
                                            <h5 className="text-content">Estimated Time</h5>
                                            <h4>7 Frb, 05:05pm</h4>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 overflow-hidden">
                                    <ol className="progtrckr">

                                        <li className="progtrckr-done">
                                            <h5>Order Pending</h5>
                                            {/* <h6>05:43 AM</h6> */}
                                        </li>

                                        <li className={processingStatus? "progtrckr-done" : "progtrckr-todo"}>
                                            <h5>Order Processing</h5>
                                            {/* <h6>05:43 AM</h6> */}
                                        </li>
                                        {/* <li className="progtrckr-done">
                                            <h5>Pre-Production</h5>
                                            <h6>01:21 PM</h6>
                                        </li> */}
                                        {/* <li className={setShippingStatus?"progtrckr-done":"progtrckr-todo"}>
                                            <h5>In Production</h5>
                                            <h6>Processing</h6>
                                        </li> */}
                                        <li className={shippingStatus== true ? "progtrckr-done" : "progtrckr-todo"}>
                                            <h5>Shipped</h5>
                                            <h6>Pending</h6>
                                        </li>
                                        <li className={orderDetail.order_delivery_done && deliveredStatus ? "progtrckr-done" : "progtrckr-todo"}>
                                            <h5>Delivered</h5>
                                            <h6>Pending</h6>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Order Detail Section End */}
            {/* Order Table Section Start */}
            <section className="order-table-section section-b-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table order-tab-table">
                                    <thead>
                                        <tr>
                                            <th>Order No</th>
                                            <th>image</th>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetail?.orderItemMasters?.map((subitem, index) => (
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td><img src={process.env.REACT_APP_API_BASE_URL +subitem.img_url} style={{width:100 , height:100}}></img></td>
                                                    <td>{subitem.product_title}</td>
                                                    <td>{subitem.product_price}</td>
                                                    <td>{subitem.quantity}</td>

                                                   { orderDetail.order_delivery_done  && <td>{subitem.product_returnable&&<button type="button" className="btn btn-md bg-warning cart-button text-white" onClick={()=>{goToReturnable(subitem.id , subitem.product_to_be_returned_by)}} >Return</button> }</td>}
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Order Table Section End */}
        </>

    )
}

export default OrderTracking;