import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { apiService } from '../../constants/ApiService';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { setToken } from '../../reducetool/features/authSlice';
import LoaderModal from '../../constants/LoaderModal';


const Login = () => {

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const isAuth = useSelector((state) => state.auth);


    useEffect(() => {
        if (isAuth === false) {
            navigate('/login');
        }
    }, [isAuth]);
    const initialValues = {
        identifier : "",
        //password : "",
        //recOtpOn : ""
    };

    const validationSchema = Yup.object().shape({
        identifier: Yup.string()
          .required('Email or Mobile number is required')
          .test(
            'identifier',
            function (value) {
              if (!value) return true; // Handled by required validation
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const mobileRegex = /^[0-9]{10}$/;

            //   if (/^\d+$/.test(value) && !mobileRegex.test(value)) { 
            //     return this.createError({message: 'Invalid mobile number'})
            //   }else{
            //     if(!emailRegex.test(value))
            //     {
            //         return this.createError({message: 'Invalid email address '})
            //     }
            //   }
              
              if (emailRegex.test(value)) return true;
              if (mobileRegex.test(value)) return true;
              
              return this.createError({
                message: /^\d+$/.test(value) && !mobileRegex.test(value)
                  ? 'Invalid mobile number'
                  : 'Invalid email address',
              });
            //   return this.createError({
            //     message: emailRegex.test(value)
            //       ? 'Invalid email address <i className="ri-error-warning-fill"></i>'
            //       : 'Invalid mobile number <i className="ri-error-warning-fill"></i>',
            //   });
            }
          ),
        //password: Yup.string()
          //.min(6, 'Password must be at least 6 characters long')
         // .required('Password is required')
      });
    const onSubmit = async (values) => {
        
        
        // Validation for user_email
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // if (!values.identifier) {
        //     setError("Please enter a email address or mobile number to login");
        //     return;
        // } 

        // if (/^\d+$/.test(values.identifier)) {
        //     console.log(values.identifier.length)
        //     if(values.identifier.length !== 10)
        //     {
        //         setError('Invalid mobile number');
        //         return;
        //     }
        // }else {
        //     if (!emailRegex.test(values.identifier)) {
        //         setError('Invalid Email Address');
        //         return;
        //     }
        // }

        setLoading(true);

        let params = {
            identifier: values.identifier,
            password: values.password,
            recOtpOn: ''
        }

        //debugger;

        const response = await apiService.post(`/api/admin/User_Detail/LoginWithOtp`, params);
        //console.log(response.message);
        setLoading(false)
        if (response.response && response.response.data.errors) {
            console.log(response.response?.data?.errors)
        }
        if (response.status === true) {
            //console.log(response);

            navigate("/otp",{state : { loginData : params, purpose:"Login"}})

        } else {
            setError(response.message)
        }


    };


    return (
        <>
            {/* Breadcrumb Section Start */}
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2 className="mb-2">Log In</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">Log In</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Breadcrumb Section End */}
            {/* log in section start */}
            <section className="log-in-section background-image-2 section-b-space">
                <div className="container-fluid-lg w-100">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
                            <div className="image-contain">
                                <img
                                    src="../assets/images/inner-page/log-in.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
                            <div className="log-in-box">
                                <div className="log-in-title">
                                    <h3>Welcome To SHIVKMS</h3>
                                    <h4>Log In Your Account</h4>
                                </div>
                                {error && <div className="text-danger mt-3" style={{ fontSize: 18, marginBottom: 20 }}>{error}</div>}
                                <div className="input-box">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    <Form className="row g-4" >
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="E-mail"
                                                    name="identifier"
                                                    required
                                                    />
                                                   
                                                <label htmlFor="email">Mobile no. or Email</label>
                                            </div>
                                            <ErrorMessage
                                                        name="identifier"
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                        </div>
                                        {/* <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="user_pass"
                                                    id="user_pass"
                                                    value={loginData.user_pass}
                                                    onChange={(e) => onChangeHandle(e)}
                                                />
                                                <label htmlFor="password">Password</label>
                                            </div>
                                            {errorLoginDetail.user_pass && <div className="text-danger">{errorLoginDetail.user_pass}</div>}

                                        </div> */}
                                        
                                        {/*<div className="col-12">
                                            <div className="forgot-box">
                                                <div className="form-check ps-0 m-0 remember-box">
                                                     <input
                                                        className="checkbox_animated check-box"
                                                        type="checkbox"
                                                        id="flexCheckDefault"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Remember me
                                                    </label> 
                                                </div>
                                                <a  onClick={()=>{ navigate("/Forget")}} className="forgot-password">
                                                    Forgot Password?
                                                </a>
                                            </div>
                                        </div>*/}
                                        <div className="col-12">
                                        {
                                                loading ? (
                                                    <LoaderModal show={loading} />
                                                ) : (
                                            <button
                                                className="btn btn-animation w-100 justify-content-center"
                                                type="submit"
                                                defaultValue="Submit"
                                            >
                                                Login
                                            </button>)
                                        }
                                        </div>
                                        </Form>
                                </Formik>
                                </div>
                                {/* <div className="other-log-in">
                                    <h6>or</h6>
                                </div>
                                <div className="log-in-button">
                                    <ul>
                                        <li>
                                            <a
                                               
                                                className="btn google-button w-100"
                                            >
                                                <img
                                                    src="../assets/images/inner-page/google.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />{" "}
                                                Log In with Google
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/"
                                                className="btn google-button w-100"
                                            >
                                                <img
                                                    src="../assets/images/inner-page/facebook.png"
                                                    className=" lazyload"
                                                    alt=""
                                                />{" "}
                                                Log In with Facebook
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="other-log-in">
                                    <h6 />
                                </div> */}
                                <div className="sign-up-box">
                                    <h4>Don't have an account?</h4>
                                    <a onClick={()=>{navigate("/Register")}} className="forgot-password">Click here to Sign Up Now!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* log in section end */}
        </>

    )
}

export default Login;