import React from "react";

const AddressModal = (props) => {
    return (
        <>
            <div
                className="modal fade theme-modal show"
                id="add-address"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingLeft: 0 }}
            >
                {/* //                                Add a new address */}

                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">

                                {props.isAddingAddress ? "Add a new address" : "Update address"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => { props.handleEditConfirm() }}
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-floating mb-4 theme-form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        id="m_form_name"
                                        name="m_form_name"
                                        value={props.newAddressData.m_form_name}
                                        onChange={(e) => { props.handleInputChange(e) }}
                                    />
                                    <label htmlFor="fname">Name</label>
                                    { props.errorAddressData.m_form_name && <div className="text-danger">{props.errorAddressData.m_form_name}</div>}
                                </div>


                                <div className="form-floating mb-4 theme-form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        id="m_address"
                                        name="m_address"
                                        value={props.newAddressData.m_address}
                                        onChange={(e) => { props.handleInputChange(e) }}

                                    />
                                    <label htmlFor="lname">Address</label>
                                    { props.errorAddressData.m_address && <div className="text-danger">{props.errorAddressData.m_address}</div>}

                                </div>

                                <div className="form-floating mb-4 theme-form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="City"
                                        id="m_city"
                                        name="m_city"
                                        value={props.newAddressData.m_city}
                                        onChange={(e) => { props.handleInputChange(e) }}
                                    />
                                    <label htmlFor="email">City</label>
                                    { props.errorAddressData.m_city && <div className="text-danger">{props.errorAddressData.m_city}</div>}

                                </div>


                                <div className="form-floating mb-4 theme-form-floating">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Pin Code"
                                        maxLength={6}

                                        id="m_pin_code"
                                        name="m_pin_code"
                                        value={props.newAddressData.m_pin_code}
                                        onChange={(e) => { props.handleInputChange(e) }}
                                    />
                                    <label htmlFor="email">Pin code</label>
                                    { props.errorAddressData.m_pin_code && <div className="text-danger">{props.errorAddressData.m_pin_code}</div>}

                                </div>



                                <div className="form-floating mb-4 theme-form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="m_country"
                                        name="m_country"
                                        value={props.newAddressData.m_country}
                                        onChange={(e) => { props.handleInputChange(e) }}
                                        placeholder="Country"
                                    />
                                    <label htmlFor="pin">Country</label>
                                    { props.errorAddressData.m_country && <div className="text-danger">{props.errorAddressData.m_country}</div>}

                                </div>
                                <div className="form-floating mb-4 theme-form-floating">
                                    <select
                                        className="form-control custom-select"
                                        style={{ borderRadius: 10, border: "1px solid #ccc", padding: 10 }}
                                        name="m_address_type"
                                        id="m_address_type"
                                        value={props.newAddressData.m_address_type}
                                        onChange={props.handleInputChange} // Assuming you have a function named handleInputChange to handle changes
                                    >
                                        <option value="">Select</option>
                                        <option value="Home">Home</option>
                                        <option value="Office">Office</option>
                                    </select>
                                    <label htmlFor="pin">Address Option</label>

                                    { props.errorAddressData.m_address_type && <div className="text-danger">{props.errorAddressData.m_address_type}</div>}

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary btn-md"
                                onClick={() => { props.handleEditConfirm() }}
                            // data-bs-dismiss="modal"
                            >
                                Close
                            </button>


                            <button
                                type="button"
                                className="btn theme-bg-color btn-md text-white"
                                // data-bs-dismiss="modal"
                                onClick={() => { props.isAddingAddress ? props.handleAddAddress() : props.handleUpdateAddAddress() }}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddressModal;