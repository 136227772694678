import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { apiService } from '../../constants/ApiService';
import Client from './Client';

const TestimonialsPerPage = 5
let arrayForHoldingTestimonials = []

const About = () => {

    const [blogTable, setBlogTable] = useState([]);
    const [clientTable, setClientTable] = useState([]);

    const [companyDetail, setCompanyDetail] = useState({
        company_name: '',
        company_address: '',
        company_pincode: '',
        company_phoneNumber: '',
        company_img_url: '',
        company_email: '',
        company_linkedin_link: '',
        company_fb_link: '',
        company_intragram_link: '',
        company_twitter_link: '',
        company_about_detail: '',

        company_terms: "",
        company_policy: ""
    });

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(5);

   
    const getCompanyData = async () => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/SystemMaster/companyDetail`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                setCompanyDetail(response.systemMasterDataByID ? response.systemMasterDataByID : companyDetail)
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    // const getUserMultipleTestimonial = async () => {
    //     try {

    //         let config = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'accept': 'application/json',
    //                 'User_id': newTestimonialData.userId
    //             }
    //         };

    //         const response = await apiService.get(`/api/ClientMaster/allclient?pageNo=${null}&limit=${null}&searchKey=${null}`, config)

    //         if (response.response && response.response.data.errors) {
    //             console.log(response.response?.data?.errors)
    //         }
    //         if (response.status === true) {
    //             // console.log(response.multiUserAddData)

    //             setUserMultipleTestimonial(response.clientMasterData)
    //         }
    //         else {
    //             // console.log(response.message);
    //         }
    //     } catch (error) {
    //         console.log(error)
    //         console.log('SomeThing went wrong!!');
    //     }
    // }


    useEffect(() => {
        getCompanyData()
    }, [])

    const getBlogData = async (page = 1, limit = 10, search = "") => {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/BlogMaster/allblog?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                console.log(response.blogMasterData)
                setBlogTable(response.blogMasterData)
                // console.log(response.message);
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
    };

    useEffect(() => {
        getBlogData();
    }, []);

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 5);
      };


    const getClientData = async () => {
        setLoading(true);
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json', // Important for file upload//s
                    'accept': 'application/json'
                }
            };

            const response = await apiService.get(`/api/ClientMaster/allclient?limit=${page}`, config)
            if (response.response && response.response.data.errors) {
                console.log(response.response?.data?.errors)
            }

            if (response.status === true) {
                console.log(response.clientMasterData, "hshshs")
                setClientTable(response.clientMasterData)
                // setTotalPage(response.totalPages)
                // console.log(response.message);
            }
            else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error)
            alert('SomeThing went wrong!!');
        }
        setLoading(false);
    };

    useEffect(() => {
        getClientData();
       
    }, [page]);


    // useEffect(() => {
        
    //   }, []);

    return (
        <>
            <section className="breadscrumb-section pt-0">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadscrumb-contain">
                                <h2>About Us</h2>
                                <nav>
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fa-solid fa-house" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            About Us
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*about */}
            <section className="fresh-vegetable-section section-lg-space">
                <div className="container-fluid-lg">
                    <div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
                        <div className="col-xl-6 col-12">
                            <div className="row g-sm-4 g-2">
                                <div className="col-6">
                                    <div className="fresh-image-2">
                                        <div
                                            className="bg-size  lazyloaded"
                                            style={{
                                                backgroundImage:
                                                    'url("../assets/images/inner-page/about-us/1.jpg")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center",
                                                backgroundRepeat: "no-repeat",
                                                display: "block"
                                            }}
                                        >
                                            <img
                                                src="../assets/images/inner-page/about-us/1.jpg"
                                                className="bg-img  lazyloaded"
                                                alt=""
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="fresh-image">
                                        <div
                                            className="bg-size  lazyloaded"
                                            style={{
                                                backgroundImage:
                                                    'url("../assets/images/inner-page/about-us/2.jpg")',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center",
                                                backgroundRepeat: "no-repeat",
                                                display: "block"
                                            }}
                                        >
                                            <img
                                                src="../assets/images/inner-page/about-us/2.jpg"
                                                className="bg-img  lazyloaded"
                                                alt=""
                                                style={{ display: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12">
                            <div className="fresh-contain p-center-left">
                                <div>
                                    <div className="review-title">
                                        <h4>About Us</h4>
                                        <h2>{companyDetail.company_name}</h2>
                                    </div>
                                    <div className="delivery-list">
                                        <p className="text-content">
                                            {companyDetail.company_about_detail}
                                        </p>
                                        <ul className="delivery-box">
                                            <li>
                                                <div className="delivery-box">
                                                    <div className="delivery-icon">
                                                        <img
                                                            src="../assets/svg/3/delivery.svg"
                                                            className=" lazyloaded"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h5 className="text">Free delivery for all orders</h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="delivery-box">
                                                    <div className="delivery-icon">
                                                        <img
                                                            src="../assets/svg/3/leaf.svg"
                                                            className=" lazyloaded"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h5 className="text">Only fresh foods</h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="delivery-box">
                                                    <div className="delivery-icon">
                                                        <img
                                                            src="../assets/svg/3/delivery.svg"
                                                            className=" lazyloaded"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h5 className="text">Free delivery for all orders</h5>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="delivery-box">
                                                    <div className="delivery-icon">
                                                        <img
                                                            src="../assets/svg/3/leaf.svg"
                                                            className=" lazyloaded"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="delivery-detail">
                                                        <h5 className="text">Only fresh foods</h5>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Client*/}
            <Client testimonialToRender={clientTable} />
            {loading && <p>Loading...</p>}
      {!loading && (
        <button className='btn btn-animation proceed-btn fw-bold' onClick={handleLoadMore} disabled={loading}>
          Load More
        </button>
      )}
            {/* <button  onClick={handleShowMoreTestimonials}>Load more</button> */}
            
            {/* <section className="client-section section-lg-space">
                <div className="container-fluid-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="about-us-title text-center">
                                <h4>What We Do</h4>
                                <h2 className="center">We are Trusted by Clients</h2>
                            </div>
                            <div className="slider-3_1 product-wrapper slick-initialized slick-slider slick-dotted">
                                <div className="slick-list draggable">
                                    <div
                                        className="slick-track"
                                        style={{
                                            opacity: 1,
                                            width: 3440,
                                            transform: "translate3d(-860px, 0px, 0px)"
                                        }}
                                    >
                                        <div
                                            className="slick-slide slick-cloned"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={-2}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/buy.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>80 K+</h2>
                                                <h4>Products Sales</h4>
                                                <p>
                                                    Some coffee shops have a seating area, while some just have
                                                    a spot to order and then go somewhere else to sit down. The
                                                    coffee shop that I am going to.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={-1}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/user.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>90%</h2>
                                                <h4>Happy Customers</h4>
                                                <p>
                                                    My goal for this coffee shop is to be able to get a coffee
                                                    and get on with my day. It's a Thursday morning and I am
                                                    rushing between meetings.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-current slick-active"
                                            tabIndex={0}
                                            style={{ width: 430 }}
                                            data-slick-index={0}
                                            aria-hidden="false"
                                            role="tabpanel"
                                            id="slick-slide00"
                                            aria-describedby="slick-slide-control00"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/work.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>10</h2>
                                                <h4>Business Years</h4>
                                                <p>
                                                    A coffee shop is a small business that sells coffee,
                                                    pastries, and other morning goods. There are many different
                                                    types of coffee shops around the world.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-active"
                                            tabIndex={0}
                                            style={{ width: 430 }}
                                            data-slick-index={1}
                                            aria-hidden="false"
                                            role="tabpanel"
                                            id="slick-slide01"
                                            aria-describedby="slick-slide-control01"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/buy.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>80 K+</h2>
                                                <h4>Products Sales</h4>
                                                <p>
                                                    Some coffee shops have a seating area, while some just have
                                                    a spot to order and then go somewhere else to sit down. The
                                                    coffee shop that I am going to.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={2}
                                            aria-hidden="true"
                                            role="tabpanel"
                                            id="slick-slide02"
                                            aria-describedby="slick-slide-control02"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/user.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>90%</h2>
                                                <h4>Happy Customers</h4>
                                                <p>
                                                    My goal for this coffee shop is to be able to get a coffee
                                                    and get on with my day. It's a Thursday morning and I am
                                                    rushing between meetings.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={3}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/work.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>10</h2>
                                                <h4>Business Years</h4>
                                                <p>
                                                    A coffee shop is a small business that sells coffee,
                                                    pastries, and other morning goods. There are many different
                                                    types of coffee shops around the world.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={4}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/buy.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>80 K+</h2>
                                                <h4>Products Sales</h4>
                                                <p>
                                                    Some coffee shops have a seating area, while some just have
                                                    a spot to order and then go somewhere else to sit down. The
                                                    coffee shop that I am going to.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            tabIndex={-1}
                                            style={{ width: 430 }}
                                            data-slick-index={5}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="clint-contain">
                                                <div className="client-icon">
                                                    <img
                                                        src="../assets/svg/3/user.svg"
                                                        className=" lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <h2>90%</h2>
                                                <h4>Happy Customers</h4>
                                                <p>
                                                    My goal for this coffee shop is to be able to get a coffee
                                                    and get on with my day. It's a Thursday morning and I am
                                                    rushing between meetings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="slick-dots" style={{}} role="tablist">
                                    <li className="slick-active" role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control00"
                                            aria-controls="slick-slide00"
                                            aria-label="1 of 2"
                                            tabIndex={0}
                                            aria-selected="true"
                                        >
                                            1
                                        </button>
                                    </li>
                                    <li role="presentation" className="">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control01"
                                            aria-controls="slick-slide01"
                                            aria-label="2 of 2"
                                            tabIndex={-1}
                                        >
                                            2
                                        </button>
                                    </li>
                                    <li role="presentation" className="">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control02"
                                            aria-controls="slick-slide02"
                                            aria-label="3 of 2"
                                            tabIndex={-1}
                                        >
                                            3
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


        
            {/* Team*/}
            {/* <section className="team-section section-lg-space">
                <div className="container-fluid-lg">
                    <div className="about-us-title text-center">
                        <h4 className="text-content">Our Creative Team</h4>
                        <h2 className="center">SHIVKMS team member</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="slider-user product-wrapper slick-initialized slick-slider slick-dotted">
                                <div className="slick-list draggable">
                                    <div
                                        className="slick-track"
                                        style={{
                                            opacity: 1,
                                            width: 5160,
                                            transform: "translate3d(-1290px, 0px, 0px)"
                                        }}
                                    >
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control33"
                                            data-slick-index={-2}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/4.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        Fondue stinking bishop goat. Macaroni cheese croque
                                                        monsieur cottage cheese.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control34"
                                            data-slick-index={-1}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/1.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        squirty cheese cheddar macaroni cheese airedale cheese
                                                        triangles.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id="slick-slide30"
                                            aria-describedby="slick-slide-control30"
                                            data-slick-index={0}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/1.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        cheeseburger airedale mozzarella the big cheese fondue.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-current slick-active"
                                            style={{ width: 430 }}
                                            tabIndex={0}
                                            role="tabpanel"
                                            id="slick-slide31"
                                            aria-describedby="slick-slide-control31"
                                            data-slick-index={1}
                                            aria-hidden="false"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/2.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        cheese on toast mozzarella bavarian bergkase smelly cheese
                                                        cheesy feet.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-active"
                                            style={{ width: 430 }}
                                            tabIndex={0}
                                            role="tabpanel"
                                            id="slick-slide32"
                                            aria-describedby="slick-slide-control32"
                                            data-slick-index={2}
                                            aria-hidden="false"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/3.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        camembert de normandie. Bocconcini rubber cheese fromage
                                                        frais port-salut.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={0}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id="slick-slide33"
                                            aria-describedby="slick-slide-control33"
                                            data-slick-index={3}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/4.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        Fondue stinking bishop goat. Macaroni cheese croque
                                                        monsieur cottage cheese.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id="slick-slide34"
                                            aria-describedby="slick-slide-control34"
                                            data-slick-index={4}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/1.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        squirty cheese cheddar macaroni cheese airedale cheese
                                                        triangles.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control30"
                                            data-slick-index={5}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/1.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        cheeseburger airedale mozzarella the big cheese fondue.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control31"
                                            data-slick-index={6}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/2.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        cheese on toast mozzarella bavarian bergkase smelly cheese
                                                        cheesy feet.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control32"
                                            data-slick-index={7}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/3.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        camembert de normandie. Bocconcini rubber cheese fromage
                                                        frais port-salut.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control33"
                                            data-slick-index={8}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/4.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        Fondue stinking bishop goat. Macaroni cheese croque
                                                        monsieur cottage cheese.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 430 }}
                                            tabIndex={-1}
                                            role="tabpanel"
                                            id=""
                                            aria-describedby="slick-slide-control34"
                                            data-slick-index={9}
                                            aria-hidden="true"
                                        >
                                            <div className="team-box">
                                                <div className="team-iamge">
                                                    <img
                                                        src="../assets/images/inner-page/user/1.jpg"
                                                        className="img-fluid  lazyloaded"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="team-name">
                                                    <h3>Anna Baranov</h3>
                                                    <h5>Marketing</h5>
                                                    <p>
                                                        squirty cheese cheddar macaroni cheese airedale cheese
                                                        triangles.
                                                    </p>
                                                    <ul className="team-media">
                                                        <li>
                                                            <a
                                                                href="https://www.facebook.com/"
                                                                className="fb-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://in.pinterest.com/"
                                                                className="pint-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-pinterest-p" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://twitter.com/"
                                                                className="twitter-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.instagram.com/"
                                                                className="insta-bg"
                                                                tabIndex={-1}
                                                            >
                                                                <i className="fa-brands fa-instagram" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="slick-dots" style={{}} role="tablist">
                                    <li className="" role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control30"
                                            aria-controls="slick-slide30"
                                            aria-label="1 of 3"
                                            tabIndex={-1}
                                        >
                                            1
                                        </button>
                                    </li>
                                    <li className="slick-active" role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control31"
                                            aria-controls="slick-slide31"
                                            aria-label="2 of 3"
                                            tabIndex={0}
                                            aria-selected="true"
                                        >
                                            2
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control32"
                                            aria-controls="slick-slide32"
                                            aria-label="3 of 3"
                                            tabIndex={-1}
                                        >
                                            3
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control33"
                                            aria-controls="slick-slide33"
                                            aria-label="4 of 3"
                                            tabIndex={-1}
                                        >
                                            4
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control34"
                                            aria-controls="slick-slide34"
                                            aria-label="5 of 3"
                                            tabIndex={-1}
                                        >
                                            5
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Review*/}
            {/* <section className="review-section section-lg-space">
                <div className="container-fluid">
                    <div className="about-us-title text-center">
                        <h4 className="text-content">Latest Testimonals</h4>
                        <h2 className="center">What people say</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="slider-4-half product-wrapper slick-initialized slick-slider slick-dotted">
                                <div
                                    className="slick-list draggable"
                                    style={{ padding: "0px 100px" }}
                                >
                                    <div className="slick-track" style={{
                                        opacity: 1,
                                        width: 7448,
                                        transform: "translate3d(-130px, 0px, 0px)"
                                    }} >
                                        {clientTable.map((clientItem, index) => (

                                            <div
                                                className="slick-slide slick-cloned"
                                                style={{ width: 392 }}
                                                tabIndex={-2}
                                                role="tabpanel"
                                                id=""
                                                aria-describedby="slick-slide-control15"
                                                data-slick-index={-3}
                                                aria-hidden="true"
                                            >
                                                <div className="reviewer-box">
                                                    <i className="fa-solid fa-quote-right" />
                                                    <div className="product-rating">
                                                        <ul className="rating">
                                                            {[...Array(4)].map((_, i) => (
                                                                <li key={i}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={24}
                                                                        height={24}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-star fill"
                                                                    >
                                                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                    </svg>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <h3>Top Quality, Beautiful Location</h3>
                                                    <p>{clientItem.client_feedback}</p>
                                                    <div className="reviewer-profile">
                                                        <div className="reviewer-image">
                                                            <img src={process.env.REACT_APP_API_BASE_URL +clientItem.img_url} className="lazyload" alt="" />
                                                        </div>
                                                        <div className="reviewer-name">
                                                            <h4>{clientItem.client_name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                </div>






                                <ul className="slick-dots" style={{}} role="tablist">
                                    <li className="slick-active" role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control10"
                                            aria-controls="slick-slide10"
                                            aria-label="1 of 4"
                                            tabIndex={0}
                                            aria-selected="true"
                                        >
                                            1
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control11"
                                            aria-controls="slick-slide11"
                                            aria-label="2 of 4"
                                            tabIndex={-1}
                                        >
                                            2
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control12"
                                            aria-controls="slick-slide12"
                                            aria-label="3 of 4"
                                            tabIndex={-1}
                                        >
                                            3
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control13"
                                            aria-controls="slick-slide13"
                                            aria-label="4 of 4"
                                            tabIndex={-1}
                                        >
                                            4
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control14"
                                            aria-controls="slick-slide14"
                                            aria-label="5 of 4"
                                            tabIndex={-1}
                                        >
                                            5
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control15"
                                            aria-controls="slick-slide15"
                                            aria-label="6 of 4"
                                            tabIndex={-1}
                                        >
                                            6
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control16"
                                            aria-controls="slick-slide16"
                                            aria-label="7 of 4"
                                            tabIndex={-1}
                                        >
                                            7
                                        </button>
                                    </li>
                                    <li role="presentation">
                                        <button
                                            type="button"
                                            role="tab"
                                            id="slick-slide-control17"
                                            aria-controls="slick-slide17"
                                            aria-label="8 of 4"
                                            tabIndex={-1}
                                        >
                                            8
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}




            {/* Blog*/}

            <section className="section-lg-space">
                <div className="container-fluid-lg">
                    <div className="about-us-title text-center">
                        <h4 className="text-content">Our Blog</h4>
                        <h2 className="center">Our Latest Blog</h2>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="slider-5 ratio_87 slick-initialized slick-slider">
                                <div className="slick-list draggable">
                                    <div
                                        className="slick-track"
                                        style={{
                                            opacity: 1,
                                            width: 3731,
                                            transform: "translate3d(-10px, 0px, 0px)"
                                        }}
                                    >
                                        {/* 
                                        <div
                                            className="slick-slide slick-cloned"
                                            style={{ width: 287 }}
                                            tabIndex={-1}
                                            data-slick-index={9}
                                            id=""
                                            aria-hidden="true"
                                        >
                                            <div className="blog-box">
                                                <div className="blog-image">
                                                    <a
                                                        href="blog-detail.html"
                                                        className="rounded-3 bg-size  lazyloaded"
                                                        tabIndex={-1}
                                                        style={{
                                                            backgroundImage:
                                                                'url("../assets/images/veg-2/blog/5.jpg")',
                                                            backgroundSize: "cover",
                                                            backgroundPosition: "center center",
                                                            backgroundRepeat: "no-repeat",
                                                            display: "block"
                                                        }}
                                                    >
                                                        <img
                                                            src="../assets/images/veg-2/blog/5.jpg"
                                                            className="bg-img  lazyload"
                                                            alt=""
                                                            style={{ display: "none" }}
                                                        />
                                                    </a>
                                                </div>
                                                <a
                                                    href="blog-detail.html"
                                                    className="blog-detail d-block"
                                                    tabIndex={-1}
                                                >
                                                    <h6>Milk Brand</h6>
                                                    <h5>Fresh hhhh Milk</h5>
                                                </a>
                                            </div>
                                        </div> */}



                                        {blogTable.map((blogitem, index) => (
                                            <div
                                                key={index}
                                                className="slick-slide slick-cloned"
                                                style={{ width: 287 }}
                                                tabIndex={-1}
                                                data-slick-index={9}
                                                id=""
                                                aria-hidden="true"
                                            >
                                                <div className="blog-box">
                                                    <div className="blog-image">
                                                        <img
                                                            src={blogitem.img_url}
                                                            className="bg-img lazyload"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <a
                                                        href="blog-detail.html"
                                                        className="blog-detail d-block"
                                                        tabIndex={-1}
                                                    >
                                                        <h6>{blogitem.blog_category}</h6>
                                                        <h5>{blogitem.blog_title}</h5>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default About;