import React from "react";

const InfoModal = (props) => {
    return (
        <>
            <div
                className="modal fade theme-modal show"
                id="add-address"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingLeft: 0 }}
            >
                {/* //                                Add a new address */}

                <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
                    <div className="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Points Contributors <i className="fa fa-info-circle"></i>
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => { props.handleInfoClose() }}
                            // data-bs-dismiss="modal"
                            // aria-label="Close"
                            >
                                <i className="fa-solid fa-xmark" />
                            </button>
                        </div>
                        <div className="modal-body">
                        <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">User name</th>
      <th scope="col">Sponsored by</th>
      <th scope="col">Joining Date</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
                        </div>
                        <div className="modal-footer">
                           
                            <button
                                type="button"
                                className="btn theme-bg-color btn-md text-white"
                                // data-bs-dismiss="modal"
                                onClick={() => { props.handleInfoClose() }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InfoModal;