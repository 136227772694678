import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../constants/ApiService';

const BlogList = () => {

  const navigate = useNavigate();
  const [blogTable, setBlogTable] = useState([]);
  const getBlogData = async (page = 1, limit = 10, search = "") => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json', // Important for file upload//s
          'accept': 'application/json'
        }
      };

      const response = await apiService.get(`/api/BlogMaster/allblog?pageNo=${page}&limit=${limit}&searchKey=${search}`, config)
      if (response.response && response.response.data.errors) {
        console.log(response.response?.data?.errors)
      }

      if (response.status === true) {
        console.log(response.blogMasterData)
        setBlogTable(response.blogMasterData)
        // console.log(response.message);
      }
      else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error)
      alert('SomeThing went wrong!!');
    }
  };

  useEffect(() => {
    getBlogData();
  }, [!blogTable]);

  const goBlogDetail = (blogId) => {
    navigate('/blogdetail', { state: { blogId: blogId } });
  }

  return (
    <>
      {/* Breadcrumb Section Start */}
      <section className="breadscrumb-section pt-0">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="breadscrumb-contain">
                <h2>Blog Page</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="index-2.html">
                        <i className="fa-solid fa-house" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blog
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb Section End */}
      <section className="blog-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-4">
            <div className="col-xxl-9 col-xl-8 col-lg-7 order-lg-2">
              <div className="row g-4">
              <h2>No blogs to show for now</h2>

                {blogTable? blogTable.map((data, index) => (
                  <>
                    <div className="col-12">

                      <div
                        className="blog-box blog-list wow fadeInUp"
                        style={{ visibility: "visible", animationName: "fadeInUp" }}
                      >
                        <div className="blog-image">
                          <img
                            src={process.env.REACT_APP_API_BASE_URL +data.img_url}
                            className=" lazyloaded"
                            alt=""
                          />
                        </div>

                        <div className="blog-contain blog-contain-2">
                          <div className="blog-label">
                            <span className="time">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-clock"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <polyline points="12 6 12 12 16 14" />
                              </svg>{" "}
                              <span>{data.updated_on.split('T')[0]}</span>
                            </span>
                            <span className="super">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-user"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx={12} cy={7} r={4} />
                              </svg>{" "}
                              <span>{data.username}</span>
                            </span>
                          </div>
                          <a href="blog-detail.html">
                            <h3>{data.blog_title}</h3>
                          </a>
                          <p>
                            {data.blog_content}
                          </p>
                          <button
                            type="button"
                            onClick={() => { goBlogDetail(data.id) }}
                            className="blog-button"
                          >
                            Read More <i className="fa-solid fa-right-long" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )):
                <>
                <div className="col-12"><h2>No blogs to show for now</h2></div>
                </>}
                {/* <div className="col-12">

                  <div
                    className="blog-box blog-list wow fadeInUp"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="blog-image">
                      <img
                        src="../assets/images/inner-page/blog/1.jpg"
                        className=" lazyloaded"
                        alt=""
                      />
                    </div>

                    <div className="blog-contain blog-contain-2">
                      <div className="blog-label">
                        <span className="time">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                          </svg>{" "}
                          <span>25 Feg, 2022</span>
                        </span>
                        <span className="super">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>{" "}
                          <span>Mark J. Speight</span>
                        </span>
                      </div>
                      <a href="blog-detail.html">
                        <h3>one pot creamy mediterranean chicken pasta cream.</h3>
                      </a>
                      <p>
                        Monterey jack cheese slices cream cheese cream cheese hard
                        cheese roquefort emmental lancashire. Who moved my cheese
                        dolcelatte st. agur blue cheese fromage mozzarella say cheese
                        mascarpone blue castello.
                      </p>
                      <button
                        onclick="location.href = 'blog-detail.html';"
                        className="blog-button"
                      >
                        Read More <i className="fa-solid fa-right-long" />
                      </button>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-12">
                  <div
                    className="blog-box blog-list wow fadeInUp"
                    data-wow-delay="0.05s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.05s",
                      animationName: "fadeInUp"
                    }}
                  >
                    <div className="blog-image">
                      <img
                        src="../assets/images/inner-page/blog/2.jpg"
                        className=" lazyloaded"
                        alt=""
                      />
                    </div>
                    <div className="blog-contain blog-contain-2">
                      <div className="blog-label">
                        <span className="time">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                          </svg>{" "}
                          <span>25 Feg, 2022</span>
                        </span>
                        <span className="super">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>{" "}
                          <span>rebeus hagrid</span>
                        </span>
                      </div>
                      <a href="blog-detail.html">
                        <h3>Crispy Frozen Vegetable is the on the Tempura.</h3>
                      </a>
                      <p>
                        Manchego cauliflower cheese st. agur blue cheese red leicester
                        monterey jack cheesecake the big cheese edam. Gouda monterey
                        jack roquefort hard cheese feta croque monsieur cheeseburger
                        manchego.
                      </p>
                      <button
                        onclick="location.href = 'blog-detail.html';"
                        className="blog-button"
                      >
                        Read More <i className="fa-solid fa-right-long" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="blog-box blog-list wow fadeInUp"
                    data-wow-delay="0.1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.1s",
                      animationName: "fadeInUp"
                    }}
                  >
                    <div className="blog-image">
                      <a href="blog-detail.html">
                        <img
                          src="../assets/images/inner-page/blog/3.jpg"
                          className=" lazyloaded"
                          alt=""
                        />
                      </a>
                      <label>
                        <i className="fa-solid fa-bolt-lightning" /> popular
                      </label>
                    </div>
                    <div className="blog-contain blog-contain-2">
                      <div className="blog-label">
                        <span className="time">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-clock"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <polyline points="12 6 12 12 16 14" />
                          </svg>{" "}
                          <span>25 Feg, 2022</span>
                        </span>
                        <span className="super">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            <circle cx={12} cy={7} r={4} />
                          </svg>{" "}
                          <span>Chris C. Hall</span>
                        </span>
                      </div>
                      <a href="blog-detail.html">
                        <h3>
                          How to start regrowing green onions and other vegetables.
                        </h3>
                      </a>
                      <p>
                        Cheese triangles say cheese cheese and biscuits dolcelatte
                        jarlsberg cream cheese taleggio fromage frais. Who moved my
                        cheese cottage cheese cheese on toast rubber cheese melted
                        cheese ricotta.
                      </p>
                      <button
                        onclick="location.href = 'blog-detail.html';"
                        className="blog-button"
                      >
                        Read More <i className="fa-solid fa-right-long" />
                      </button>
                    </div>
                  </div>
                </div> */}

              </div>


              {/* pagination ui  */}
              {/* <nav className="custome-pagination">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled">
                    <a className="page-link" href="javascript:void(0)" tabIndex={-1}>
                      <i className="fa-solid fa-angles-left" />
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="javascript:void(0)">
                      1
                    </a>
                  </li>
                  <li className="page-item" aria-current="page">
                    <a className="page-link" href="javascript:void(0)">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0)">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0)">
                      <i className="fa-solid fa-angles-right" />
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>


            {/* recent post  */}
            {/* <div className="col-xxl-3 col-xl-4 col-lg-5 order-lg-1">
              <div
                className="left-sidebar-box wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="left-search-box">
                  <div className="search-box">
                    <input
                      type="search"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Search...."
                    />
                  </div>
                </div>

                <div
                  className="accordion left-accordion-box"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Recent Post
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body pt-0">
                        <div className="recent-post-box">
                          <div className="recent-box">
                            <a href="blog-detail.html" className="recent-image">
                              <img
                                src="../assets/images/inner-page/blog/1.jpg"
                                className="img-fluid  lazyloaded"
                                alt=""
                              />
                            </a>
                            <div className="recent-detail">
                              <a href="blog-detail.html">
                                <h5 className="recent-name">
                                  Green onion knife and salad placed
                                </h5>
                              </a>
                              <h6>
                                25 Jan, 2022{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-thumbs-up"
                                >
                                  <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                                </svg>
                              </h6>
                            </div>
                          </div>
                          <div className="recent-box">
                            <a href="blog-detail.html" className="recent-image">
                              <img
                                src="../assets/images/inner-page/blog/2.jpg"
                                className="img-fluid  lazyloaded"
                                alt=""
                              />
                            </a>
                            <div className="recent-detail">
                              <a href="blog-detail.html">
                                <h5 className="recent-name">
                                  Health and skin for your organic
                                </h5>
                              </a>
                              <h6>
                                25 Jan, 2022{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-thumbs-up"
                                >
                                  <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                                </svg>
                              </h6>
                            </div>
                          </div>
                          <div className="recent-box">
                            <a href="blog-detail.html" className="recent-image">
                              <img
                                src="../assets/images/inner-page/blog/3.jpg"
                                className="img-fluid  lazyloaded"
                                alt=""
                              />
                            </a>
                            <div className="recent-detail">
                              <a href="blog-detail.html">
                                <h5 className="recent-name">
                                  Organics mix masala fresh &amp; soft
                                </h5>
                              </a>
                              <h6>
                                25 Jan, 2022{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-thumbs-up"
                                >
                                  <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                                </svg>
                              </h6>
                            </div>
                          </div>
                          <div className="recent-box">
                            <a href="blog-detail.html" className="recent-image">
                              <img
                                src="../assets/images/inner-page/blog/4.jpg"
                                className="img-fluid  lazyloaded"
                                alt=""
                              />
                            </a>
                            <div className="recent-detail">
                              <a href="blog-detail.html">
                                <h5 className="recent-name">
                                  Fresh organics brand and picnic
                                </h5>
                              </a>
                              <h6>
                                25 Jan, 2022{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-thumbs-up"
                                >
                                  <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                                </svg>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Category
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body p-0">
                        <div className="category-list-box">
                          <ul>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Latest Recipes</h5>
                                  <span>10</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Diet Food</h5>
                                  <span>6</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Low calorie Items</h5>
                                  <span>8</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Cooking Method</h5>
                                  <span>9</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Dairy Free</h5>
                                  <span>12</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="blog-list.html">
                                <div className="category-name">
                                  <h5>Vegetarian Food</h5>
                                  <span>10</span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Product Tags
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div className="accordion-body pt-0">
                        <div className="product-tags-box">
                          <ul>
                            <li>
                              <a href="javascript:void(0)">Fruit Cutting</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">Meat</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">organic</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">cake</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">pick fruit</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">backery</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">organix food</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">Most Expensive Fruit</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFour"
                      >
                        Trending Products
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body">
                        <ul className="product-list product-list-2 border-0 p-0">
                          <li>
                            <div className="offer-product">
                              <a
                                href="shop-left-sidebar.html"
                                className="offer-image"
                              >
                                <img
                                  src="../assets/images/vegetable/product/23.png"
                                  className=" lazyloaded"
                                  alt=""
                                />
                              </a>
                              <div className="offer-detail">
                                <div>
                                  <a href="shop-left-sidebar.html">
                                    <h6 className="name">
                                      Meatigo Premium Goat Curry
                                    </h6>
                                  </a>
                                  <span>450 G</span>
                                  <h6 className="price theme-color">₹ 70.00</h6>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="offer-product">
                              <a
                                href="shop-left-sidebar.html"
                                className="offer-image"
                              >
                                <img
                                  src="../assets/images/vegetable/product/24.png"
                                  className=" lazyloaded"
                                  alt=""
                                />
                              </a>
                              <div className="offer-detail">
                                <div>
                                  <a href="shop-left-sidebar.html">
                                    <h6 className="name">
                                      Dates Medjoul Premium Imported
                                    </h6>
                                  </a>
                                  <span>450 G</span>
                                  <h6 className="price theme-color">₹ 40.00</h6>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="mb-0">
                            <div className="offer-product">
                              <a
                                href="shop-left-sidebar.html"
                                className="offer-image"
                              >
                                <img
                                  src="../assets/images/vegetable/product/26.png"
                                  className=" lazyloaded"
                                  alt=""
                                />
                              </a>
                              <div className="offer-detail">
                                <div>
                                  <a href="shop-left-sidebar.html">
                                    <h6 className="name">
                                      Apple Red Premium Imported
                                    </h6>
                                  </a>
                                  <span>1 KG</span>
                                  <h6 className="price theme-color">₹ 80.00</h6>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default BlogList;