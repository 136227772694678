import { createSlice, current } from '@reduxjs/toolkit';

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        company_order_otp_active : null
    },

    reducers: {
       
        setOrderOtpActive: (state, action) => {
            state.company_order_otp_active = action.payload;
        },

        // resetOrderOtpActive : (state, action) => {
        //     company_order_otp_active = ""
        // },

    },
});

export const { setOrderOtpActive } = companySlice.actions;

export default companySlice.reducer;
